
import Pay from "./com/pay.vue";
import Tester from "./com/tester/index.vue";
import RegisterMini from "./com/register-mini/index.vue";
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'


@Component({})
export default class extends Vue {
  @State((state) => state.store.store)
  store;

  get query() {
    return this.$route.query;
  }
  get authString() {
    if (!this.query) return false;
    return this.query.authString;
  }

  get authPost() {
    return {
      authString: this.query.authString,
      time: this.query.time,
      authCode: this.query.auth_code,
      expiresIn: this.query.expires_in,
    }
  }

  registerStatus: any = null;
  authLoading = false;
  @Action("store/getStore")
  getStore

  async created() {
    if (this.authString) {
      let { error } = await this.$post(
        "/manager/open-platform/auth-callback",
        this.authPost
      );
      if (error) return;
      this.$success("授权成功");
      this.$replace("/setting/auth");
      this.getStore()
    }
    this.getRegisterStatus();
  }
  async getRegisterStatus() {
    let { error, data } = await this.$get(
      "/manager/open-platform/register-status"
    );
    if (error) return;
    this.registerStatus = data;
  }

  registerMiniCode() {
    if (this.store.appid) return this.$alert("已绑定小程序无法注册");
    this.$dialog({
      title: "申请小程序",
      props: { width: "630px", },
      content: () => {
        return <RegisterMini source={this.registerStatus} />
      }
    });
  }

  async withdrawAudit() {
    let { error, message } = await this.$get(
      "/manager/open-platform/withdraw-audit"
    );
    if (error) return this.$error(message);
    this.$success("撤回审核成功");
  }

  async release() {
    let { error, message } = await this.$get("/manager/open-platform/release");
    if (error) return this.$error(message);
    this.$success("恭喜您发布代码成功");
  }
  async submitAuidt() {
    let { error, message } = await this.$get("/manager/open-platform/submit-audit");
    if (error) return this.$error(message);
    this.$success("提交审核成功");
  }
  async viewCode() {
    this.$dialog({
      title: "查看体验码",
      props: { width: "630px", },
      content: () => {
        return <Tester />
      }
    });
  }
  async gotoAuth() {
    this.authLoading = true;
    let { error, data, message } = await this.$get("/manager/open-platform/get-auth-url");
    this.authLoading = false;
    if (error) return this.$error(message);
    location.href = data.url;
  }
  paySetting() {
    this.$dialog({
      title: "支付设置",
      props: { width: "530px", },
      content() {
        return <Pay />;
      }
    });
  }
}
