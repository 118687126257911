
import { Component } from 'vue-facing-decorator';
import { ListViewVue } from '@/common/BaseVue';

@Component({
  components: {}
})
export default class extends ListViewVue {
  listType = "router";

  gotoDetail(id?) {
    if (id) {
      return this.$goto(`/recharge/recharge/edit?id=${id}`);
    }
    this.$goto("/recharge/recharge/create");
  }
  async deleteRow(id) {
    let { error, message } = await this.$post("/manager/recharge/delete", {
      id,
    });
    if (error) return this.$error(message);
    this.$success("删除成功");
    this.refresh();
  }
  getList() {
    return this.$get("/manager/recharge/list", {
      ...this.mesureData,
    });
  }
}
