
import { Watch, Component, Vue, toNative, mixins } from 'vue-facing-decorator'
import ChooseGoods from "./com/choose-goods.vue";
import ChangeInput from "./com/change-input.vue";
import SetSkuPrice from "./com/set-sku-price.vue";
@Component({
  components: { ChangeInput },
})
export default class extends Vue {
  @Watch('list')
  _listWatcher() {
    this.getGoods()
  }
  get goodsList() {
    let res = [];
    let hashData = {};
    this.list.map((item) => {
      if (!hashData[item.goodsId]) {
        hashData[item.goodsId] = {
          goodsId: item.goodsId,
          skuList: [],
        };
        res.push(hashData[item.goodsId]);
      }
      if (item.skuId) {
        hashData[item.goodsId].skuList.push({ ...item });
      } else {
        Object.assign(hashData[item.goodsId], { ...item });
      }
    });
    return res;
  }

  get checkedHash() {
    let res = {};
    this.list.map((item) => {
      if (!res[item.goodsId]) res[item.goodsId] = {};
      res[item.goodsId][item.skuId] = item;
    });
    return res;
  }
  get goodsIds() {
    let ids = {};
    for (let i = 0; i < this.list.length; i++) {
      let item = this.list[i];
      if (!this.goodsData[item.goodsId]) {
        ids[item.goodsId] = true;
      }
    }
    return Object.keys(ids);
  }

  submitLoading = false
  loading = false
  goodsData: any = {}
  list: any[] = []
  formData = {
    name: "",
    remake: "",
  }
  created() {
    this.getData();
  }

  filterValue(data, prop) {
    if (!data) return "";
    return data[prop];
  }

  async setSku(row) {
    let goods = this.goodsData[row.goodsId];
    if (!goods) return;
    let list = await this.$dialog({
      title: "设置规格折扣",
      props: { width: "900px", },
      content() {
        return <SetSkuPrice info={row} goods={goods}></SetSkuPrice>;
      },
    });
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      Object.assign(this.checkedHash[item.goodsId][item.skuId], item);
    }
  }
  async getData() {
    let id = this.$route.query.id;
    if (!id) return;
    this.loading = true;
    let { error, data } = await this.$get("/manager/activity/detail", { id });
    this.loading = false;
    if (error) return;
    this.formData = {
      name: data.name,
      remake: data.remake,
    };
    this.list = data.activityGoodsList;
  }
  changeInput(val, row, type) {
    this.list.map((item) => {
      if (item.goodsId == row.goodsId) {
        item.value = val;
        item.type = type;
      }
    });
    this.list = [...this.list]
  }
  removeList(row) {
    for (let i = this.list.length; i--; i >= 0) {
      let item = this.list[i];
      if (item.goodsId == row.goodsId) {
        this.list.splice(i, 1);
      }
    }
  }
  async submit() {
    this.submitLoading = true;
    let { error, data, message } = await this.$post("/manager/activity/operat", {
      activityGoodsList: this.list,
      id: this.$route.query.id,
      ...this.formData,
    });
    this.submitLoading = false;
    if (error) return this.$error(message);
    this.$success("操作成功");
    this.$router.back();
  }

  async getGoods() {
    if (this.goodsIds.length == 0) return;
    let { error, data } = await this.$get("/manager/goods/list-sku-goods", {
      ids: this.goodsIds,
      pageSize: -1,
    });

    if (error) return;
    let res = {};
    data.list.map((item) => {
      res[item.id] = item;
    });
    this.goodsData = { ...this.goodsData, ...res };
  }

  async operatGoods() {
    let data = this.checkedHash;
    let chooseList = await this.$dialog({
      title: "选择商品",
      props: {
        width: "900px",
      },
      content() {
        return <ChooseGoods options={data}></ChooseGoods>;
      }
    });
    let list: any[] = [];
    let prevList: any[] = [];
    for (let id in chooseList) {
      let hasSku = false;
      for (let skuId in chooseList[id]) {
        hasSku = true;
        if (this.checkedHash[id] && this.checkedHash[id][skuId]) {
          prevList.push(this.checkedHash[id][skuId]);
        } else {
          list.push({
            goodsId: id,
            skuId: skuId,
            type: "",
            value: "",
          });
        }
      }
      if (!hasSku) {
        list.push({
          goodsId: id,
          type: "",
          value: "",
        });
      }
    }
    this.list = [...prevList, ...list];
  }
}

