import Edit from './edit.vue'
import Show from './show.vue'

function createData(data?) {
  return {
    icon: '',
    icon2: '',
    text: '',
    link: {
    },
    ...data
  }
}
export default {
  Show,
  Edit,
  createData,
  data: {
    data() {
      let res = {
        data: {
          colorType: '1', // 0 自定义颜色  1 全局颜色
          color: '#333333',
          color1: '#FF4444',
          style: {
            backgroundColor: '#FFFFFF'
          },
          list: [
            {
              icon: 'https://file.cnzhizhi.com/common/icons/home01.png',
              icon2: 'https://file.cnzhizhi.com/common/icons/home01h.png',
              link: {
                type: "page",
                value: "homepage"
              },
              text: "首页"
            },
            {
              icon: 'https://file.cnzhizhi.com/common/icons/home02.png',
              icon2: 'https://file.cnzhizhi.com/common/icons/home02h.png',
              link: {
                type: "page",
                value: "category"
              },
              text: "商品分类"
            },
            {
              icon: 'https://file.cnzhizhi.com/common/icons/home03.png',
              icon2: 'https://file.cnzhizhi.com/common/icons/home03h.png',
              link: {
                type: "page",
                value: "car"
              },
              text: "购物车"
            },
            {
              icon: 'https://file.cnzhizhi.com/common/icons/home04.png',
              icon2: 'https://file.cnzhizhi.com/common/icons/home04h.png',
              link: {
                type: "page",
                value: "personal"
              },
              text: "个人中心"
            }
          ]
        },
        render: "MenuList",
      }
      res.data.list = res.data.list.map((item) => createData(item));
      return res;
    },
    name: "底部导航",
  },
  style: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0
  },
}