
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {

  @Prop()
  modelValue: any;

  group = [
    [
      { name: "购物车", icon: "iconweibiaoti2fuzhi04" },
      { name: "联系客服", icon: "icondaifahuo" },
    ],
    [{ name: "收货地址", icon: "icondaishouhuo" }],
  ]
};
