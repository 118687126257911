
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';

@Component({})
export default class extends Vue {
  @State((state) => state.store.store)
  store

  list: any[] = []
  @Action("store/getStore")
  getStore



  taskInfo() {
    return this.$goto({
      path: "/vip/level/normal-score",
    });
  }

  created() {
    this.getList();
  }

  async changeVip(v) {
    await this.$postNotify("/manager/level/toggle-vip", {
      vipSwitch: v,
    });
    this.getStore()
  }
  editInfo() {
    return this.$goto({
      path: "/vip/level/normal-operat",
    });
  }
  async getList() {
    let { error, data, message } = await this.$get("/manager/level/list", {
      pageSize: -1,
    });
    if (error) {
      return this.$error(message)
    }
    this.list = data.list;
  }
}
