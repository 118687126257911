import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, _mergeProps({
    "destroy-on-close": true,
    class: "custom-drawer-style-box",
    direction: "rtl",
    size: "600px",
    onClosed: _ctx.closed,
    "with-header": false
  }, _ctx.props, { "model-value": _ctx.showDialog }), {
    default: _withCtx(() => [
      (_ctx.showContent)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
            key: 0,
            onResolve: _ctx.resolveEvent
          }, null, 40, ["onResolve"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["onClosed", "model-value"]))
}