import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3df3b91c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-container" }
const _hoisted_2 = { class: "view-group-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_slider = _resolveComponent("el-slider")!
  const _component_ui_layout1 = _resolveComponent("ui-layout1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_layout1, {
        title: "空白高度",
        class: "view-item-box"
      }, {
        content: _withCtx(() => [
          _createVNode(_component_el_slider, {
            modelValue: _ctx.modelValue.height,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.height) = $event)),
            "show-input": "",
            class: "slider-style-box",
            min: 10,
            "show-input-controls": false
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ])
  ]))
}