
import { LockDec, ValidateDec } from '@/decorators'
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'


@Component({
  components: {
  }
})
export default class extends Vue {
  postData = {
    list: [],
  }

  removeGroup(i) {
    this.postData.list.splice(i, 1)
  }

  addGroup() {
    this.postData.list.push({ name: '' })
  }

  submitLoading = false
  loading = false

  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  }

  async getData() {
    let { data } = await this.$getError('/manager/salesman/get-category');
    this.postData = data;
  }

  @LockDec('submitLoading')
  @ValidateDec("form")
  async submit() {
    await this.$postNotify('/manager/salesman/set-category', this.postData)
  }
}
