import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6582f14e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-container" }
const _hoisted_2 = { class: "view-group-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_layout1 = _resolveComponent("ui-layout1")!
  const _component_el_slider = _resolveComponent("el-slider")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_ui_layout2 = _resolveComponent("ui-layout2")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_color_picker = _resolveComponent("el-color-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_layout1, {
        title: "占位符名称",
        class: "view-item-box"
      }, {
        content: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.modelValue.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.title) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ui_layout1, {
        title: "空白高度",
        class: "view-item-box"
      }, {
        content: _withCtx(() => [
          _createVNode(_component_el_slider, {
            style: {"margin-left":"10px"},
            modelValue: _ctx.modelValue.style.height,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.style.height) = $event)),
            "show-input": "",
            class: "slider-style-box",
            min: 28,
            max: 38,
            "show-input-controls": false
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_ui_layout2, {
      title: "框体样式",
      ext: _ctx.foramtRadius(_ctx.modelValue.borderRadius)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_radio_group, {
          modelValue: _ctx.modelValue.borderRadius,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.borderRadius) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio_button, { label: "normal" }, {
              default: _withCtx(() => [
                _createTextVNode("正常")
              ]),
              _: 1
            }),
            _createVNode(_component_el_radio_button, { label: "circle" }, {
              default: _withCtx(() => [
                _createTextVNode("圆形")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["ext"]),
    _createVNode(_component_ui_layout2, {
      title: "背景颜色",
      ext: _ctx.modelValue.wrapStyle.backgroundColor
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "text",
          style: {"margin":"0 10px 0 0"},
          onClick: _cache[3] || (_cache[3] = ($event: any) => (
        _ctx.modelValue.wrapStyle.backgroundColor =
        _ctx.defaultData.data.wrapStyle.backgroundColor
        ))
        }, {
          default: _withCtx(() => [
            _createTextVNode("重置")
          ]),
          _: 1
        }),
        _createVNode(_component_el_color_picker, {
          modelValue: _ctx.modelValue.wrapStyle.backgroundColor,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelValue.wrapStyle.backgroundColor) = $event)),
          onActiveChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.modelValue.wrapStyle.backgroundColor = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["ext"]),
    _createVNode(_component_ui_layout2, {
      title: "框体颜色",
      ext: _ctx.modelValue.style.backgroundColor
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "text",
          style: {"margin":"0 10px 0 0"},
          onClick: _cache[6] || (_cache[6] = ($event: any) => (
        _ctx.modelValue.style.backgroundColor = _ctx.defaultData.data.style.backgroundColor
        ))
        }, {
          default: _withCtx(() => [
            _createTextVNode("重置")
          ]),
          _: 1
        }),
        _createVNode(_component_el_color_picker, {
          modelValue: _ctx.modelValue.style.backgroundColor,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modelValue.style.backgroundColor) = $event)),
          onActiveChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.modelValue.style.backgroundColor = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["ext"]),
    _createVNode(_component_ui_layout2, {
      title: "文本颜色",
      ext: _ctx.modelValue.style.color
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "text",
          style: {"margin":"0 10px 0 0"},
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.modelValue.style.color = _ctx.defaultData.data.style.color))
        }, {
          default: _withCtx(() => [
            _createTextVNode("重置")
          ]),
          _: 1
        }),
        _createVNode(_component_el_color_picker, {
          modelValue: _ctx.modelValue.style.color,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modelValue.style.color) = $event)),
          onActiveChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.modelValue.style.color = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["ext"])
  ]))
}