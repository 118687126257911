
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {

  @Watch("skuList")
  skuListWatcher() {
    this.$emit("update:modelValue", this.mresureList);
  }

  @Prop({
    default() {
      return []
    }
  })
  modelValue: any[];

  @Prop(
    {
      default() {
        return []
      }
    }
  )
  sku: any[];

  @Watch("sku")
  skuWatcher() {
    this.$emit("update:modelValue", this.mresureList);
  }

  created() {
    for (let i = 0; i < this.mresureList.length; i++) {
      Object.assign(this.mresureList[i], this.modelValue[i]);
    }
    this.$emit("update:modelValue", this.mresureList);
  }

  get listHash() {
    let res = {};
    for (let i = 0; i < this.modelValue.length; i++) {
      let item = this.modelValue[i];
      if (item._uuid) {
        res[item._uuid] = item;
      }
    }
    return res;
  }

  get mresureList() {
    if (!this.sku || this.sku.length == 0) return []
    let indexHash = {};
    this.computSku.map((item, index) => {
      indexHash[index] = { current: 0, item };
    });
    let valueList = [];
    while (true) {
      let goods = {
        _uuid: "",
        price: "",
        stock: "",
        weight: "",
        indexList: [],
      };
      for (let index in indexHash) {
        let item = indexHash[index];
        let spec = item.item;
        let current = item.current;
        let specList = spec.specList;
        goods._uuid += specList[current]._uuid;
        goods.indexList.push(current);
      }
      let hasNext = (() => {
        for (let i = this.computSku.length - 1; i >= 0; i--) {
          let nextIndex = indexHash[i].current + 1;
          let hasNext = this.computSku[i].specList[nextIndex];
          if (hasNext) {
            indexHash[i].current = nextIndex;
            return true;
          }
          indexHash[i].current = 0;
        }
        return false;
      })();
      if (this.listHash[goods._uuid]) {
        let imp = goods.indexList ? { indexList: goods.indexList } : {}
        valueList.push(
          Object.assign(this.listHash[goods._uuid], {
            ...goods,
            ...this.listHash[goods._uuid],
          }, imp)
        );
      } else {
        valueList.push(goods);
      }
      if (!hasNext) break;
    }
    return valueList;
  }
  get skuList() {
    let res = [];
    this.computSku.map((item) => {
      item.specList.map((citem) => {
        if (!citem._uuid) {
          citem._uuid = this.$getUUID();
        }
        res.push(citem);
      });
    });
    return res;
  }
  get computSku() {
    let res = [];
    for (let i = 0; i < this.sku.length; i++) {
      let item = this.sku[i];
      if (item.specList.length == 0) continue;
      res.push(item);
    }
    return res;
  }

  getName(item, row, index) {
    return item.specList[row.indexList[index]].name
  }

  arraySpanMethod({ rowIndex, columnIndex }) {
    if (columnIndex >= this.computSku.length) return [1, 1];
    if (columnIndex == 0) {
      let rowspan = 1;
      if (
        this.computSku[1] &&
        this.computSku[1].specList &&
        this.computSku[1].specList.length
      ) {
        rowspan *= this.computSku[1].specList.length;
      }
      if (
        this.computSku[2] &&
        this.computSku[2].specList &&
        this.computSku[2].specList.length
      ) {
        rowspan *= this.computSku[2].specList.length;
      }
      if (rowIndex % rowspan !== 0) {
        return {
          colspan: 1,
          rowspan: 0,
        };
      }
      return {
        rowspan,
        colspan: 1,
      };
    }
    if (columnIndex == 1) {
      let rowspan = 1;
      if (
        this.computSku[2] &&
        this.computSku[2].specList &&
        this.computSku[2].specList.length
      ) {
        rowspan *= this.computSku[2].specList.length;
      }
      if (rowIndex % rowspan !== 0) {
        return {
          colspan: 1,
          rowspan: 0,
        };
      }
      return {
        rowspan,
        colspan: 1,
      };
    }
    return [1, 1];
  }
  async setValue(key) {
    let data = await this.$prompt('提示', {
      title: "请输入批量设置值",
    });
    this.modelValue.map(function (item) {
      item[key] = data;
    });
  }
}
