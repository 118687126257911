
import InstanceBox from './index.vue';
import { dialog } from '@/plugins/dialog/index'
export default function (options, props = {}) {
  options = Object.assign({}, options)
  return dialog({
    props: {
      width: '500px'
    },
    content: () => {
      return <InstanceBox options={options}></InstanceBox>;
    }
  })
}