import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-prevent": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_ctx.disabled)
    ? _renderSlot(_ctx.$slots, "reference", {
        key: 0,
        showEvent: _ctx.showEvent,
        hideEvent: _ctx.hideEvent
      })
    : (_openBlock(), _createBlock(_component_el_popover, _mergeProps({
        key: 1,
        visible: _ctx.visible
      }, _ctx.$attrs, {
        onBeforeEnter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showChild = true)),
        onAfterLeave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showChild = false))
      }), {
        reference: _withCtx(() => [
          _renderSlot(_ctx.$slots, "reference", {
            showEvent: _ctx.showEvent,
            hideEvent: _ctx.hideEvent
          })
        ]),
        default: _withCtx(() => [
          (_ctx.showChild)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "default", {
                  showEvent: _ctx.showEvent,
                  hideEvent: _ctx.hideEvent
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 16, ["visible"]))
}