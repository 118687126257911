import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79d4b756"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-container" }
const _hoisted_2 = { class: "view-group-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_ui_layout1 = _resolveComponent("ui-layout1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_layout1, {
        title: "第一优先级",
        class: "view-item-box"
      }, {
        content: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.modelValue.order.order1,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.order.order1) = $event)),
            placeholder: "请选择第一优先级",
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "创建时间越晚越靠前",
                value: "0"
              }),
              _createVNode(_component_el_option, {
                label: "创建时间越早越靠前",
                value: "1"
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ])
  ]))
}