
import { LockDec, ValidateDec } from "@/decorators";
import { TSX, Vue, Component, Prop } from "vue-facing-decorator";

interface Props {
  modelValue: any
}

@Component({
})
export default class extends TSX<Props>()(Vue) implements Props {

  @Prop()
  modelValue: any;

  postData = {
    status: 1,
    content: '',
    serialNumber: ''
  }
  
  submitLoading = false;

  @ValidateDec("form")
  @LockDec("submitLoading")
  async submit() {
    await this.$postNotify("/manager/salesman/withdraw-audit", { ...this.postData, id: this.modelValue.id })
    this.$emit("resolve")
  }

  cancel() {
    this.$emit("reject", { msg: '取消' })
  }
}

