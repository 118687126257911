
import { Component, Vue } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
@Component({
  components: {}
})
export default class extends ListViewVue {
  listType = "router";
  getList() {
    return this.$get("/manager/case/category-list", {
      ...this.mesureData
    });
  }
  async deleteRow(id) {
    let { error, message } = await this.$post("/manager/case/delete-category", {
      id,
    });
    if (error) return this.$error(message);
    this.$success("删除成功");
    this.refresh();
  }
  gotoCategory(id?) {
    if (id) {
      return this.$goto({
        path: "/case/category/edit",
        query: {
          id,
        },
      });
    }
    this.$goto({
      path: "/case/category/create",
    });
  }
}
