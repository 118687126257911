
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;

  get skuText() {
    if (this.modelValue.goodsDetails.skuName)
      return this.modelValue.goodsDetails.skuName
    return ''
  }
  get goodsImage() {
    return this.modelValue.goodsDetails.cover
  }

  openDialog(data) {
    this.$dialog({
      title: '用户留言',
      props: {
        width: "500px",
      },
      content: () => {
        return <el-table data={data} show-header={false}>
          <el-table-column
            property="name"
            label=""
            width="80"
          ></el-table-column>
          <el-table-column property="value" label=""></el-table-column>
        </el-table>
      }
    });
  }
}
