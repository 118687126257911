
import { Component, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
import StoreFrom from "./dialog/store-form.vue"
@Component({})
export default class extends Vue {
  @Watch('store')
  storeWatch() {
    this.getData();
  }

  @State((state) => state.store.store)
  store;

  @Action("store/getStore")
  getStore
  sizeForm: any = {}
  created() {
    this.getData();
  }

  getData() {
    const { name, logo, description } = this.store;
    this.sizeForm = { name, logo, description };
  }
  setForm() {
    this.$dialog({
      props: {
        width: "800px"
      },
      title: '设置填报表单字段',
      content: () => {
        return <StoreFrom />
      }
    })
  }
  async submit() {
    await this.$postError("/manager/store/edit", this.sizeForm);
    this.getStore()
  }
}
