
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
})
export default class extends Vue {

  @Prop()
  modelValue: any;

  @Prop()
  showType: any;

  @Prop()
  type: any;

  @Watch('showType')
  @Watch('type')
  @Watch('modelValue')
  typeWatch() {
    this.mesureValue();
  }

  get placeholder() {
    return "";
  }
  showValue = ""
  created() {
    this.mesureValue();
  }

  mesureValue() {
    if (this.showType != this.type) {
      this.showValue = "";
    } else {
      this.showValue = this.modelValue;
    }
  }
  change(val) {
    this.$emit("change", val);
  }
}

