
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator';
interface Props {
  modelValue: any
};

@Component({
  components: {},
})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  modelValue: any

  get isDefaultSale() {
    return this.postData.type == 0
  }

  postData = {
    type: 0,
    levelList: []
  }

  created() {
    if (this.modelValue?.goodsInfo?.type) {
      this.postData.type = this.modelValue?.goodsInfo?.type
    }
    this.postData.levelList = JSON.parse(JSON.stringify(this.modelValue.levelList))
  }

  async submit() {
    await this.$postNotify("/manager/salesman/goods-operat", { ...this.postData, id: this.modelValue.id })
    this.$emit("resolve")
  }

  cancel() {
    this.$emit("reject", { msg: '取消' })
  }
}

