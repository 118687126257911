
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
interface Props {
  data: any
}

@Component({})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop({})
  data: any

  list: any[] = []
  created() {
    if (this.data) {
      this.list = JSON.parse(JSON.stringify(this.data));
    }
  }
  addRule() {
    this.list.push({
      month: '',
      price: ''
    })
  }
  removevIndex(index) {
    this.list.splice(index, 1)
  }
  saveEvent() {
    this.$emit("resolve", this.list)
  }
  cancelEvent() {
    this.$emit("reject", { msg: '用户取消' })
  }
}
