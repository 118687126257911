import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a603c59"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "choose-icon-box" }
const _hoisted_2 = { class: "choose-image-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "show-icon-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_ui_page = _resolveComponent("ui-page")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createElementBlock("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.source.list, (item, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: i,
            onClick: ($event: any) => (_ctx.changeChoose(item))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ui_icon, {
                modelValue: item.value
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", null, _toDisplayString(item.name), 1)
          ], 8, _hoisted_3))
        }), 128))
      ])), [
        [_directive_loading, _ctx.source.loading]
      ])
    ]),
    _createVNode(_component_ui_page, {
      style: {"margin-top":"16px"},
      info: _ctx.pageInfo,
      modelValue: _ctx.page.page,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page.page) = $event))
    }, null, 8, ["info", "modelValue"])
  ]))
}