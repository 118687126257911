
import { Component, Vue } from 'vue-facing-decorator'

import { VueCropper } from "vue-cropper";
import 'vue-cropper/dist/index.css'

@Component({
  components: { VueCropper },
})
export default class extends Vue {
  option: any = {
    img: null,
  }
  info = null
  loading = false;

  changeFile(e) {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (evt) => {
        this.option.img = evt.target?.result;
      };
      reader.readAsDataURL(file);
    }
    e.target.value = "";
  }


  async submitForm() {
    if (this.loading) return;
    this.loading = true;
    let { error, data } = await new Promise<ReturnType<typeof this.$upload>>((r) => {
      (this.$refs.cropper as any).getCropBlob((data) => {
        let fd = new FormData();
        let file = new File([data], "test.png");
        fd.append("file", file);
        r(this.$upload("/manager/open-platform/upload-media", fd));
      });
    });
    if (error || !data.media_id) return;
    await this.$post("/manager/open-platform/set-wechat-avatar", {
      mediaId: data.media_id,
    });
    this.loading = false;
    if (error) return;
    this.$emit('resolve');
  }
}
