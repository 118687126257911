
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({

})
export default class extends Vue {
  @Prop({
    default() {
      return 60
    }
  })
  modelValue: number

  @Watch('modelValue', {
    immediate: true
  })
  modelValueWatch() {
    this.s = this.modelValue;
    this.runloop();
  }

  s = 0;
  _isDestroyed = false;

  get showS() {
    if (this.s <= 0) return "0";
    return this.s;
  }

  runloop() {
    if (this._isDestroyed) return;
    setTimeout(() => {
      if (this._isDestroyed) return;
      if (this.s <= 0) {
        this.$emit("ended");
        return;
      }
      this.runloop();
      this.s = this.s - 1;
    }, 1000);
  }
  beforeUnmount() {
    this._isDestroyed = true;
  }
}
