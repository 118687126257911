
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import ChooseActiveity from "./choose-activeity.vue";

@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any

  @Watch('modelValue')
  modelValueWatch() {
    this.getData()
  }

  source: any = null;

  created() {
    this.getData();
  }

  async chooseData() {
    let data = await this.$dialog({
      title: "请选择活动",
      content() {
        return <ChooseActiveity></ChooseActiveity>;
      },
    });
    this.$emit("update:modelValue", data);
  }

  clearData() {
    this.$emit("update:modelValue", "");
    this.$forceUpdate()
  }

  async getData() {
    if (!this.modelValue || this.modelValue == "0") {
      this.source = null;
      return;
    }
    let { error, data } = await this.$get("/manager/activity/detail", {
      id: this.modelValue,
    });
    if (error) {
      this.source = null;
      return;
    }
    this.source = data;
  }
}
