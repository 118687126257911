import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d0f7a1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout01-box" }
const _hoisted_2 = {
  key: 0,
  class: "_title-text-box"
}
const _hoisted_3 = {
  key: 1,
  class: "_title-text-box"
}
const _hoisted_4 = {
  key: 2,
  class: "_content-text-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$slots.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]))
      : (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
    (_ctx.$slots.content)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}