import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-580ee60e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tag-deco-box" }
const _hoisted_2 = { class: "tag-deco-left-box" }
const _hoisted_3 = { class: "tag-deco-right-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Edit = _resolveComponent("Edit")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Delete = _resolveComponent("Delete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_icon, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit'))),
        size: "24"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Edit)
        ]),
        _: 1
      }),
      _createVNode(_component_el_icon, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete'))),
        size: "24"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Delete)
        ]),
        _: 1
      })
    ])
  ]))
}