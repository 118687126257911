
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  loading = true
  source = ''

  created() {
    this.getData()
  }

  async getData() {
    let { error, data } = await this.$getDownload(
      "/manager/open-platform/get-store-qrcode",
      {
      },
      {
        auto: false,
        method: 'GET'
      }
    );
    if (error) return
    this.source = data;
    this.loading = false;
  }
}

