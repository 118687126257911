import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_title = _resolveComponent("ui-title")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_ui_card = _resolveComponent("ui-card")!
  const _component_ui_container = _resolveComponent("ui-container")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_ui_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_ui_card, null, {
        title: _withCtx(() => [
          _createVNode(_component_ui_title, { title: "系统页面" })
        ]),
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createBlock(_component_ui_table, {
            data: _ctx.source.list
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "title",
                label: "名字"
              }),
              _createVNode(_component_el_table_column, {
                label: "操作",
                width: "150",
                align: "right"
              }, {
                default: _withCtx(({ row }) => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    icon: "Edit",
                    onClick: ($event: any) => (_ctx.gotoEdit(row))
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])), [
            [_directive_loading, _ctx.source.loading]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}