import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_title = _resolveComponent("ui-title")!
  const _component_el_color_picker = _resolveComponent("el-color-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ui_card = _resolveComponent("ui-card")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_ui_container = _resolveComponent("ui-container")!

  return (_openBlock(), _createBlock(_component_ui_container, { class: "container-box" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, { "label-width": "140px" }, {
        default: _withCtx(() => [
          _createVNode(_component_ui_card, null, {
            title: _withCtx(() => [
              _createVNode(_component_ui_title, { title: "颜色设置" })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_el_form_item, { label: "背景色" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_color_picker, {
                      modelValue: _ctx.source['--background-color'],
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.source['--background-color']) = $event)),
                      predefine: [_ctx.defaultData['--background-color']]
                    }, null, 8, ["modelValue", "predefine"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "全局字体颜色" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_color_picker, {
                      modelValue: _ctx.source['--font-color'],
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.source['--font-color']) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "主色调" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_color_picker, {
                      modelValue: _ctx.source['--primary-color'],
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.source['--primary-color']) = $event)),
                      predefine: [_ctx.defaultData['--primary-color']]
                    }, null, 8, ["modelValue", "predefine"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "次色调" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_color_picker, {
                      modelValue: _ctx.source['--secondary-color'],
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.source['--secondary-color']) = $event)),
                      predefine: [_ctx.defaultData['--secondary-color']]
                    }, null, 8, ["modelValue", "predefine"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "按钮次色" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_color_picker, {
                      modelValue: _ctx.source['--secondary-button-color'],
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.source['--secondary-button-color']) = $event)),
                      predefine: [_ctx.defaultData['--secondary-button-color']]
                    }, null, 8, ["modelValue", "predefine"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "页头背景" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_color_picker, {
                      modelValue: _ctx.source['--nav-background'],
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.source['--nav-background']) = $event)),
                      predefine: [_ctx.defaultData['--nav-background']]
                    }, null, 8, ["modelValue", "predefine"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "页头文字颜色" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_color_picker, {
                      modelValue: _ctx.source['--nav-color'],
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.source['--nav-color']) = $event)),
                      predefine: [_ctx.defaultData['--nav-color']]
                    }, null, 8, ["modelValue", "predefine"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "页头按钮边框" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_color_picker, {
                      modelValue: _ctx.source['--nav-left-border-color'],
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.source['--nav-left-border-color']) = $event)),
                      predefine: [_ctx.defaultData['--nav-left-border-color']]
                    }, null, 8, ["modelValue", "predefine"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "状态栏颜色" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio_group, {
                      modelValue: _ctx.source['--nav-status-color'],
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.source['--nav-status-color']) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio_button, { label: "#000000" }, {
                          default: _withCtx(() => [
                            _createTextVNode("黑")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_radio_button, { label: "#FFFFFF" }, {
                          default: _withCtx(() => [
                            _createTextVNode("白")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_el_form_item, { style: {"margin":"0"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    onClick: _ctx.submit,
                    type: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("应用修改")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}