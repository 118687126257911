
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;

  get title() {
    if (this.modelValue.title) return this.modelValue.title;
    return "点击编辑内容";
  }
  get content() {
    if (this.modelValue.content) return this.modelValue.content;
    return "这是描述信息";
  }
  get style() {
    return (this.modelValue.style);
  }
  get titleStyle() {
    return (this.modelValue.titleStyle);
  }
  get contentStyle() {
    return (this.modelValue.contentStyle);
  }
}



