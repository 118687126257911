
import { Vue, Component } from "vue-facing-decorator";
import { ListViewVue } from '@/common/BaseVue'
import DialogAudit from './com/dialog-audit/index.vue'
@Component({
  components: {},
})
export default class extends ListViewVue {
  listType = "router";

  tableChange(type) {
    let data = { ...this.condition };
    data.type = type;
    this.find(data);
  }

  async editInfo(row) {
    await this.$dialog({
      title: '提现申请',
      props: {
        width: '800px'
      },
      content: () => {
        return <DialogAudit modelValue={row} />
      }
    })
    this.refresh()
  }

  operatGoods(id?) {
    if (id) {
      return this.$goto({
        path: "/goods/goods/edit",
        query: {
          id,
        },
      });
    }
    return this.$goto({
      path: "/goods/goods/create",
    });
  }
  async getList() {
    return this.$get("/manager/salesman/withdraw-list", {
      ...this.mesureData,
    });
  }
  resetFilter() {
    this.find({ ...this.getCondition(), type: this.condition.type });
  }
}

