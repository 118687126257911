import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"color":"#999","font-size":"12px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ui_title = _resolveComponent("ui-title")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_ui_page = _resolveComponent("ui-page")!
  const _component_ui_card = _resolveComponent("ui-card")!
  const _component_ui_container = _resolveComponent("ui-container")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_ui_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_ui_card, null, {
        title: _withCtx(() => [
          _createVNode(_component_ui_title, { title: "案例分类" }, {
            right: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gotoCategory()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("添加分类")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createBlock(_component_ui_table, {
            data: _ctx.source.list
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, { label: "分组名称" }, {
                default: _withCtx(({ row }) => [
                  _createVNode(_component_el_link, {
                    type: "primary",
                    underline: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(row.name), 1)
                    ]),
                    _: 2
                  }, 1024),
                  (row.desc)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(row.desc), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, { label: "创建时间" }, {
                default: _withCtx(({ row }) => [
                  _createTextVNode(_toDisplayString(_ctx.$format(row.createTime)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "操作",
                align: "right"
              }, {
                default: _withCtx(({ row }) => [
                  _createVNode(_component_ui_button, {
                    onClick: ($event: any) => (_ctx.gotoCategory(row.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("编辑")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_el_popconfirm, {
                    title: "是否删除该记录",
                    onConfirm: ($event: any) => (_ctx.deleteRow(row.id)),
                    style: {"margin-left":"10px"}
                  }, {
                    reference: _withCtx(() => [
                      _createVNode(_component_ui_button, { type: "danger" }, {
                        default: _withCtx(() => [
                          _createTextVNode("删除")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onConfirm"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])), [
            [_directive_loading, _ctx.source.loading]
          ]),
          _createVNode(_component_ui_page, {
            modelValue: _ctx.page.page,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page.page) = $event)),
            info: _ctx.pageInfo
          }, null, 8, ["modelValue", "info"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}