
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;

  get wrapStyle() {
    return { padding: `0 ${this.modelValue.pageGap}px` };
  }
  get listWapStyle() {
    return {
      margin: `-${this.modelValue.imageGap}px 0`,
    };
  }
  get listStyle() {
    let gap = this.modelValue.imageGap;
    if (this.modelValue.orderMode != "oneline") {
      gap = 0;
    }
    return {
      margin: `${gap}px 0`,
      borderRadius: `${this.modelValue.imageRadius}`,
    };
  }
  get carouselHeight() {
    if (this.firstWidth == 0) return `${this.firstHeight}`;
    let radio = this.width / this.firstWidth;
    return `${this.firstHeight * radio}px`;
  }
  firstHeight = 0
  firstWidth = 0
  width = 0
  async getHeight() {
    let wrapBox = this.$refs.warpBox as any
    this.width = wrapBox.$el.offsetWidth;
  }
  loadImage(e, index) {
    if (index == 0) {
      let height = e.target.naturalHeight || e.target.height;
      let width = e.target.naturalWidth || e.target.width;
      this.firstHeight = height;
      this.firstWidth = width;
      this.getHeight();
    }
  }
}
