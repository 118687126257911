import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28246fda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "province-list-box" }
const _hoisted_2 = { class: "choose-address-operat-box" }
const _hoisted_3 = { class: "operat-right-box" }
const _hoisted_4 = {
  key: 0,
  style: {"margin-right":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProvinceItem = _resolveComponent("ProvinceItem")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.province, (item, i) => {
        return (_openBlock(), _createBlock(_component_ProvinceItem, {
          selected: _ctx.selected,
          checked: _ctx.checked,
          onCheckChange: _ctx.checkChange,
          onChange: _ctx.changePopupCity,
          item: item,
          disabled: _ctx.disabled,
          key: i
        }, null, 8, ["selected", "checked", "onCheckChange", "onChange", "item", "disabled"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_checkbox, {
        onChange: _ctx.toggleCheckAll,
        modelValue: _ctx.isCheckAll,
        indeterminate: _ctx.indeterminate
      }, {
        default: _withCtx(() => [
          _createTextVNode("全国")
        ]),
        _: 1
      }, 8, ["onChange", "modelValue", "indeterminate"]),
      _createElementVNode("div", _hoisted_3, [
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, " 已选择" + _toDisplayString(_ctx.checkedLength) + "个地区 ", 1))
          : _createCommentVNode("", true),
        _createVNode(_component_ui_button, { onClick: _ctx.submit }, {
          default: _withCtx(() => [
            _createTextVNode("确定")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_ui_button, {
          type: "",
          onClick: _ctx.cancel
        }, {
          default: _withCtx(() => [
            _createTextVNode("取消")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}