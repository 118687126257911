
import { Component, Vue } from 'vue-facing-decorator';
import Menu from "./page/menu/index.vue";
import Limit from "./page/limit/index.vue";
import Role from "./page/role/index.vue";
import Store from "./page/store/index.vue";
import Category from "./page/category/index.vue";
import VersionList from "./page/version-list/index.vue";

@Component({
  components: { Menu, Limit, Role, Store, Category ,VersionList}
})
export default class extends Vue {
  value = "menu"
  list = [
    {
      name: '店铺菜单',
      value: 'menu'
    },
    {
      name: '权限列表',
      value: 'limit'
    },
    {
      name: '系统角色',
      value: 'role'
    },
    {
      name: '店铺分类',
      value: 'category'
    },
    {
      name: '店铺列表',
      value: 'store'
    },
    {
      name: '版本管理',
      value: 'version-list'
    }
  ]
}
