
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  info: any = null
  ruleForm = {
    signature: "",
  }

  loading = false;
  async submitForm() {
    if (this.loading) return;
    this.loading = true;
    let { error } = await this.$post(
      "/manager/open-platform/set-wechat-signature",
      this.ruleForm
    );
    this.loading = false;
    if (error) return;
    this.$emit('resolve');
  }
}

