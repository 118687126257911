import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9f1af1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-container" }
const _hoisted_2 = { class: "user-order-menu-box" }
const _hoisted_3 = { class: "user-order-menu-item-row-left-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "user-order-menu-item-box"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (cItem, cIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: cIndex,
              class: "user-order-menu-item-row-box"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_ui_icon, {
                  class: "icon-box",
                  modelValue: cItem.icon
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", null, _toDisplayString(cItem.name), 1)
              ]),
              _createVNode(_component_ui_icon, {
                class: "icon-box",
                modelValue: "iconiconfontjiantou2"
              })
            ]))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}