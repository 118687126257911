
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
@Component({
  components: {
  }
})
export default class extends ListViewVue {

  @Prop()
  info: any;
 
  page = {
    page: 1,
    pageSize: 40
  }
  changeChoose(item) {
    this.$emit("resolve", { url: item.value, type: "icon" });
  }
  async getList() {
    return this.$get("/manager/common/icon-list", {
      ...this.mesureData,
    });
  }
}
