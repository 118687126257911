
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import ChooseFile from "./choose-file/index.vue";
import ChooseIcon from "./choose-icon/index.vue";
interface Props {
  info: any
}

@Component({
  components: {
    ChooseFile,
    ChooseIcon
  }
})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  info: any;

  activeName = "first"
  resolve() {
    this.$emit("resolve", ...arguments)
  }
  reject() {
    this.$emit("reject", ...arguments)
  }
}
