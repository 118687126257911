
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {
  }
})
export default class extends Vue {
  @Prop()
  modelValue: any

  get type() {
    let value = this.modelValue || ''
    if (value.indexOf("%u") == 0) {
      return "unicode";
    } else if (value.indexOf("http") == 0) {
      return "url";
    }
    return "class";
  }

  get showContent() {
    if (this.type == "unicode") {
      return unescape(this.modelValue);
    }
    return "";
  }
  get classStyle() {
    if (this.type == "unicode") {
      return {};
    }
    return this.modelValue;
  }
}
