
import { LockDec, ValidateDec } from "@/decorators";
import { ElCheckbox, ElInput } from "element-plus";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  loading = false;
  checked = true
  countdown = false;
  codeloading = false;

  post = {
    phone: "",
    loginpwd: "",
    code: "",
  }

  gotoEvent(path) {
    this.$goto(path);
  }

  @LockDec("codeloading")
  async getCode() {
    if (!this.checked) return this.$alert('请先同意用户协议、隐私政策');
    let { error, data, message } = await this.$post('/manager/common/get-code', {
      phone: this.post.phone,
      action: "register",
    });
    if (error) return this.$error(message);
    this.countdown = true;
  }

  gotoUrl(e) {
    e.preventDefault();
  }

  @ValidateDec("form", {
    showTips: true
  })
  async request() {
    let { error, message } = await this.$post('/manager/common/register', this.post);
    if (error) return this.$error(message);
    this.$success("恭喜您注册成功");
    this.$goto("/login");
  }
}
