import Vue from 'vue';
import { request } from "@/plugins/api/index"
import { utils } from '@/plugins/utils/index'

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token'),
    user: null
  },
  actions: {
    async login({ commit }, post) {
      let { error, data, message, origin } = await request.$post('/manager/common/login', post);
      if (error) return { error, data, message, origin }
      commit('login', { token: data.token });
      return { error, data, message, origin }
    },
    async getUserInfo({ commit }, post) {
      let { error, data, message, origin } = await request.$get('/manager/common/userinfo')
      if (error) return { error, data, message, origin };
      commit('setUserinfo', data);
      return { error, data, message, origin };
    },
    async logout({ commit }, postData = {}) {
      let promise: ReturnType<typeof request.$get> = Promise.resolve({ message: 'ok' });
      let defaultData = {
        needRquest: true,
        tooltip: true,
        ...postData
      }
      if (defaultData.needRquest) {
        promise = request.$get('/manager/common/login-out');
      }
      await promise;
      if (defaultData.tooltip) {
        utils.$success('成功退出')
      }
      commit('logout');
      const router = await import("@/router/index");
      router.default.replace({ path: "/login" });
      return promise
    }
  },
  mutations: {
    login(state, { token }) {
      state.token = token;
      localStorage.setItem('token', token)
    },
    logout(state) {
      localStorage.removeItem('token')
      state.token = '';
      state.user = null;
    },
    setUserinfo(state, user) {
      state.user = user
    }
  },
  getters: {}
}