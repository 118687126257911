
import ShowCategoryItem from "./show-category-item.vue";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {
    ShowCategoryItem,
  }
})
export default class extends Vue {

  @Prop()
  modelValue: any;
  selected = 0

  get wrapClass() {
    return `item-container-${this.modelValue.showType}`;
  }
  get tabbar() {
    let res = [];
    if (this.modelValue.showType != "left" && this.modelValue.showAll) {
      res.push({ name: "全部", all: 1, count: 0, id: "" });
    }
    if (!this.modelValue.list || this.modelValue.list.length == 0) {
      res.push(
        { name: "分类一" },
        { name: "分类二" },
        { name: "分类三" },
        {
          name: "分类四",
        },
        {
          name: "分类五",
        }
      );
    } else {
      res.push(...this.modelValue.list);
    }
    return res;
  }
  get listWarpStyle() {
    return { padding: `0 ${this.modelValue.pageGap}px` };
  }
}


