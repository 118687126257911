
import Member from "./member.vue";
import { Component, Vue } from 'vue-facing-decorator'

@Component({
  components: { Member },
})
export default class extends Vue {
  activeName = "1"
  loading = true
  source = ''
  created() {
    this.getData();
  }

  async getData() {
    let { error, data } = await this.$getDownload(
      "/manager/open-platform/test-qrcode",
      {
      },
      {
        auto: false,
        method: 'GET'
      }
    );
    if (error) return
    this.source = data;
    this.loading = false;
  }
}
