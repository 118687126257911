
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop()
  skuValue: any
  
  @Prop()
  skuInfo: any

  @Prop()
  checked: any

  @Watch('computSku', { deep: true })
  computSkuWatch(val) {
    let lengthHash = {};
    for (let i = val.length - 1; i >= 0; i--) {
      if (i == val.length - 1) {
        lengthHash[i] = 1;
      } else {
        lengthHash[i] = lengthHash[i + 1] * val[i + 1].list.length;
      }
    }
    let list = this.getLoop(val, true);
    let res = [];
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      let _val = this.getRowData(item);
      _val = Object.assign(_val, this.value[i]);
      res.push(_val);
      item.map((cItem, cIndex) => {
        let rowLength = item.length - 1;
        let isLast = cIndex == rowLength;
        let value;
        if (isLast) {
          value = this.value[i].id;
        } else {
          let start =
            Math.floor(i / lengthHash[cIndex]) * lengthHash[cIndex];
          value = this.value.slice(start, lengthHash[cIndex] + start);
        }
        _val[`sk${cIndex}`] = { name: cItem.value, value };
      });
    }
    this.skuDetail = res;
    return;
  }


  skuDetail = null
  chooseList = {}
  sku = []

  created() {
    this.skuDetail = this.skuValue
  }

  getIndeter(item, chooseList) {
    if (!item) return false;
    if (typeof item.value == "number") {
      return false;
    }
    let counts = {
      checked: 0,
      unchecked: 0,
    };
    for (let i = 0; i < item.value.length; i++) {
      let cItem = item.value[i];
      if (chooseList[cItem.id]) {
        counts.checked++;
      } else {
        counts.unchecked++;
      }
      if (counts.checked > 0 && counts.unchecked > 0) return true;
    }
    return false;
  }
  getChecked(item, chooseList) {
    if (!item) return false;
    if (typeof item.value == "number") {
      if (chooseList[item.value]) {
        return true;
      }
      return false;
    }
    for (let i = 0; i < item.value.length; i++) {
      let cItem = item.value[i];
      if (!chooseList[cItem.id]) return false;
    }
    return true;
  }

  get chooseText() {
    let length = Object.keys(this.checked || {}).length;
    if (length) {
      return `已选择规格(${length})`;
    }
    return "请选择规格";
  }

  get isIndeter() {
    return this.chooseLength < this.value.length && this.chooseLength > 0;
  }

  get isCheckAll() {
    return this.chooseLength == this.value.length;
  }
  get chooseLength() {
    return Object.keys(this.chooseList).length;
  }
  get value() {
    return this.skuDetail;
  }

  get computSku() {
    let newVal = [];
    for (let i = 0; i < this.sku.length; i++) {
      let item = this.sku[i];
      if (item.list.length == 0) {
        continue;
      }
      newVal.push(item);
    }
    return newVal;
  }

  toggleAll(value) {
    if (value) {
      let data = {};
      for (let i = 0; i < this.value.length; i++) {
        let item = this.value[i];
        data[item.id] = true;
      }
      this.chooseList = data;
      return;
    }
    this.chooseList = {};
  }
  changeItem(checked, item) {
    if (typeof item.value == "number") {
      if (checked) {
        this.chooseList[item.value] = true;
      } else {
        delete this.chooseList[item.value];
      }
      this.chooseList = { ...this.chooseList };
      return;
    }
    item.value.map((cItem) => {
      if (checked) {
        this.chooseList[cItem.id] = true;
      } else {
        delete this.chooseList[cItem.id];
      }
    });
    this.chooseList = { ...this.chooseList };
  }
  confirmData() {
    this.$emit("change", Object.keys(this.chooseList));
    this.closeDialog();
  }
  closeDialog() {
    (this.$refs.popover as any).hide();
  }
  async showEvent() {
    this.sku = [...this.skuInfo];
    this.chooseList = { ...this.checked };
  }
  getRowData(item) {
    let uuid_ = item.reduce((pre, item) => {
      return pre + item.uuid_;
    }, "");
    let skuInfo = this.sku.map((skuItem, index) => {
      let skuValueItem = item[index] || {};
      if (skuItem.image == "1") {
        return {
          name: skuItem.value,
          image: skuValueItem.image,
          value: skuValueItem.value,
        };
      }
      return { name: skuItem.value, value: skuValueItem.value };
    });
    return { weight: "", price: "", stock: "", uuid_, skuInfo };
  }
  arraySpanMethod({ rowIndex, columnIndex }) {
    if (columnIndex >= this.computSku.length) return [1, 1];
    if (columnIndex == 0) {
      let rowspan = 1;
      if (
        this.computSku[1] &&
        this.computSku[1].list &&
        this.computSku[1].list.length
      ) {
        rowspan *= this.computSku[1].list.length;
      }
      if (
        this.computSku[2] &&
        this.computSku[2].list &&
        this.computSku[2].list.length
      ) {
        rowspan *= this.computSku[2].list.length;
      }
      if (rowIndex % rowspan !== 0) {
        return {
          colspan: 1,
          rowspan: 0,
        };
      }
      return {
        rowspan,
        colspan: 1,
      };
    }
    if (columnIndex == 1) {
      let rowspan = 1;
      if (
        this.computSku[2] &&
        this.computSku[2].list &&
        this.computSku[2].list.length
      ) {
        rowspan *= this.computSku[2].list.length;
      }
      if (rowIndex % rowspan !== 0) {
        return {
          colspan: 1,
          rowspan: 0,
        };
      }
      return {
        rowspan,
        colspan: 1,
      };
    }
    return [1, 1];
  }
  getLoop(array, isRoot, firstLength?: number) {
    if (array.length == 0) return [];
    if (isRoot) {
      firstLength = array.length;
    }
    let res = [];
    for (let i = 0; i < array.length; i++) {
      let item = array[i].list;

      let child;
      if (array.length > 1) {
        child = this.getLoop(array.slice(i + 1), false, firstLength);
      }

      for (let k = 0; k < item.length; k++) {
        let kitem = item[k];
        if (array.length == 1) {
          res.push([kitem]);
        } else {
          for (let citem of child) {
            if (!isRoot) {
              res.push([kitem, ...citem]);
            } else if (firstLength - 1 == citem.length) {
              res.push([kitem, ...citem]);
            }
          }
        }
      }
    }
    return res;
  }

}
