import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd06470a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabbar-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_title = _resolveComponent("ui-title")!
  const _component_StorePage = _resolveComponent("StorePage")!
  const _component_MiniPage = _resolveComponent("MiniPage")!
  const _component_IcpPage = _resolveComponent("IcpPage")!
  const _component_PayPage = _resolveComponent("PayPage")!
  const _component_AuthPage = _resolveComponent("AuthPage")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_ui_card = _resolveComponent("ui-card")!
  const _component_ui_container = _resolveComponent("ui-container")!

  return (_openBlock(), _createBlock(_component_ui_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_ui_card, null, {
        title: _withCtx(() => [
          _createVNode(_component_ui_title, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tabIndex = 0)),
                  class: _normalizeClass({ selected: _ctx.tabIndex == 0 })
                }, " 店铺设置 ", 2),
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.tabIndex = 1)),
                  class: _normalizeClass({ selected: _ctx.tabIndex == 1 })
                }, " 小程序设置 ", 2),
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.tabIndex = 2)),
                  class: _normalizeClass({ selected: _ctx.tabIndex == 2 })
                }, " 备案 ", 2),
                _createElementVNode("div", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.tabIndex = 3)),
                  class: _normalizeClass({ selected: _ctx.tabIndex == 3 })
                }, " 微信支付 ", 2),
                _createElementVNode("div", {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.tabIndex = 4)),
                  class: _normalizeClass({ selected: _ctx.tabIndex == 4 })
                }, " 小程序认证 ", 2)
              ])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_form, { "label-width": "140px" }, {
            default: _withCtx(() => [
              (_ctx.tabIndex == 0)
                ? (_openBlock(), _createBlock(_component_StorePage, { key: 0 }))
                : (_ctx.tabIndex == 1)
                  ? (_openBlock(), _createBlock(_component_MiniPage, { key: 1 }))
                  : (_ctx.tabIndex == 2)
                    ? (_openBlock(), _createBlock(_component_IcpPage, { key: 2 }))
                    : (_ctx.tabIndex == 3)
                      ? (_openBlock(), _createBlock(_component_PayPage, { key: 3 }))
                      : (_ctx.tabIndex == 4)
                        ? (_openBlock(), _createBlock(_component_AuthPage, { key: 4 }))
                        : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}