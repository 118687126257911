import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f1fde2d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "com-select-box"
}
const _hoisted_2 = { class: "com-item-box" }
const _hoisted_3 = { class: "text-line" }
const _hoisted_4 = { class: "select-edit-box" }
const _hoisted_5 = { class: "select-edit-component-box" }
const _hoisted_6 = { class: "select-edit-component-box" }
const _hoisted_7 = {
  key: 0,
  class: "select-edit-tabbar-box"
}
const _hoisted_8 = { class: "phone-middle-box" }
const _hoisted_9 = { class: "phone-box" }
const _hoisted_10 = { class: "title-style-box" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 1,
  class: "right-position-conner"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 2,
  class: "save-button-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon_zhizhi = _resolveComponent("ui-icon-zhizhi")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_ui_edit_title = _resolveComponent("ui-edit-title")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_ctx.store && _ctx.store.style)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "custom-box",
        style: _normalizeStyle(_ctx.store.style)
      }, [
        (_ctx.showLeft)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_el_collapse, {
                modelValue: _ctx.activeNames,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeNames) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leftGroup, (item, index) => {
                    return (_openBlock(), _createBlock(_component_el_collapse_item, {
                      title: item.title,
                      key: index,
                      name: item.title
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_draggable, {
                          class: "com-select-list-box",
                          list: item.list,
                          sort: false,
                          onStart: ($event: any) => (_ctx.dragStarEvent(item.list, $event)),
                          group: { name: 'people', pull: 'clone', put: false }
                        }, {
                          item: _withCtx(({ element }) => [
                            _createElementVNode("div", _hoisted_2, [
                              _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_ui_icon_zhizhi, {
                                  modelValue: element.icon,
                                  class: "left-iconsize"
                                }, null, 8, ["modelValue"]),
                                _createElementVNode("div", null, _toDisplayString(element.name), 1)
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1032, ["list", "onStart"])
                      ]),
                      _: 2
                    }, 1032, ["title", "name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("div", _hoisted_5, [
            (_ctx.rightSide)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_ui_edit_title, {
                    title: _ctx.rightSide?.data?.name
                  }, null, 8, ["title"]),
                  (_ctx.selectItem)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.rightSide.Edit), {
                        modelValue: _ctx.selectItem.data,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectItem.data) = $event)),
                        key: _ctx.selectItem.uuid_
                      }, null, 8, ["modelValue"]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, _ctx.editModeData == 0]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_6, [
            _createVNode(_component_ui_edit_title, { title: "页面设置" }),
            _renderSlot(_ctx.$slots, "page", { parent: this }, undefined, true)
          ], 512), [
            [_vShow, _ctx.editModeData == 1]
          ]),
          (_ctx.slotPage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", {
                  class: _normalizeClass([{ selected: _ctx.editModeData == 0 }, "select-edit-tabbar-item"]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editModeData = 0))
                }, " 组件编辑 ", 2),
                _createElementVNode("div", {
                  class: _normalizeClass([{ selected: _ctx.editModeData == 1 }, "select-edit-tabbar-item"]),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editModeData = 1))
                }, " 页面设置 ", 2)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", {
              class: _normalizeClass(["phone-head-box", { 'white-header-box': _ctx.store.style['--nav-status-color'] != '#000000' }]),
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.clickHeader && _ctx.clickHeader(...args)))
            }, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.showTitle), 1)
            ], 2),
            _createElementVNode("div", {
              class: "phone-width",
              style: _normalizeStyle({ background: 'var(--background-color)' })
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fixedList, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.uuid_,
                  class: _normalizeClass(["item-drag-list disabled-drag", {
              selected: index == _ctx.scopedSelected,
            }]),
                  style: _normalizeStyle(_ctx.componentList[item.render].style),
                  onClick: ($event: any) => {_ctx.chooseIndex(index); _ctx.editModeData = 0;}
                }, [
                  (_ctx.componentList[item.render])
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentList[item.render].Show), {
                        key: 0,
                        ref_for: true,
                        ref: "fixedComlist",
                        modelValue: item.data,
                        "onUpdate:modelValue": ($event: any) => ((item.data) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                    : _createCommentVNode("", true)
                ], 14, _hoisted_11))
              }), 128)),
              _createVNode(_component_draggable, {
                modelValue: _ctx.arrayList,
                group: "people",
                onAdd: _ctx.addEvent,
                style: _normalizeStyle([{"min-height":"576px"}, _ctx.pageStyle]),
                ghostClass: "add-ghost-placeholder",
                onEnd: _ctx.endEvent,
                filter: ".undraggable",
                "item-key": "uuid_"
              }, {
                item: _withCtx(({ element, index }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["item-drag-list", {
                selected: index == _ctx.scopedSelected + _ctx.fixedList.length,
                undraggable: element.move === false,
              }]),
                    style: _normalizeStyle(_ctx.componentList[element.render].style),
                    onClick: ($event: any) => {_ctx.chooseIndex(index + _ctx.fixedList.length); _ctx.editModeData = 0;}
                  }, [
                    (_ctx.componentList[element.render])
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentList[element.render].Show), {
                          key: 0,
                          ref: "comlist",
                          modelValue: element.data,
                          "onUpdate:modelValue": ($event: any) => ((element.data) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("", true),
                    (element.delete !== false)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createElementVNode("div", {
                            class: "delete-button",
                            onClick: _withModifiers(($event: any) => (_ctx.removeEvent(index)), ["stop"])
                          }, [
                            _createVNode(_component_el_icon, { size: "14" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Delete)
                              ]),
                              _: 1
                            })
                          ], 8, _hoisted_14)
                        ]))
                      : _createCommentVNode("", true)
                  ], 14, _hoisted_12)
                ]),
                _: 1
              }, 8, ["modelValue", "onAdd", "onEnd", "style"])
            ], 4)
          ])
        ]),
        (_ctx.slotButton)
          ? _renderSlot(_ctx.$slots, "button", { key: 1 }, undefined, true)
          : (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_component_el_button, {
                onClick: _ctx.saveButton,
                type: "primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("保存")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
      ], 4))
    : _createCommentVNode("", true)
}