
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
})
export default class extends Vue {

  @Prop()
  color: any;

  get wrapClass() {
    switch (this.color) {
      case 'red':
      case 'green':
      case 'orange':
      case 'purple':
      case 'blue':
      case 'gray':
        return `tag-${this.color}-box`;
    }
    switch (Number(this.color)) {
      case -1:
        return 'tag-red-box';
      case 0:
        return 'tag-green-box';
      case 1:
        return 'tag-orange-box';
      case 2:
        return 'tag-purple-box';
      case 3:
        return 'tag-blue-box';
      case 4:
        return 'tag-gray-box';
    }
  }
}
