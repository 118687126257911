import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31dedce9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "goods-category-wrap"
}
const _hoisted_2 = { style: {"padding":"10px 15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_layout1 = _resolveComponent("ui-layout1")!
  const _component_ui_edit_share = _resolveComponent("ui-edit-share")!
  const _component_ui_custom = _resolveComponent("ui-custom")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ui_custom, {
          fixedValue: _ctx.fixedTemplateData,
          "edit-mode": "1",
          modelValue: _ctx.templateData,
          onSubmit: _ctx.operat,
          selected: 0,
          "page-name": _ctx.source.name
        }, {
          page: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ui_layout1, {
                title: "分类标题",
                class: "view-item-box"
              }, {
                content: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.source.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.source.name) = $event)),
                    placeholder: "请输入分类标题"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ui_edit_share, { data: _ctx.source }, null, 8, ["data"])
            ])
          ]),
          _: 1
        }, 8, ["fixedValue", "modelValue", "onSubmit", "page-name"])
      ]))
    : _createCommentVNode("", true)
}