
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
})
export default class extends Vue {

  @Prop()
  formData: any

  created() {
    this.formData.content = this.formData.content || [];
  }
  operatPage(data) {
    this.formData.content = data.list;
    this.$emit('submit')
  }
  saveButton() {
    (this.$refs.custom as any).saveButton();
  }
}

