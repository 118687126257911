import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_input = _resolveComponent("ui-input")!

  return (_openBlock(), _createBlock(_component_ui_input, _mergeProps(_ctx.$attrs, _toHandlers(_ctx.$listeners), {
    validate: "coin",
    "model-value": _ctx.showValue,
    placeholder: _ctx.placeholder,
    onChange: _ctx.change
  }), {
    append: _withCtx(() => [
      _renderSlot(_ctx.$slots, "append")
    ]),
    _: 3
  }, 16, ["model-value", "placeholder", "onChange"]))
}