import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1aa5140d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "onLoad"]
const _hoisted_2 = {
  key: 1,
  class: "title-style-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "item-container",
    style: _normalizeStyle(_ctx.wrapStyle)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        style: _normalizeStyle(_ctx.itemStyle),
        class: "image-list-item-box"
      }, [
        (_ctx.showImage)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "image-style-box",
              ref_for: true,
              ref: "imageBox",
              style: _normalizeStyle(_ctx.imageBoxStyle)
            }, [
              _createElementVNode("img", {
                src: item.cover || _ctx.defaultCover,
                onLoad: ($event: any) => (_ctx.loadImage($event, index))
              }, null, 40, _hoisted_1)
            ], 4))
          : _createCommentVNode("", true),
        (_ctx.showText)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", null, _toDisplayString(item.title), 1)
            ]))
          : _createCommentVNode("", true)
      ], 4))
    }), 128))
  ], 4))
}