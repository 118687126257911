
import draggable from "vuedraggable";
import defaultData from "./index";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {
    draggable,
  },
})
export default class extends Vue {
  @Prop()
  modelValue: any;
  defaultData = defaultData.data.data()
  created(): void {
    this.defaultData = defaultData.data.data()
  }

  get showText() {
    if (
      this.modelValue.showStyle == "image-text" ||
      this.modelValue.showStyle == "text"
    )
      return true;
    return false;
  }
  get showImage() {
    if (
      this.modelValue.showStyle == "image-text" ||
      this.modelValue.showStyle == "image"
    )
      return true;
    return false;
  }

  removeImage(index) {
    this.modelValue.list.splice(index, 1);
  }
  async addImage() {
    if (!this.showImage) {
      return this.modelValue.list.push(defaultData.data.createItem({}));
    }
    let data = await this.$chooseImage();
    this.modelValue.list.push(
      ...data.map((item) => {
        return defaultData.data.createItem({
          cover: item.url,
        });
      })
    );
  }
}
