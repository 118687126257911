

import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({

})
export default class extends Vue {

  @Prop()
  modelValue: any;

  @Watch('modelValue')
  modelValueWatch() {
    this.getData()
  }
  loading = false
  source: any = {}
  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  }
  async getData() {
    if (!this.modelValue) {
      return;
    }
    let { error, data } = await this.$get("/manager/goods/category-detail", {
      id: this.modelValue,
    });
    if (error) {
      this.source = null;
      return;
    }
    this.source = data;
  }
}
