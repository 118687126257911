import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from './images/checked.png'
import _imports_1 from './images/unchecked.png'


const _withScopeId = n => (_pushScopeId("data-v-43e8762e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-radio-text-before-box" }
const _hoisted_2 = { class: "checked-image-box" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1
}
const _hoisted_5 = { class: "ui-radio-text-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-radio-box", { 'ui-radio-checked-box': _ctx.isCheck }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleRadio && _ctx.toggleRadio(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "before", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isCheck)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : (_openBlock(), _createElementBlock("img", _hoisted_4))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}