
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
})
export default class extends Vue {

  @Prop()
  modelValue: any

  get showText() {
    switch (this.modelValue.status) {
      case 1:
        return "买家申请退款";
      case 2:
        return "等待买家退货";
      case 3:
        if (this.modelValue.userId) {
          return "退款结束";
        }
        return "主动退款";
      case 4:
        return "退款关闭";
    }
  }
}

