
import OperatIndex from "./operat/index.vue";
import OperatContent from "./operat/content.vue";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { LockDec } from "@/decorators";

@Component({
  components: {
    OperatIndex,
    OperatContent,
  },
})
export default class extends Vue {
  step = 0
  formData: any = {}
  loading = false
  submitLoading = false;
  created() {
    this.formData = this.getDefaultData()
    if (this.$route.query.id) {
      this.getData();
    }
  }

  getDefaultData() {
    return {
      name: "",
      description: "",
      categoryId: [],
      image: [],
      video: [],
      spec: [],
      skuList: [],
      price: "",
      originPrice: "",
      type: 1,
      showPrice: "",
      minBuy: 1,
      expressType: 1,
      expressPrice: "",
      sellStatus: 1,
      sellStartTime: null,
      buyButtonType: 1,
      buyButton: "",
      message: [],
      buyNum: 0,
    };
  }


  async getData() {
    this.loading = true;
    let { error, data } = await this.$get("/manager/goods/detail", {
      id: this.$route.query.id,
    });
    setTimeout(() => {
      this.loading = false;
    });
    if (error) return;
    this.formData = data;
  }


  @LockDec("submitLoading")
  async submit() {
    if (this.$route.query.type == "clone") {
      delete this.formData.id;
    }
    let { error, data, message } = await this.$post(
      "/manager/goods/operat",
      this.formData
    );
    if (error) return this.$error(message);
    if (this.formData.id) {
      this.$goto("/goods/goods/list");
      return this.$success("更新成功");
    }
    this.$success("保存成功");
    this.formData = this.getDefaultData();
  }
} 
