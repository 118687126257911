
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue';
import ChooseSku from "./choose-sku.vue";
interface Props {
  options: any
}

@Component({
  components: { ChooseSku }
})
export default class extends TSX<Props>()(ListViewVue) implements Props {

  @Prop()
  options: any;

  page = { pageSize: 5, page: 1 }

  get goodsLength() {
    return Object.keys(this.checked).length;
  }
  get isCheckAll() {
    if (!this.source.list) return false;
    for (let i = 0; i < this.source.list.length; i++) {
      let item = this.source.list[i];
      if (!this.checked[item.id]) return false;
      for (let citem of item.skuList) {
        if (!this.checked[item.id][citem.id]) return false;
      }
    }
    return true;
  }
  checked: any = {}
  created() {
    this.checked = { ...this.options }
  }
  toggleCheckAll(bool) {
    if (bool) {
      let res = {};
      this.source.list.map((item) => {
        res[item.id] = {};
        item.skuList.map((citem) => {
          res[item.id][citem.id] = true;
        });
      });
      this.checked = { ...this.checked, ...res };
      return;
    }
    this.source.list.map((item) => {
      delete this.checked[item.id];
    });
    this.checked = { ...this.checked };
  }
  toggleCheck(bool, row) {
    if (bool) {
      let res = {};
      row.skuList.map((item) => {
        res[item.id] = true;
      });
      this.checked[row.id] = res;
      this.checked = { ...this.checked };
      return;
    }
    delete this.checked[row.id];
    this.checked = { ...this.checked };
  }
  changeSku(goodsId, skuIds) {
    if (skuIds.length == 0) {
      delete this.checked[goodsId];
      this.checked = { ...this.checked };
      return;
    }
    let res = {};
    skuIds.map((item) => {
      res[item] = true;
    });
    this.checked[goodsId] = res;
    this.checked = { ...this.checked };
  }
  closeDialog() {
    this.$emit('reject', { msg: "取消选择" });
  }
  confirmDialog() {
    this.$emit('resolve', this.checked);
  }
  getList() {
    return this.$get("/manager/goods/list-sku-goods", {
      ...this.mesureData
    });
  }
  filterChecked(row, checked) {
    if (!checked[row.id]) return false;
    if (!row.skuList || row.skuList.length == 0) return true;
    let currentGoods = checked[row.id];
    if (Object.keys(currentGoods).length == row.skuList.length) return true;
    return false;
  }
  filterIndeter(row, checked) {
    if (!checked[row.id]) return false;
    if (!row.skuList || row.skuList.length == 0) return false;
    let currentGoods = checked[row.id];
    if (Object.keys(currentGoods).length < row.skuList.length) return true;
    return false;
  }
}
