import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b8d8ef0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "case-category-wrap" }
const _hoisted_2 = { class: "save-button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ui_custom = _resolveComponent("ui-custom")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_custom, {
      modelValue: _ctx.formData.content,
      onSubmit: _ctx.operatPage,
      pageName: _ctx.formData.name,
      ref: "custom"
    }, {
      button: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change-step', 0)))
          }, {
            default: _withCtx(() => [
              _createTextVNode("上一步")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            onClick: _ctx.saveButton,
            type: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode("保存")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onSubmit", "pageName"])
  ]))
}