
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import defaultData from "./index";
@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;
  defaultData: any = {}
  created(): void {
    this.defaultData = defaultData.data.data()
  }
}
