import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OperatIndex = _resolveComponent("OperatIndex")!
  const _component_OperatContent = _resolveComponent("OperatContent")!

  return (_ctx.step == 0)
    ? (_openBlock(), _createBlock(_component_OperatIndex, {
        key: 0,
        loading: _ctx.loading,
        formData: _ctx.formData,
        onChangeStep: _cache[0] || (_cache[0] = ($event: any) => (_ctx.step = $event)),
        onSubmit: _ctx.submit
      }, null, 8, ["loading", "formData", "onSubmit"]))
    : (_openBlock(), _createBlock(_component_OperatContent, {
        key: 1,
        loading: _ctx.loading,
        formData: _ctx.formData,
        onChangeStep: _cache[1] || (_cache[1] = ($event: any) => (_ctx.step = $event)),
        onSubmit: _ctx.submit
      }, null, 8, ["loading", "formData", "onSubmit"]))
}