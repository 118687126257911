
import MessageRow from "../com/message-row.vue";
import Sku from "../com/sku.vue";
import SkuDetail from "../com/sku-detail.vue";
import ComGroup from "../com/group.vue";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
  components: {
    MessageRow,
    Sku,
    SkuDetail,
    ComGroup,
  }
})
export default class extends Vue {
  @Prop()
  formData: any

  @Prop()
  submitLoading: any

  @Prop()
  loading: any

  goodsType = [
    {
      name: "实物商品",
      desc: "(物流发货)",
      value: 1,
    },
    {
      name: "虚拟商品",
      desc: "(无需物流)",
      value: 2,
    },
  ]
  changeExpressType(val, bool) {
    if (bool) {
      this.formData.expressType = this.formData.expressType | val;
    } else {
      this.formData.expressType = this.formData.expressType ^ val;
    }
  }
  async submit() {
    this.$emit("submit");
  }
  addRow() {
    this.formData.message.push({
      name: `留言${this.formData.message.length + 1}`,
      type: "text",
      placeholder: "",
      isRequired: 0,
    });
  }
  processVideo(url) {
    return `${url}?x-oss-process=video/snapshot,t_0,m_fast,w_240`;
  }
}
