import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6da9608"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-category-item-box" }
const _hoisted_2 = {
  key: 0,
  class: "list-cateogry-title-box"
}
const _hoisted_3 = { class: "goods-list-box" }
const _hoisted_4 = { class: "goods-item-inner-box" }
const _hoisted_5 = { class: "goods-images" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "goods-content" }
const _hoisted_8 = {
  key: 0,
  class: "goods-title"
}
const _hoisted_9 = { class: "goods-title-inner" }
const _hoisted_10 = {
  key: 0,
  class: "goods-title-desc"
}
const _hoisted_11 = {
  key: 1,
  class: "goods-opeart-box"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 2,
  class: "buy-button-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["item-inner-container", _ctx.listClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLeft)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.item.name), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "goods-item-box",
            style: _normalizeStyle(_ctx.goodsGapStyle)
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: item.cover,
                  style: _normalizeStyle(_ctx.imageStyle)
                }, null, 12, _hoisted_6)
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_ctx.modelValue.showTitle)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, _toDisplayString(item.name), 1),
                      (_ctx.isLeft && item.description)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(item.description), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.modelValue.showPrice || _ctx.modelValue.showBuyBtn)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", null, [
                        (_ctx.modelValue.showPrice)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, "￥" + _toDisplayString(item.showPrice), 1))
                          : _createCommentVNode("", true)
                      ]),
                      (_ctx.modelValue.showBuyBtn)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            (_ctx.modelValue.btnStyle == 0)
                              ? (_openBlock(), _createBlock(_component_ui_icon, {
                                  key: 0,
                                  modelValuee: "iconjia",
                                  class: "icon-right"
                                }))
                              : (_ctx.modelValue.btnStyle == 1)
                                ? (_openBlock(), _createBlock(_component_ui_icon, {
                                    key: 1,
                                    modelValuee: "icongoumai",
                                    class: "icon-right"
                                  }))
                                : (_openBlock(), _createElementBlock("div", _hoisted_14, "购买"))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 4))
        }), 128))
      ])
    ])
  ], 2))
}