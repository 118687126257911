
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
interface Props {
  parent?: any
  row?: any
}

@Component({
})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  parent: any

  @Prop()
  row: any
  ruleForm = {
    name: "",
    aliasName: '',
    path: "",
    active_menu: "",
    filePath: "",
    show: 0,
    redirect: 0,
    comment: "",
    roleItemInfo: null,
    sort: 0,
    icon: ''
  }

  list = []
  selectItem(data) {
    this.ruleForm.roleItemInfo = data;
  }
  created() {
    if (this.row) {
      this.ruleForm = { ...this.ruleForm, ...this.row };
    }
    this.getList();
  }
  async getList() {
    let { error, data } = await this.$get("/manager/super/common/role-item-list");
    if (error) return;
    this.list = data;
  }
  async confirmData() {
    await this.$post("/manager/super/common/operat-menu-item", {
      ...this.ruleForm,
      id: this.row ? this.row.id : undefined,
      parentId: this.parent ? this.parent.id : undefined,
      roleItemId: this.ruleForm.roleItemInfo
        ? this.ruleForm.roleItemInfo.id
        : null,
    });
    this.$emit('resolve', []);
  }
  closeDialog() {
    this.$emit('reject', { msg: "已取消" });
  }
}
