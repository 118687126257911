
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
import ProvinceItem from "./province-item.vue";
interface Props {
  disabledList: any[]
  selectList: any[]
}

@Component({
  components: {
    ProvinceItem
  }
})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop({
    default() {
      return []
    }
  })
  disabledList: any[]


  @Prop({
    default() {
      return []
    }
  })
  selectList: any[]

  @Action("address/getAddress")
  getAddress;

  @State(state => state.address.hash) cityHash
  @State(state => state.address.province) province

  selected = ""
  checked: any = {}
  disabled: any = {}


  get isCheckAll() {
    return this.checked[0];
  }
  get indeterminate() {
    if (this.isCheckAll) return false;
    return Object.keys(this.checked).length > 0;
  }
  get checkedLength() {
    return Object.keys(this.checked).length;
  }
  get disabledChecked() {
    // 获取禁止选中hash
    let res = {};
    for (let code in this.disabled) {
      res[code] = true;
      res = Object.assign(res, this.initParentDisabled(this.cityHash[code]));
      res = Object.assign(
        res,
        this.initChildrenDisabled(this.cityHash[code])
      );
    }
    return res;
  }


  created() {
    this.disabledList.map((code) => {
      this.disabled[code] = true;
    });
    this.disabled = { ...this.disabled };
    this.selectList.map((code) => {
      this.checked[code] = true;
    });
    this.checked = { ...this.checked };
    this.getAddress()
  }



  initParentDisabled(areaData, res = {}) {
    res[areaData.code] = true;
    if (areaData.parentCode == -1) {
      return res;
    }
    return this.initParentDisabled(this.cityHash[areaData.parentCode], res);
  }
  initChildrenDisabled(areaData, res = {}) {
    if (!areaData.children || Object.keys(areaData.children).length == 0) {
      return res;
    }
    Object.keys(areaData.children).map((code) => {
      res[code] = true;
      this.initChildrenDisabled(this.cityHash[code], res);
    });
    return res;
  }
  submit() {
    this.$emit('resolve', Object.keys(this.checked));
  }
  cancel() {
    this.$emit("reject", { msg: "已取消" });
  }
  toggleCheckAll(bool) {
    if (bool) {
      if (Object.keys(this.disabledChecked).length > 0) {
        this.checkChange({
          code: 0,
          checked: true,
        });
        return;
      }
      this.checked = { 0: true };
      return;
    }
    this.checked = {};
  }
  checkChange({ code, checked }) {
    let changeData = this.cityHash[code];
    this.removeAllChildren(changeData.children);
    if (checked) {
      this.checkItem(changeData);
    } else {
      this.uncheckItem(changeData, []);
    }
    this.checked = { ...this.checked };
  }
  checkItem(changeData) {
    if (this.disabledChecked[changeData.code]) {
      this.checkAllChildren(this.cityHash[changeData.code].children);
      return; // 有禁用项不需要递归
    } else {
      // 如果可以选中直接成功
      this.checked[changeData.code] = true;
    }
    if (changeData.parentCode == -1) {
      return; /// 根目录无法递归
    }
    let siblings = Object.keys(
      this.cityHash[changeData.parentCode].children
    );
    for (let i = 0; i < siblings.length; i++) {
      let code = siblings[i];
      if (!this.checked[code]) {
        return; // 兄弟节点没有选中，不需要递归
      }
    }
    for (let i = 0; i < siblings.length; i++) {
      let code = siblings[i];
      delete this.checked[code];
    }
    // 所有子类选中，删除所有子类并升级
    this.checkItem(this.cityHash[changeData.parentCode]);
  }
  checkAllChildren(children) {
    for (let code in children) {
      if (this.disabledChecked[code]) {
        if (this.cityHash[code] && this.cityHash[code].children) {
          this.checkAllChildren(this.cityHash[code].children);
        }
        continue;
      }
      this.checked[code] = true;
    }
  }
  uncheckItem(changeData, list) {
    if (this.checked[changeData.parentCode]) {
      // 父级 如果是全选 那么子集需要选中
      let sibling = this.cityHash[changeData.parentCode].children;
      for (let code in sibling) {
        this.checked[code] = true;
      }
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let hash = { ...this.cityHash[item.parentCode].children };
        delete hash[item.code];
        this.checked = { ...this.checked, ...hash };
      }
      delete this.checked[changeData.parentCode];
    } else {
      if (this.cityHash[changeData.parentCode].parentCode != -1) {
        list.push(changeData);
        this.uncheckItem(this.cityHash[changeData.parentCode], list);
      }
    }
    delete this.checked[changeData.code]; //也许没用，不影响性能
  }
  removeAllChildren(children) {
    for (let code in children) {
      delete this.checked[code];
      let codeArea = this.cityHash[code];
      this.removeAllChildren(codeArea.children);
    }
  }
  changeProvince(province, checked) {
    if (checked) {
      this.checked[province.code] = true;
    } else {
      if (this.checked[province.parentCode]) {
        // 父级 如果是全选 那么子集需要选中
        let sibling = this.cityHash[province.parentCode].children;
        for (let code in sibling) {
          this.checked[code] = true;
        }
      }
      delete this.checked[province.parentCode];
      delete this.checked[province.code];
    }
    // 删除勾选的所有子类
  }
  changePopupCity(item) {
    if (this.selected == item) {
      return (this.selected = "");
    }
    this.selected = item;
  }
}

