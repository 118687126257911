
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop({
    default() {
      return true
    }
  })
  modelValue: any

  @Prop({
    default() {
      return true
    }
  })
  trueValue: any
  @Prop({
    default() {
      return false
    }
  })
  falseValue: any


  get isCheck() {
    return this.trueValue == this.modelValue;
  }
  toggleRadio() {
    if (this.isCheck) {
      return this.$emit("update:modelValue", this.falseValue);
    }
    return this.$emit("update:modelValue", this.trueValue);
  }
}
