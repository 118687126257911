
import { ListViewVue } from '@/common/BaseVue'
import TableHead from "./com/table-head/index.vue";
import TableBody from "./com/table-body/index.vue";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
  components: {
    TableHead,
    TableBody
  }
})
export default class extends ListViewVue {
  listType = "router";
  userList: any[] = []
  userLoading = false;

  get mesureList() {
    return this.userList.map((item) => {
      return { ...item, id: `${item.id}` }
    });
  }

  async created() {
    if (this.$route.query.userId) {
      let { error, data } = await this.$get("/manager/customer/list", {
        ids: [this.$route.query.userId],
      });
      if (error) return;
      this.userList = data.list
    }
  }

  async searchUser(query) {
    this.userLoading = true;
    let { error, data } = await this.$get("/manager/customer/list", {
      name: query,
    });
    this.userLoading = false;
    if (error) return;
    this.userList = data.list
  }
  tablist = [
    {
      name: "全部",
      value: "0",
    },
    {
      name: "待付款",
      value: "1",
    },
    {
      name: "待发货",
      value: "2",
    },
    {
      name: "已发货",
      value: "3",
    },
    {
      name: "已完成",
      value: "4",
    },
    {
      name: "已关闭",
      value: "5",
    },
    {
      name: "售后中",
      value: "6",
    },
  ]
  getCondition() {
    return {
      type: '2'
    }
  }
  async getList() {
    return this.$get("/manager/order/list", {
      ...this.mesureData,
    });
  }
  async exportData() {
    this.$getDownload("/manager/order/list", {
      export: 1,
      ...this.mesureData,
    });
  }
  resetFilter() {
    this.find(this.getCondition())
  }
}
