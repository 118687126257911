import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12c3a659"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-date-picker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_date_picker, _mergeProps(_ctx.$attrs, {
      style: {"width":"100%"},
      modelValue: _ctx.modelValue,
      "onUpdate:modelValue": _ctx.inputEvent
    }), null, 16, ["modelValue", "onUpdate:modelValue"])
  ]))
}