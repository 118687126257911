
import { LockDec } from '@/decorators';
import { Component, Vue } from 'vue-facing-decorator'
import { State } from 'vuex-facing-decorator';
@Component({})
export default class extends Vue {
  @State((state) => state.store.store)
  store;
  data: any = {}
  loading = false
  created() {
    this.getData();
  }
  orderInfo: any = null;

  renewalLoading = false;

  @LockDec("renewalLoading")
  async renewal() {
    if (!this.orderInfo) {
      this.orderInfo = await this.$postError("/manager/store/create-renewal-order");
    }
    let res = await this.$postError("/manager/store/get-renewal-order", {
      id: this.orderInfo.id
    })
    window.open(res.url, "_blank")
  }
  async getData() {
    this.loading = true;
    let { error, data } = await this.$get('/manager/store/statistics');
    this.loading = false;
    if (error) return;
    this.data = data;
  }
}
