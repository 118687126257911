import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "image-cover",
      style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.modelValue.url + ')' })
    }, null, 4),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, _toDisplayString(_ctx.modelValue.name), 1)
    ])
  ]))
}