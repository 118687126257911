
import { LockDec, ValidateDec } from '@/decorators';
import { Component, Prop, Vue } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  get checkHash() {
    let res = {};
    this.formData.roleList.map((id) => {
      res[id] = true;
    });
    return res;
  }
  ruleList = []

  loading = false;
  submitLoading = false;

  formData = {
    id: undefined,
    name: "",
    phone: "",
    roleList: [],
  }

  async created() {
    this.getRuleList();
    this.loading = true;
    await this.getData();
    this.loading = false;
  }

  changeCheck(item, bool) {
    if (bool) {
      this.formData.roleList.push(item.id);
    } else {
      this.formData.roleList.splice(
        this.formData.roleList.indexOf(item.id),
        1
      );
    }
  }
  async getRuleList() {
    let { error, data } = await this.$get("/manager/role/list", {
      pageSize: 100000,
    });
    if (error) return;
    this.ruleList = data.list;
  }
  async getData() {
    let id = this.$route.query.id;
    if (!id) return;
    let { error, data } = await this.$get("/manager/staff/info", { id });
    if (error) return;
    this.formData = data;
  }

  @LockDec("submitLoading")
  @ValidateDec('form')
  async submit() {
    if (this.formData.id) {
      await this.$postNotify("/manager/staff/edit", {
        ...this.formData,
      });
    } else {
      await this.$postNotify("/manager/staff/add", {
        ...this.formData,
      });
    }
    this.$router.back();
  }
}
