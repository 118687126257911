
import { Action, State } from 'vuex-facing-decorator';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  @State("store", {
    namespace: 'store'
  })
  store
  @State("menu", {
    namespace: 'store'
  })
  menu

  @State("user", {
    namespace: "user"
  })
  userInfo;

  @Action('user/logout')
  userLogout;

  @Action("store/getStore")
  getStore
  @Action("user/getUserInfo")
  getUserInfo;

  get showDrawer() {
    return this.screenWidth < 700;
  }

  get activePath(): string {
    for (let i = this.$route.matched.length; i--; i >= 0) {
      if (this.$route.matched[i].meta.isMenu) {
        return String(this.$route.matched[i].name)
      }
    }
    return ''
  }

  screenWidth = 0;
  isCollapse = false
  isCollapse1 = false
  async created() {
    this.resizeEvent();
    window.addEventListener("resize", this.resizeEvent);
    this.getStore()
    this.getUserInfo()
  }
  get showCollapse() {
    if (this.showDrawer) {
      return this.isCollapse1;
    }
    return this.isCollapse;
  }
  toggleCollapse() {
    if (this.showDrawer) {
      this.isCollapse1 = !this.isCollapse1;
      return;
    }
    this.isCollapse = !this.isCollapse;
  }
  resizeEvent() {
    this.screenWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
  }
  async handleCommand(command) {
    switch (command) {
      case "backhome":
        let { href } = this.$router.resolve("/home")
        return location.href = href
      case "logout":
        return this.userLogout()
    }
  }
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeEvent);
  }
}
