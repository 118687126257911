
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
import draggable from "vuedraggable";

import componentList, { menu } from "./com/index";
@Component({
  components: {
    draggable
  }
})
export default class extends Vue {

  @Prop()
  pageName: string;

  @Prop()
  pageStyle: any;

  @Prop({
    default() {
      return true;
    }
  })
  showLeft: boolean;

  @Prop({
    default() {
      return 0;
    }
  })
  editMode: number;


  @Prop({
    default() {
      return [componentList.Text.data.data()];
    }
  })
  modelValue: any[];

  @Prop({
    default() {
      return []
    }
  })
  fixedValue: any[];

  @Prop({
    default() {
      return -1
    }
  })
  selected: number;

  chooseIndex(index) {
    this.scopedSelected = Number(index);
  }

  @State((state) => state.store.store)
  store;


  get slotPage() {
    return !!this.$slots.page;
  }
  get slotButton() {
    return !!this.$slots.button;
  }
  get selectItem() {
    let index = Number(this.scopedSelected);
    if (index == -1) return;
    if (this.fixedList && this.fixedList[index]) {
      return this.fixedList[index];
    }
    index -= this.fixedList.length;
    if (!this.arrayList || !this.arrayList[index]) return;
    return this.arrayList[index];
  }
  get rightSide() {
    if (this.selectItem && componentList[this.selectItem.render]) {
      return componentList[this.selectItem.render];
    }
    return null
  }
  get showTitle() {
    return this.pageName || "";
  }
  leftGroup = menu

  componentList = componentList
  activeNames = ["基础组件"]
  scopedSelected: number = -1
  fixedList: any[] = []
  arrayList: any[] = []
  editModeData: number = 0;

  created() {
    this.fixedList = this.fixedValue.map((item) => {
      return { ...item, uuid_: this.$getUUID() };
    })
    this.scopedSelected = this.selected

    this.arrayList = this.modelValue.map((item) => {
      return { ...item, uuid_: this.$getUUID() };
    })
    this.editModeData = this.editMode
  }

  clickHeader() {
    if (this.slotPage) {
      this.editModeData = 1;
    }
  }
  dragStarEvent(list, evt) {
    evt.clone.data = (...args) => {
      return { ...list[evt.oldIndex].data(...args), uuid_: this.$getUUID() };
    };
  }

  addEvent(evt) {
    let { newIndex } = evt;
    this.arrayList.splice(newIndex, 0, {
      ...evt.clone.data(),
    });
  }

  removeEvent(index) {
    this.arrayList.splice(index, 1);
  }
  endEvent(evt) {
    let { newIndex, oldIndex } = evt;
    if (oldIndex == newIndex) return;
    if (this.arrayList[newIndex].move === false) return;
    let array = this.arrayList.splice(oldIndex, 1);
    this.arrayList.splice(newIndex, 0, ...array);
  }


  async saveButton() {
    let fixedComlist: any[] = this.$refs.fixedComlist as any
    if (fixedComlist) {
      for (let i = 0; i < fixedComlist.length; i++) {
        let item = fixedComlist[i];
      }
    }
    let comlist: any[] = this.$refs.comlist as any
    if (comlist) {
      // todo校验
      for (let i = 0; i < comlist.length; i++) {
        let item = comlist[i];
      }
    }
    this.$emit("submit", {
      fixed: this.fixedList.map((item) => {
        delete item.uuid_;
        return item;
      }),
      list: this.arrayList.map((item) => {
        delete item.uuid_;
        return item;
      }),
    });
  }
}

