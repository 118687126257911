
import { Component, Vue } from 'vue-facing-decorator'
import Custom from "@/com/ui/custom/com/index";
import { ValidateDec } from '@/decorators';
@Component({})
export default class extends Vue {
  get type() {
    return this.source.pathUri;
  }
  get showLeft() {
    switch (this.type) {
      case "menu":
        return false;
    }
    return true;
  }
  get fixedList() {
    switch (this.type) {
      case "personal":
        return this.getUsercenter(this.source.fixedTemplateData);
    }
    if (this.type == "menu") {
      if (this.source.fixedTemplateData.length == 1) {
        return [
          { delete: false, render: "MenuFixed" },
          this.source.fixedTemplateData[0],
        ];
      } else if (!(this.source.fixedTemplateData instanceof Array)) {
        return [
          { delete: false, render: "MenuFixed" },
          this.source.fixedTemplateData,
        ];
      }
    }
    return this.source.fixedTemplateData || [];
  }

  get list() {
    if (
      !this.source.templateData ||
      !(this.source.templateData instanceof Array)
    ) {
      return [];
    }
    return this.source.templateData;
  }

  get id() {
    return this.$route.query.id;
  }

  loading = false
  source: any = {}
  async created() {
    await this.getData();
  }

  getUsercenter(data) {
    if (!data || data.length == 0) {
      return [
        { ...Custom.UserCenter.data.data() },
        { ...Custom.Gap.data.data() },
        { ...Custom.UserOrderMenu.data.data() },
        { ...Custom.Gap.data.data() },
        { ...Custom.UserMenu.data.data() },
      ];
    }
    return data;
  }

  submitLoading = false

  @ValidateDec('submitLoading')
  async operatPage(data) {
    function getData() {
      return { fixedTemplateData: data.fixed, templateData: data.list };
    }
    let postData = getData.call(this);
    await this.$postNotify("/manager/page/operat", {
      ...this.source,
      id: this.id,
      ...postData,
    });
  }

  async getData() {
    if (!this.id) return;
    this.loading = true;
    let { error, data } = await this.$get("/manager/page/info", {
      id: this.id,
    });
    this.loading = false;
    if (error) return;
    this.source = data;
  }
}
