import { request } from "@/plugins/api/index"

let lastPromise = null

const getters = {
}

const actions = {
  async getAddress(state) {
    if (lastPromise) return lastPromise;
    lastPromise = request.$get("/manager/common/get-china-area")
    let { error, data } = await lastPromise;
    if (error) return lastPromise;
    state.commit('setAddress', data)
    return lastPromise;
  },
}

// mutations
const mutations = {
  setAddress(state, list) {
    let province = [];
    let hash = {
      0: {
        code: 0,
        parentCode: -1,
        name: '全国',
        children: {}
      }
    };
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      hash[item.code] = {
        ...item,
        children: {}
      }
      if (item.level == "0") {
        province.push(item);
      }
      hash[item.parentCode].children[item.code] = item;
    }
    state.hash = hash;
    state.province = province;
    state.list = list
  }
}

export default {
  namespaced: true,
  state: {
    list: [],
    province: [],
    hash: {}
  },
  actions,
  mutations,
  getters
}