
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
interface Props {
  row?: any
};

@Component({
})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  row: any;

  ruleForm: any = {
    name: "",
    templateId: "",

  }

  data: any = {}

  created() {
    this.ruleForm = { ...this.ruleForm, ...this.row };
    this.getList();
  }


  async getList() {
    let { error, data } = await this.$get("/manager/super/store/template-list");
    if (error) return;
    this.data = data;
  }

  async confirmData() {
    for (let i = 0; i < this.data.template_list.length; i++) {
      let item = this.data.template_list[i];
      if (item.template_id == this.ruleForm.templateId) {
        this.ruleForm.version = item.user_version;
        this.ruleForm.description = item.user_desc;
        continue;
      }
    }
    await this.$post('/manager/super/store/update-category', {
      ...this.ruleForm,
    });
    this.$emit('resolve', []);
  }

}
