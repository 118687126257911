
import SkuItem from "./sku-item.vue";
import { Component, Vue, Prop } from 'vue-facing-decorator'

@Component({
  components: {
    SkuItem,
  },
})
export default class extends Vue {
  @Prop()
  modelValue: any;


  removeIndex(index) {
    this.modelValue.splice(index, 1)
  }
  addItem() {
    this.modelValue.push({
      name: "",
      specList: [],
      showImage: "0",
    });
  }
}

