
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  @Prop()
  modelValue: string | number;

  @Prop()
  sign: string | number;

  @Prop({
    default() {
      return false;
    }
  })
  reverse: boolean;

  get classColor() {
    switch (this.direction) {
      case 1:
        return "sign-plus";
      case -1:
        return "sign-minus";
    }
    return "sign-flat";
  }

  get direction() {
    let getVal = (val) => {
      val = (val + "").replace(/^\+/, "");
      val = parseFloat(val);
      let diffValue = this.reverse ? -1 : 1;
      if (val > 0) return 1 * diffValue;
      if (val < 0) return -1 * diffValue;
      return 0;
    };
    if (this.sign || this.sign === 0) {
      return getVal(this.sign);
    }
    return getVal(this.modelValue);
  }

}
