
import { dialog } from '@/plugins/dialog/index'
import InstanceBox from './index.vue';
/**
 * 选择案例分类
 */
export default function (options) {
  options = Object.assign({
    multiple: true
  }, options);
  return dialog({
    title: '案例分组',
    content() {
      return <InstanceBox options={options}></InstanceBox>
    }
  })
}