
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
interface Props {
  options: any
}

@Component({})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  options: any;

  get payAmount() {
    let res = "0".toDecimal();
    res = this.orderInfo.orderGoods.reduce((prev, item) => {
      prev = item.price.toDecimal().mul(item.num).add(prev);
      return prev;
    }, res);
    function toNumber(v) {
      v = parseFloat(v);
      if (isNaN(v)) return 0;
      return v;
    }
    res = res
      .add(toNumber(this.ruleForm.expressAmount))
      .add(toNumber(this.ruleForm.changeAmount));
    return res.toString();
  }
  get title() {
    return `订单原价(含运费)：${this.orderInfo.origin_amount || ""} 元`;
  }

  arraySpanMethod({ row, column, rowIndex, columnIndex }) {
    if (columnIndex >= 4) {
      return {
        rowspan: this.orderInfo.orderGoods.length,
        colspan: 1,
      };
    }
  }

  checkAllGoods(bool) {
    let res = [];
    if (bool) {
      this.orderInfo.orderGoods.map((item) => {
        if (!item.pack_id) {
          res.push(item.id);
        }
      });
      this.ruleForm.goodsId = res;
      return;
    }
    this.ruleForm.goodsId = res;
  }

  async getData() {
    let { error, data } = await this.$get("/manager/order/view", {
      id: this.options.id,
    });
    if (error) return;
    this.ruleForm.changeAmount = data.changeAmount;
    this.ruleForm.expressAmount = data.expressAmount;
    this.orderInfo = data;
  }
  async submitForm() {
    let { error, message } = await this.$post("/manager/order/modify-price", {
      ...this.ruleForm,
      id: this.options.id,
    });
    if (error) return this.$error(message);
    this.$emit('resolve', []);
  }

  async created() {
    this.loaindg = true;
    await this.getData();
    this.loaindg = false;
  }

  loaindg = false
  orderInfo: any = {}

  ruleForm = {
    changeAmount: "",
    expressAmount: "",
    goodsId: []
  }
  rules = {}
} 
