
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import ShowGoods from "./show-goods.vue";
import ShowCategory from "./show-category.vue";
@Component({
  components: {
    ShowGoods,
    ShowCategory
  }
})
export default class extends Vue {
  @Prop()
  modelValue: any
}
