
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
})
export default class extends Vue {
  info: any = null;
  ruleForm = {
    name: "",
  }

  loading = false;

  async checkName() {
    let { error } = await this.$post(
      "/manager/open-platform/check-wechat-name",
      this.ruleForm
    );
    if (error) {
      this.info = { type: "error", msg: "该昵称不可用" };
      return;
    }
    this.info = { type: "success", msg: "恭喜该昵称可用" };
  }
  async submitForm() {
    if (this.loading) return;
    this.loading = true;
    let { error } = await this.$post(
      "/manager/open-platform/set-wechat-name",
      this.ruleForm
    );
    this.loading = false;
    if (error) return;
    this.$emit('resolve');
  }
}
