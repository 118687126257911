
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {},
})
export default class extends Vue {
  @Watch('title', {
    immediate: true
  })
  titleWatch() {
    this.changeData()
  }

  @Prop()
  title: string
  changeData() {
    if (this.title) {
      document.title = this.title;
    }
  }

}
