
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';

@Component({
})
export default class extends Vue {
  @State((state) => state.store.store)
  store;

  @Action("store/getLastStore")
  getLastStore

  @Action("store/getStore")
  getStore

  async created() {
    this.defaultData = this.getDefaultData()
    this.source = this.getDefaultData()
    this.getData();
  }

  defaultData: any = null;
  source: any = null;
  async submit() {
    await this.$postError('/manager/store/edit-style', {
      content: JSON.stringify(this.source),
    });
    this.$success("修改成功");
    this.getStore()
  }
  async getData() {
    let { error, message, data } = await this.getLastStore();
    if (error) return;
    this.source = { ...this.source, ...data.style };
  }

  getDefaultData() {
    return {
      // 全局颜色
      "--background-color": "",
      "--primary-color": "#FF0000", // 主色
      "--secondary-color": "#F2EEE2", //副色
      "--secondary-button-color": "#F2EEE2", //按钮副色
      "--gray0-color": "#999999",
      "--hover-background-color": "#F0F0F0",
      "--hover-color": "",
      // 全局颜色结束

      // 菜单栏
      "--nav-background": "#FFFFFF",
      "--nav-color": "#000",
      "--nav-left-border-color": "#E6E6E6",
      "--nav-status-color": "#000000", // 只能是#000000 或者 #ffffff
      // 菜单栏结束
    };
  }
}
