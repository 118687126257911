import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e86c1022"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tester-box" }
const _hoisted_2 = { style: {"text-align":"center"} }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "体验码",
          name: "1"
        }, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              (!_ctx.loading)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.source
                  }, null, 8, _hoisted_3))
                : _createCommentVNode("", true)
            ])), [
              [_directive_loading, _ctx.loading]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}