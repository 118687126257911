
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import draggable from "vuedraggable";
import CategoryName from "./com/category-name.vue";

@Component({
  components: {
    draggable,
    CategoryName
  }
})
export default class extends Vue {
  @Prop()
  modelValue: any;


  list = [
    { name: "样式1", value: 0 },
    { name: "样式2", value: 1 },
    { name: "样式3", value: 2 },
  ]

  async addCategory() {
    let data = await this.$chooseCategory();
    this.modelValue.list.push(
      ...data.map((item) => {
        return {
          count: 10,
          name: item.name,
          id: item.id,
          all: 0,
        };
      })
    );
  }
  async changeCategory(i) {
    let data = await this.$chooseCategory({
      multiple: false,
    });
    this.modelValue.list[i].id = data[0].id;
  }
  createItem() {
    return {};
  }
  removeItem(index) {
    this.modelValue.list.splice(index, 1);
  }
}


//   },
// };
