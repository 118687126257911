
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
interface Props {
  options: any
};

@Component({
})
export default class extends TSX<Props>()(Vue) implements Props {

  @Prop()
  options: any;

  @State("express", {
    namespace: "store"
  })
  expressList

  @Action("store/getExpress")
  getExpress

  loading = false
  orderInfo: any = {
    orderGoods: []
  }
  sendList = [
    { name: "快递发货", value: 1 },
    { name: "无需物流", value: 2 },
  ]

  ruleForm = {
    goodsId: [],
    type: 1,
    expressBill: "",
    expressId: null,
  }

  rules = {}

  get checkList() {
    let res: any = {};
    if (this.loading) return res;
    for (let i = 0; i < this.ruleForm.goodsId.length; i++) {
      let id = this.ruleForm.goodsId[i];
      res[id] = {
        index: i,
      };
    }
    return res;
  }

  get checkAll() {
    if (this.goodsInfo.waitSend == 0) return false;
    return this.ruleForm.goodsId.length == this.goodsInfo.waitSend;
  }
  get checkIndeter() {
    if (this.checkAll) return false;
    return this.ruleForm.goodsId.length > 0;
  }
  get goodsInfo() {
    let waitSend = 0;
    let send = 0;
    if (!this.orderInfo.orderGoods) {
      return { waitSend, send };
    }
    this.orderInfo.orderGoods.map((item) => {
      if (item.packId) {
        send++;
      } else {
        waitSend++;
      }
    });
    return { waitSend, send };
  }

  async created() {
    this.getExpress();
    this.loading = true;
    await this.getData();
    this.loading = false;
  }
  async getData() {
    let { error, data } = await this.$get("/manager/order/view", {
      id: this.options.id,
    });
    if (error) return;
    this.orderInfo = data;
  }

  checkAllGoods(bool) {
    let res = [];
    if (bool) {
      this.orderInfo.orderGoods.map((item) => {
        if (!item.packId) {
          res.push(item.id);
        }
      });
      this.ruleForm.goodsId = res;
      return;
    }
    this.ruleForm.goodsId = res;
  }
  changeGoods(id) {
    if (this.checkList[id]) {
      this.ruleForm.goodsId.splice(this.checkList[id].index, 1);
    } else {
      this.ruleForm.goodsId.push(id);
    }
  }
  async submitForm() {
    let { error, data, message } = await this.$post("/manager/order/send-gooods", {
      ...this.ruleForm,
      id: this.options.id,
    });
    if (error) return this.$error(message);
    this.$emit('resolve');
  }
}
