import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a4dc5da"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "floating-button-box"
}
const _hoisted_2 = {
  key: 1,
  class: "floating-list-box"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "floating-nav-panel-box" }
const _hoisted_5 = { class: "panel-icon-box" }
const _hoisted_6 = { class: "panel-text-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["floating-nav-box", _ctx.wrapClass])
  }, [
    (_ctx.showButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "floating-nav-item-box floating-nav-toggle-button1-box",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
          }, [
            _createVNode(_component_ui_icon, {
              modelValue: _ctx.modelValue.icon1
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", {
            class: "floating-nav-item-box floating-nav-toggle-button2-box",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = !_ctx.open))
          }, [
            _createVNode(_component_ui_icon, {
              modelValue: _ctx.modelValue.icon2
            }, null, 8, ["modelValue"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.rightList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rightList, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "floating-nav-item-box",
              key: i,
              style: _normalizeStyle(item.style)
            }, [
              _createVNode(_component_ui_icon, {
                modelValue: item.icon
              }, null, 8, ["modelValue"])
            ], 4))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.popupList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "floating-nav-outer-space-box",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.open = false))
          }),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.popupList, (item, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "floating-nav-panel-item-box",
                key: i
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_ui_icon, {
                    modelValue: item.icon
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_6, _toDisplayString(item.name), 1)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}