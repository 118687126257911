import Edit from './edit.vue'
import Show from './show.vue'
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {
          orderMode: 'oneline',
          imageGap: 0,
          imageRadius:0 ,
          pageGap: 0,
          list: []
        },
        render: "Image",
      }
    },
    name: "图片组件",
    icon: "iconpicture",
  }
}