import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.ruleForm,
    rules: _ctx.rules,
    ref: "formTable",
    "label-width": "100px",
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "分组名称",
        prop: "name"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ruleForm.name) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "排序",
        prop: "sort"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.sort,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ruleForm.sort) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitForm()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("创建分组")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('reject', { msg: '取消选择' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}