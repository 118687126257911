
import { LockDec, ValidateDec } from '@/decorators';
import { Component, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, State } from 'vuex-facing-decorator';

@Component({})
export default class extends Vue {
  get businessCategory() {
    if (this.categoryV1) return [this.categoryV1, this.formData.settlement_info.qualification_type]
    return null
  }

  categoryV1 = ''
  set businessCategory(v) {
    if (v) {
      this.categoryV1 = v[0]
      this.formData.settlement_info.settlement_id = `${this.businessHash[v[1]].settlement_rule_info.settlement_rule_id}`
      this.formData.settlement_info.qualification_type = v[1]
    }
  }


  get cityCategory() {
    if (!this.formData.bank_account_info.bank_address_code) return null;
    return [this.formData.bank_account_info.bank_address_code.replace(/[0-9][0-9]00$/, '0000'), this.formData.bank_account_info.bank_address_code]
  }

  set cityCategory(v) {
    this.formData.bank_account_info.bank_address_code = v[1]
  }

  get bankCityList() {
    return this.brachBankList.map((item) => {
      return {
        label: item.name,
        value: item.value,
        children: item.list.map((citem) => {
          return { label: citem.name, value: citem.value }
        })
      }
    })
  }

  get businessList() {
    return this.categoryList.map((item) => {
      return {
        label: item.big_industry_info.big_industry_name,
        value: item.big_industry_info.big_industry_id,
        children: item.industry_category_list.map((citem) => {
          return { label: citem.industry_category_info.category_name, value: citem.industry_category_info.category_name }
        })
      }
    })
  }

  get businessHash() {
    let res: Record<string, any> = {}
    this.categoryList.map((item) => {
      item.industry_category_list.map((citem) => {
        res[citem.industry_category_info.category_name] = citem
      })
    })
    return res;
  }

  async changeSubject(v) {
    if (v == this.formData.subject_info.subject_type) return;
    await this.$confirm("改变企业类型所有信息需从新填写", { title: '操作提示' });
    this.formData.subject_info.subject_type = v;
  }
  /**
   * 证件类型
   */
  contactIdDocType = [
    {
      name: '中国大陆居民-身份证',
      value: 'IDENTIFICATION_TYPE_IDCARD'
    },
  ]

  /*
   主体类型 
   */
  subjectType = [
    {
      name: '企业',
      value: 'SUBJECT_TYPE_ENTERPRISE'
    },
    // {
    //   name: '个体户',
    //   value: 'SUBJECT_TYPE_INDIVIDUAL'
    // }
  ]

  /**
   * 银行卡类型
   * */
  get bankAccountType() {
    return [
      {
        name: '对公银行账户',
        value: 'BANK_ACCOUNT_TYPE_CORPORATE'
      }
    ]
    return [
      {
        name: '对公银行账户',
        value: 'BANK_ACCOUNT_TYPE_CORPORATE'
      },
      {
        name: '经营者个人银行卡',
        value: 'BANK_ACCOUNT_TYPE_PERSONAL'
      }
    ]
  }

  get bankAccountName() {
    if (this.formData.bank_account_info.bank_account_type == 'BANK_ACCOUNT_TYPE_CORPORATE') {
      return this.formData.subject_info.business_license_info.merchant_name || '请输入企业名称'
    }
    return '--'
  }

  /**银行卡列表 */
  bankList = [
    {
      name: '工商银行',
      value: '工商银行'
    },
    {
      name: '交通银行',
      value: '交通银行'
    },
    {
      name: '招商银行',
      value: '招商银行'
    },
    {
      name: '民生银行',
      value: '民生银行'
    },
    {
      name: '中信银行',
      value: '中信银行'
    },
    {
      name: '浦发银行',
      value: '浦发银行'
    },
    {
      name: '兴业银行',
      value: '兴业银行'
    },
    {
      name: '光大银行',
      value: '光大银行'
    },
    {
      name: '广发银行',
      value: '广发银行'
    },
    {
      name: '平安银行',
      value: '平安银行'
    },
    {
      name: '北京银行',
      value: '北京银行'
    },
    {
      name: '华夏银行',
      value: '华夏银行'
    },
    {
      name: '农业银行',
      value: '农业银行'
    },
    {
      name: '建设银行',
      value: '建设银行'
    },
    {
      name: '邮政储蓄银行',
      value: '邮政储蓄银行'
    },
    {
      name: '中国银行',
      value: '中国银行'
    },
    {
      name: '宁波银行',
      value: '宁波银行'
    }
  ]

  uploadLoading = false;

  showData: Record<string, string> = {}

  get postData() {
    return this.formData
  }

  submitLoading = false;

  @LockDec("submitLoading")
  @ValidateDec("form")
  async submit() {
    await this.$postNotify("/manager/wechat/pay/submit-audit", this.postData);
  }

  @LockDec("uploadLoading")
  async uploadFiles(fieldKey: string) {
    let files = await this.$chooseFile();
    let fd = new FormData()
    fd.append('file', files[0])
    let { error, data, message } = await this.$upload("/manager/wechat/pay/upload-media", fd);
    if (error) {
      return this.$error(message);
    }
    let formdata = this.formData;
    let arr = fieldKey.split('.');
    arr.map((key, index) => {
      if (index == arr.length - 1) {
        formdata[key] = data.mediaId
      } else {
        formdata = formdata[key]
      }
    })
    this.showData[data.mediaId] = data.path;
  }

  @State((state) => state.store.store)
  store;



  @Action("store/getStore")
  getStore


  formData = {
    subject_info: {
      subject_type: "SUBJECT_TYPE_ENTERPRISE",
      business_license_info: {
        license_copy: "",
        license_number: "",
        merchant_name: "",
        legal_person: "",
        license_address: "",
        period_begin: "",
        period_end: ""
      },
      identity_info: {
        id_doc_type: "IDENTIFICATION_TYPE_IDCARD",
        id_card_info: {
          id_card_copy: "",
          id_card_national: "",
          id_card_name: "",
          id_card_number: "",
          id_card_address: "",
          card_period_begin: "",
          card_period_end: ""
        }
      },
      owner: true
    },
    business_info: {
      merchant_shortname: "",
      service_phone: "",
    },
    settlement_info: {
      settlement_id: "",
      qualification_type: ""
    },
    bank_account_info: {
      bank_account_type: "BANK_ACCOUNT_TYPE_CORPORATE",
      account_name: "",
      account_bank: "",
      bank_address_code: "",
      account_number: ""
    },
    contact_info: {
      contact_type: 'LEGAL',
      contact_name: "",
      mobile_phone: "",
      contact_email: ""
    },
  }

  categoryList = []
  brachBankList = []

  created() {
    this.getCategoryList()
    this.getBankList()
  }

  async getBankList() {
    let { data } = await this.$get('/manager/wechat/pay/bank-address-list')
    this.brachBankList = data.list
  }
  async getCategoryList() {
    let { error, data } = await this.$get('/manager/wechat/pay/category-list')
    if (error) return;
    this.categoryList = data.list;
  }
}
