import { dialog } from '@/plugins/dialog/index'
import InstanceBox from './index.vue';
export default function (data?) {
  data = { multiple: true, title: '请选择', type: 1, ...data };
  return dialog({
    title: data.title,
    props: {
      width: '940px',
    },
    content() {
      return <InstanceBox info={data}></InstanceBox>
    }
  })
}