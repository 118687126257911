
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import draggable from "vuedraggable";

@Component({
  components: {
    draggable
  }
})
export default class extends Vue {
  @Prop()
  modelValue: any;
  @Prop()
  dataType: any;
  @Prop({
    default() {
      return function (res) {
        return res;
      }
    }
  })
  urlPipe: Function;

  @Prop({
    default() {
      return 1;
    }
  })
  type: any;


  @Prop({
  })
  tips: any;

  @Prop({
    default() {
      return true;
    }
  })
  showClose: boolean;

  @Prop({
    default() {
      return 1;
    }
  })
  limit: number;

  get signInfo() {
    if (this.dataType == "array") {
      return { hasData: this.modelValue.length != 0, url: this.modelValue[0] };
    }
    return { hasData: !!this.modelValue, url: this.modelValue };
  }

  removeData() {
    if (this.dataType == "array") {
      return this.$emit("update:modelValue", []);
    }
    this.$emit("update:modelValue", "");
  }

  removeIndex(index) {
    this.modelValue.splice(index, 1);
  }
  chooseFile(data?) {
    if (this.type == 1) {
      return this.$chooseImage(data);
    }
    return this.$chooseVideo(data);
  }
  async chooseImage() {
    if (this.limit == 1) {
      let data = await this.chooseFile({
        multiple: false,
      });
      if (this.dataType == "array") {
        return this.$emit("update:modelValue", [data[0].url]);
      }
      return this.$emit("update:modelValue", data.url);
    }
    let data = await this.chooseFile();
    this.$emit("update:modelValue", [...this.modelValue, ...data.map((item) => item.url)]);
  }
}
