import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d942d37"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "align-right-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_dialog_title = _resolveComponent("ui-dialog-title")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ui_form = _resolveComponent("ui-form")!
  const _component_ui_dialog_container = _resolveComponent("ui-dialog-container")!

  return (_openBlock(), _createBlock(_component_ui_dialog_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_ui_dialog_title, {
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reject', {}))),
        title: _ctx.title
      }, null, 8, ["title"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ui_form, { "label-width": "100px" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            type: "textarea",
            rows: _ctx.rows,
            placeholder: _ctx.placeholder,
            modelValue: _ctx.contentValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contentValue) = $event)),
            maxlength: _ctx.maxlength,
            "show-word-limit": ""
          }, null, 8, ["rows", "placeholder", "modelValue", "maxlength"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.submitForm
            }, {
              default: _withCtx(() => [
                _createTextVNode("确定")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}