
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

import { ListViewVue } from '@/common/BaseVue'
@Component({
  components: {}
})
export default class extends ListViewVue {
  getList() {
    return this.$get("/manager/coupon/list", { ...this.mesureData });
  }

  toggle(row) {
    this.selected = row.id;
    this.selectedData = row
  }

  closeEvent() {
    this.$emit("reject", { msg: "未选择优惠券" });
  }
  choose() {
    if (this.selected == "") return;
    this.$emit("resolve", this.selectedData);
  }

  selectedData = null
  selected = ""
}
