import Decimal from 'decimal.js';

declare global {
  interface Number {
    toDecimal: () => Decimal
  }
}

Number.prototype.toDecimal = function () {
  return new Decimal(this);
}