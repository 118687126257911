
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop({ default: true })
  modelValue: any

  @Prop({ default: true })
  trueValue: any

  @Prop({ default: false })
  falseValue: any

  @Prop({ default: 'radio' })
  type: string;

  get isCheck() {
    return this.trueValue == this.modelValue;
  }
  toggleRadio() {
    if (this.type == "radio") {
      return this.$emit("update:modelValue", this.trueValue);
    }

    if (this.isCheck) {
      return this.$emit("update:modelValue", this.falseValue);
    }
    return this.$emit("update:modelValue", this.trueValue);
  }
}
