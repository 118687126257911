
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
@Component({
})
export default class extends ListViewVue {
  listType = "router";
  async deleteRow(id) {
    await this.$postNotify("/manager/role/delete", {
      id,
    });
    this.refresh();
  }
  gotoDetail(id?) {
    if (id) {
      return this.$goto(`/setting/role/operat?id=${id}`);
    }
    this.$goto("/setting/role/operat");
  }
  getList() {
    return this.$get("/manager/role/list", {
      ...this.mesureData,
      pageSize: 9999,
    });
  }
}
