
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {

  @Prop()
  modelValue: any;

  get text() {
    if (this.modelValue.content) return this.modelValue.content;
    return "点击编辑公告内容";
  }
  get style() {
    return this.modelValue.style
  }
}
