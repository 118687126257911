
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

import { ListViewVue } from '@/common/BaseVue'
import SelectCategory from './com/select-category/index.vue'
import SelectLevel from './com/select-level/index.vue'

@Component({
  components: {
    SelectCategory,
    SelectLevel
  },
})
export default class extends ListViewVue {
  listType = "router";

  tableChange(type) {
    let data = { ...this.condition };
    data.type = type;
    this.find(data);
  }

  operatGoods(id?) {
    if (id) {
      return this.$goto({
        path: "/goods/goods/edit",
        query: {
          id,
        },
      });
    }
    return this.$goto({
      path: "/goods/goods/create",
    });
  }
  async getList() {
    return this.$get("/manager/salesman/list", {
      ...this.mesureData,
    });
  }
  resetFilter() {
    this.find({ ...this.getCondition(), type: this.condition.type });
  }
  async deleteRow(id) {
    await this.$confirm("是否删除当前分销员？删除后自购金额和推广金额将会清零。", { title: '删除提醒', confirmButtonClass: 'red-button-box' })
    this.$postNotify("/manager/salesman/delete", { id });
    this.refresh();
  }
}

