import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShowGoods = _resolveComponent("ShowGoods")!
  const _component_ShowCategory = _resolveComponent("ShowCategory")!

  return (_ctx.modelValue.type == 'goods')
    ? (_openBlock(), _createBlock(_component_ShowGoods, {
        key: 0,
        modelValue: _ctx.modelValue
      }, null, 8, ["modelValue"]))
    : (_openBlock(), _createBlock(_component_ShowCategory, {
        key: 1,
        modelValue: _ctx.modelValue
      }, null, 8, ["modelValue"]))
}