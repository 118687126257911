
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
import CityItem from "./city-item.vue";
@Component({
  components: {
    CityItem
  }
})
export default class extends Vue {

  @Prop()
  item: any

  @Prop()
  selected: any

  @Prop()
  checked: any

  @Prop()
  disabled: any



  @State(state => state.address.hash) cityHash
  @State(state => state.address.province) province

  get code() {
    return this.item.code;
  }

  get list() {
    let obj = this.cityHash[this.code].children;
    return Object.keys(obj).map((k) => obj[k]);
  }
  get isDisabled() {
    if (this.disabled[this.code]) return true;
    if (this.disabled[0]) return true;
    return false;
  }
  get isChecked() {
    if (this.checked[this.code]) return true;
    if (this.checked[0]) return true;
    return false;
  }
  get indeterminate() {
    if (this.isChecked) return false;
    let list = Object.keys(this.cityHash[this.code].children);
    for (let i = 0; i < list.length; i++) {
      let code = list[i];
      if (this.checked[code]) return true;
      let cityList = Object.keys(this.cityHash[code].children);
      for (let k = 0; k < cityList.length; k++) {
        // 有县被选中也需要单选
        let ccode = cityList[k];
        if (this.checked[ccode]) return true;
      }
    }
    return false;
  }

  toggleCheckProvince(bool) {
    if (this.selected != this.code) {
      this.$emit("change", "");
    }
    this.$emit("checkChange", {
      code: this.code,
      checked: bool,
    });
  }
} 
