import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29659458"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-container" }
const _hoisted_2 = { class: "view-group-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_layout1 = _resolveComponent("ui-layout1")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_ui_layout2 = _resolveComponent("ui-layout2")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_color_picker = _resolveComponent("el-color-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_layout1, {
        title: "标题内容",
        class: "view-item-box"
      }, {
        content: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.modelValue.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.title) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ui_layout1, {
        title: "描述内容",
        class: "view-item-box"
      }, {
        content: _withCtx(() => [
          _createVNode(_component_el_input, {
            type: "textarea",
            modelValue: _ctx.modelValue.content,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.content) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_divider),
    _createElementVNode("div", null, [
      _createVNode(_component_ui_layout2, {
        title: "显示位置",
        ext: _ctx.positionExt
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            modelValue: _ctx.modelValue.style.textAlign,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.style.textAlign) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio_button, { label: "left" }, {
                default: _withCtx(() => [
                  _createTextVNode("左")
                ]),
                _: 1
              }),
              _createVNode(_component_el_radio_button, { label: "center" }, {
                default: _withCtx(() => [
                  _createTextVNode("中")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["ext"]),
      _createVNode(_component_ui_layout2, {
        title: "标题字号",
        ext: _ctx.foramtSize(_ctx.modelValue.titleStyle.fontSize)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            modelValue: _ctx.modelValue.titleStyle.fontSize,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.titleStyle.fontSize) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio_button, { label: "16px" }, {
                default: _withCtx(() => [
                  _createTextVNode("大")
                ]),
                _: 1
              }),
              _createVNode(_component_el_radio_button, { label: "14px" }, {
                default: _withCtx(() => [
                  _createTextVNode("中")
                ]),
                _: 1
              }),
              _createVNode(_component_el_radio_button, { label: "12px" }, {
                default: _withCtx(() => [
                  _createTextVNode("小")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["ext"]),
      _createVNode(_component_ui_layout2, {
        title: "描述字号",
        ext: _ctx.foramtSize(_ctx.modelValue.contentStyle.fontSize)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            modelValue: _ctx.modelValue.contentStyle.fontSize,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelValue.contentStyle.fontSize) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio_button, { label: "16px" }, {
                default: _withCtx(() => [
                  _createTextVNode("大")
                ]),
                _: 1
              }),
              _createVNode(_component_el_radio_button, { label: "14px" }, {
                default: _withCtx(() => [
                  _createTextVNode("中")
                ]),
                _: 1
              }),
              _createVNode(_component_el_radio_button, { label: "12px" }, {
                default: _withCtx(() => [
                  _createTextVNode("小")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["ext"]),
      _createVNode(_component_ui_layout2, {
        title: "标题粗细",
        ext: _ctx.foramtWeight(_ctx.modelValue.titleStyle.fontWeight)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            modelValue: _ctx.modelValue.titleStyle.fontWeight,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modelValue.titleStyle.fontWeight) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio_button, { label: "bold" }, {
                default: _withCtx(() => [
                  _createTextVNode("粗")
                ]),
                _: 1
              }),
              _createVNode(_component_el_radio_button, { label: "normal" }, {
                default: _withCtx(() => [
                  _createTextVNode("细")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["ext"]),
      _createVNode(_component_ui_layout2, {
        title: "描述粗细",
        ext: _ctx.foramtWeight(_ctx.modelValue.contentStyle.fontWeight)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            modelValue: _ctx.modelValue.contentStyle.fontWeight,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modelValue.contentStyle.fontWeight) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio_button, { label: "bold" }, {
                default: _withCtx(() => [
                  _createTextVNode("粗")
                ]),
                _: 1
              }),
              _createVNode(_component_el_radio_button, { label: "normal" }, {
                default: _withCtx(() => [
                  _createTextVNode("细")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["ext"]),
      _createVNode(_component_ui_layout2, {
        title: "标题颜色",
        ext: _ctx.modelValue.titleStyle.color
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "text",
            style: {"margin":"0 10px 0 0"},
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.modelValue.titleStyle.color = _ctx.defaultData.data.titleStyle.color))
          }, {
            default: _withCtx(() => [
              _createTextVNode("重置")
            ]),
            _: 1
          }),
          _createVNode(_component_el_color_picker, {
            modelValue: _ctx.modelValue.titleStyle.color,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modelValue.titleStyle.color) = $event)),
            onActiveChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.modelValue.titleStyle.color = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["ext"]),
      _createVNode(_component_ui_layout2, {
        title: "描述颜色",
        ext: _ctx.modelValue.contentStyle.color
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "text",
            style: {"margin":"0 10px 0 0"},
            onClick: _cache[10] || (_cache[10] = ($event: any) => (
          _ctx.modelValue.contentStyle.color = _ctx.defaultData.data.contentStyle.color
          ))
          }, {
            default: _withCtx(() => [
              _createTextVNode("重置")
            ]),
            _: 1
          }),
          _createVNode(_component_el_color_picker, {
            modelValue: _ctx.modelValue.contentStyle.color,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modelValue.contentStyle.color) = $event)),
            onActiveChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.modelValue.contentStyle.color = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["ext"]),
      _createVNode(_component_ui_layout2, {
        title: "背景颜色",
        ext: _ctx.modelValue.style.backgroundColor
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "text",
            style: {"margin":"0 10px 0 0"},
            onClick: _cache[13] || (_cache[13] = ($event: any) => (
          _ctx.modelValue.style.backgroundColor = _ctx.defaultData.data.style.backgroundColor
          ))
          }, {
            default: _withCtx(() => [
              _createTextVNode("重置")
            ]),
            _: 1
          }),
          _createVNode(_component_el_color_picker, {
            modelValue: _ctx.modelValue.style.backgroundColor,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.modelValue.style.backgroundColor) = $event)),
            onActiveChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.modelValue.style.backgroundColor = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["ext"])
    ])
  ]))
}