
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import defaultData from "./index";
import draggable from "vuedraggable";
@Component({
  components: {
    draggable,
  },
})
export default class extends Vue {

  @Prop()
  modelValue: any;

  get iconColor1() {
    if (this.modelValue.colorType == "0") {
      return { color: this.modelValue.color };
    }
    return { color: "var(--font-color)" };
  }
  get iconColor2() {
    if (this.modelValue.colorType == "0") {
      return { color: this.modelValue.color1 };
    }
    return { color: "var(--primary-color)" };
  }

  defaultData: any = {}

  removeIndex(index) {
    this.modelValue.list.splice(index, 1);
  }
  addMenu() {
    this.modelValue.list.push(defaultData.createData());
  }
  created() {
    this.defaultData = defaultData.data.data()
  }
}

