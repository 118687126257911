import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-840386a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sku-detail-box" }
const _hoisted_2 = { class: "operat-button-box" }
const _hoisted_3 = { style: {"margin":"0 10px 0 0"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    onBeforeEnter: _ctx.showEvent,
    "hide-after": 0,
    placement: "right",
    width: "400",
    ref: "popover",
    trigger: "click",
    "popper-class": "__choose-sku-box"
  }, {
    reference: _withCtx(() => [
      _createVNode(_component_el_link, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.chooseText), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_table, {
          "max-height": "400",
          data: _ctx.value,
          ref: "table",
          "span-method": _ctx.arraySpanMethod,
          border: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computSku, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_table_column, {
                key: index,
                label: item.value,
                prop: 'sk' + index
              }, {
                default: _withCtx(({ row, column }) => [
                  (_openBlock(), _createBlock(_component_el_checkbox, {
                    key: row[column.property] ? row[column.property].id : '',
                    onChange: ($event: any) => (_ctx.changeItem($event, row[column.property])),
                    indeterminate: _ctx.getIndeter(row[column.property], _ctx.chooseList),
                    modelValue: _ctx.getChecked(row[column.property], _ctx.chooseList)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(row[column.property] ? row[column.property].name : ""), 1)
                    ]),
                    _: 2
                  }, 1032, ["onChange", "indeterminate", "modelValue"]))
                ]),
                _: 2
              }, 1032, ["label", "prop"]))
            }), 128)),
            _createVNode(_component_el_table_column, {
              prop: "stock",
              label: "库存"
            }, {
              default: _withCtx(({ row }) => [
                _createTextVNode(_toDisplayString(row.stock), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data", "span-method"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_el_checkbox, {
              indeterminate: _ctx.isIndeter,
              "model-value": _ctx.isCheckAll,
              onChange: _ctx.toggleAll
            }, {
              default: _withCtx(() => [
                _createTextVNode(" 全选")
              ]),
              _: 1
            }, 8, ["indeterminate", "model-value", "onChange"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_3, "已选择规则:" + _toDisplayString(_ctx.chooseLength), 1),
            _createVNode(_component_el_button, {
              onClick: _ctx.confirmData,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode("确定")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_el_button, { onClick: _ctx.closeDialog }, {
              default: _withCtx(() => [
                _createTextVNode("取消")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onBeforeEnter"]))
}