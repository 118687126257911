
import InstanceBox from './index.vue';
import { dialog } from '@/plugins/dialog/index'

export default function (options) {
  options = Object.assign({}, options)
  return dialog({
    content() {
      return <InstanceBox options={options}></InstanceBox>
    }
  })
}