
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {

  @Prop()
  modelValue: any

  list = [
    { name: "待付款", icon: "iconweibiaoti2fuzhi04" },
    { name: "待发货", icon: "icondaifahuo" },
    { name: "待收货", icon: "icondaishouhuo" },
    { name: "售后", icon: "iconshouhou" },
  ]
};
