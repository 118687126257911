
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {},
})
export default class extends Vue {
  @Prop()
  modelValue: any;

  inputEvent(...arg) {
    this.$emit("update:modelValue", ...arg);
  }
}
