
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
import ImageItem from "./image-item.vue";
import VideoItem from "./video-item.vue";
@Component({
  components: {
    ImageItem,
    VideoItem
  }
})
export default class extends ListViewVue {

  @Prop()
  multiple: any;

  @Prop()
  modelValue: any;

  @Prop()
  categoryId: any;

  @Prop()
  type: any;

  get uploadInfo() {
    if (this.type == 1) {
      return { text: "上传图片", accept: ".jpg,.jpeg,.gif,.bmp,.png" };
    }
    return { text: "上传视频", accept: ".mp4" };
  }
  get selectHash() {
    let res = {};
    for (let i = 0; i < this.modelValue.length; i++) {
      let item = this.modelValue[i];
      res[item.id] = { index: i };
    }
    return res;
  }

  search = { keyword: "" }
  categoryList = []
  uploadLoading = false
  list = []

  async uploadFiles(e) {
    if (e.target.files.length == 0) return;
    this.uploadLoading = true;
    let list = await Promise.all(
      Array.prototype.map.call(e.target.files, (file) => {
        return this.uploadFile(file);
      })
    );
    this.uploadLoading = false;
    await this.refresh();
    let selectIds = {};
    list.map(([err, data]) => {
      if (err) return;
      selectIds[data.id] = true;
    });
    for (let i = this.source.list.length - 1; i >= 0; i--) {
      let item = this.source.list[i];
      if (selectIds[item.id]) {
        this.changeChoose(item);
      }
    }
  }
  hugupUploadFile() {
    let upload = this.$refs.uploadFile as any
    upload.value = "";
    upload.click();
  }

  async uploadFile(file) {
    let post = new FormData();
    post.append("file", file);
    post.append("documentCategoryId", this.categoryId);
    post.append("type", this.type);
    return this.$upload("/manager/document/upload", post);
  }
  changeChoose(item) {
    if (!this.multiple) {
      this.$emit("update:modelValue", [item]);
      return;
    }
    if (this.selectHash[item.id]) {
      return this.modelValue.splice(this.selectHash[item.id].index, 1);
    }
    this.modelValue.push(item);
  }

  page = {
    page: 1,
    pageSize: 20
  }

  getIndex(value, now) {
    for (let i = 0; i < value.length; i++) {
      let item = value[i];
      if (now.id == item.id) return i;
    }
    return -1;
  }

  getList() {
    let query: any = {
      documentCategoryId: this.categoryId,
      ...this.mesureData,
    };
    if (this.type) {
      query.type = this.type;
    }
    return this.$get("/manager/document/list", query);
  }
}
