
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;

  get mesureModelValue() {
    return this.modelValue
  }

  set mesureModelValue(v) {
    this.$emit("update:modelValue",v)
  }

  @Prop({
    default() {
      return []
    }
  })
  list: { name: string, value: any }[];

}
