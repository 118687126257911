import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "ruleForm",
    "label-width": "100px",
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "父类" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.parent ? _ctx.parent.name : "无"), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "名称" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ruleForm.name) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "权限key" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.limit,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ruleForm.limit) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.confirmData
          }, {
            default: _withCtx(() => [
              _createTextVNode("确定")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, { onClick: _ctx.closeDialog }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}