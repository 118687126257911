
const dataCache_ = {};
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;

  @Prop()
  mode: any;

  @Prop({
    default() {
      return 'common'
    }
  })
  type: string;


  @Watch('request')
  async requestWatch() {
    this.show = await (async () => {
      if (!this.modelValue || !this.modelValue.type) return;
      let res = this.getSelect(this.modelValue);
      return res;
    })();
  }


  list: any[] = []
  value_ = []
  key = 0
  show = null
  get request() {
    const { modelValue, list } = this;
    return { modelValue, list };
  }

  created() {
    this.getDropList();
  }

  async getDropList() {
    if (!dataCache_[this.type]) {
      dataCache_[this.type] = this.$get("/manager/link/list", {
        type: this.type,
        mode: this.mode,
      });
    }
    let { error, data } = await dataCache_[this.type];
    if (error) {
      delete dataCache_[this.type];
      return;
    }
    this.list = data;
  }


  async changeEvent(select) {
    this.key = Math.random();
    let [type, value] = select;
    let item = this.getSelect({ type, value });
    let err, event;
    switch (item.event) {
      case "choosePage":
        event = await this.$choosePage({
          multiple: false,
        });
        if (event) {
          event = event[0].id;
        }
        break;
      case "chooseGoods":
        event = await this.$chooseGoods({
          multiple: false,
        });
        if (event) {
          event = event[0].id;
        }
        break;
      case "chooseGoodsCategory":
        event = await this.$chooseCategory({
          multiple: false,
        });
        if (event) {
          event = event[0].id;
        }
        break;
      case "chooseCase":
        event = await this.$chooseCase({
          multiple: false,
        });
        if (event) {
          event = event[0].id;
        }
        break;
      case "chooseCaseCategory":
        event = await this.$chooseCaseCategory({
          multiple: false,
        });
        if (event) {
          event = event[0].id;
        }
        break;
      case "chooseLink":
        event = await this.$prompt('提示', {
          title: "请输入自定义链接",
          inputPlaceholder: "如：https://",
        });
        break;
      case "choosePath":
        event = await this.$prompt('提示', {
          title: "请输入自定义路径",
          inputPlaceholder: "如： /pages/index/index",
        });
        break;
      case "chooseMini":
        event = await this.$chooseMiniLink({
          title: "请输入小程序APPID",
          inputPlaceholder: "如： /pages/index/index",
        });
        break;
    }
    if (err) return;

    if (!event) {
      return this.$emit("update:modelValue", { type, value });
    }
    this.$emit("update:modelValue", { type, value, event });
  }

  getSelect(value) {
    for (let i = 0; i < this.list.length; i++) {
      let item = this.list[i];
      if (item.value == value.type) {
        if (!item.children) return item;
        for (let c = 0; c < item.children.length; c++) {
          let citem = item.children[c];
          if (citem.value == value.value) {
            return citem;
          }
        }
        return;
      }
    }
  }
  toggleSelect() {
    (this.$refs.cascader as any).togglePopperVisible();
  }
}


