
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any

  get style() {
    return (this.modelValue.wrapStyle);
  }
  get searchStyle() {
    return ({
      ...this.modelValue.style,
      height: this.modelValue.style.height + "px",
      borderRadius:
        this.modelValue.borderRadius == "normal"
          ? "3px"
          : this.modelValue.style.height + "px",
    });
  }
}

