
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;
  get iconColor1() {
    if (this.modelValue.colorType == "0") {
      return { color: this.modelValue.color };
    }
    return { color: "var(--font-color)" };
  }
}
