
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({

})
export default class extends Vue {
  @Prop()
  modelValue: any
}
