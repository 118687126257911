import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin":"5px 0"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_checkbox, {
      "model-value": _ctx.isChecked,
      disabled: _ctx.isDisabled,
      onChange: _ctx.toggleCheckedCounty
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.coitem.name), 1)
      ]),
      _: 1
    }, 8, ["model-value", "disabled", "onChange"])
  ]))
}