import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e16adbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_close_btn = _resolveComponent("ui-close-btn")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createBlock(_component_draggable, {
    tag: "ul",
    modelValue: _ctx.dragValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dragValue) = $event)),
    class: "image-sort-list-box"
  }, {
    item: _withCtx(({ index, element }) => [
      (_ctx.source[element])
        ? (_openBlock(), _createElementBlock("li", { key: index }, [
            _createElementVNode("img", {
              src: _ctx.source[element].cover
            }, null, 8, _hoisted_1),
            _createVNode(_component_ui_close_btn, {
              onClick: _withModifiers(($event: any) => (_ctx.removeItem(index)), ["stop"]),
              style: {"width":"16px","height":"16px","font-size":"12px"}
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}