
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {},

})
export default class extends Vue {
  @Prop()
  modelValue: any;

  @Action("address/getAddress")
  getAddress
  @State((state) => state.address.hash)
  cityHash

  get showValue() {
    if (this.modelValue.length == 0) {
      return "请选择包邮区域";
    }
    if (!this.cityHash) return ''
    return this.modelValue
      .map((code) => {
        if (!this.cityHash[code]) return ''
        return this.cityHash[code].name;
      })
      .join(",");
  }

  created() {
    this.getAddress()
  }
}
