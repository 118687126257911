import Edit from './edit.vue'
import Show from './show.vue'
import { shopHeadImage } from '@/config';
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {
          backgroundImage: shopHeadImage
        },
        render: "Shopinfo",
      }
    },
    name: "店铺信息",
    icon: "icondianpu",
  }
}