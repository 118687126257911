import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0c3b6bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "change-pwd" }
const _hoisted_2 = { class: "operat-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_dialog_title = _resolveComponent("ui-dialog-title")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_form_item = _resolveComponent("ui-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_ui_form = _resolveComponent("ui-form")!
  const _component_ui_dialog_container = _resolveComponent("ui-dialog-container")!

  return (_openBlock(), _createBlock(_component_ui_dialog_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_ui_dialog_title, {
        title: "修改密码",
        onClose: _ctx.close
      }, null, 8, ["onClose"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ui_form, {
          ref: "form",
          "label-width": "140px",
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ui_form_item, {
              label: "旧密码",
              data: _ctx.forms.oldpwd,
              required: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "password",
                  modelValue: _ctx.forms.oldpwd,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.forms.oldpwd) = $event)),
                  placeholder: "请输入旧密码"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["data"]),
            _createVNode(_component_ui_form_item, {
              label: "重置密码",
              required: true,
              rule: _ctx.validateRulePassword,
              data: _ctx.forms.pwd
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.forms.pwd,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.forms.pwd) = $event)),
                  type: "password",
                  placeholder: "请设置新密码，最少8位数"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["rule", "data"]),
            _createVNode(_component_ui_form_item, {
              label: "确认密码",
              required: true,
              rule: _ctx.validateNewConfirmPassword,
              data: _ctx.forms.pwd2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.forms.pwd2,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.forms.pwd2) = $event)),
                  type: "password",
                  placeholder: "请再次填写密码"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["rule", "data"]),
            _createVNode(_component_ui_form_item, { label: "" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_el_button, {
                    onClick: _ctx.submit,
                    type: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("修改密码 ")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_el_link, {
                    onClick: _ctx.close,
                    underline: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("取消")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512)
      ])
    ]),
    _: 1
  }))
}