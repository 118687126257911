
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
@Component({
})
export default class extends Vue {
  @Prop()
  coitem: any;

  @Prop()
  checked: any;

  @Prop()
  disabled: any;

  @State(state => state.address.hash) cityHash
  get code() {
    return this.coitem.code;
  }
  get isDisabled() {
    if (this.disabled[this.code]) return true; // 自己
    let cityCode = this.cityHash[this.code].parentCode;
    if (this.disabled[cityCode]) return true; // 市
    let provCode = this.cityHash[cityCode].parentCode;
    if (this.disabled[provCode]) return true; //省
    if (this.disabled[0]) return true; //全国
    return false;
  }

  get isChecked() {
    if (this.checked[this.code]) return true; // 自己
    let cityCode = this.cityHash[this.code].parentCode;
    if (this.checked[cityCode]) return true; // 市
    let provCode = this.cityHash[cityCode].parentCode;
    if (this.checked[provCode]) return true; //省
    if (this.checked[0]) return true; //全国
    return false;
  }
  toggleCheckedCounty(bool) {
    this.$emit("checkChange", { code: this.code, checked: bool });
  }
}

