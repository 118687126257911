import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a07a2ed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-select-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_select, _mergeProps(_ctx.$attrs, {
      modelValue: _ctx.modelValue,
      style: {"width":"100%"},
      "onUpdate:modelValue": _ctx.inputEvent
    }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 16, ["modelValue", "onUpdate:modelValue"])
  ]))
}