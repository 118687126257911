<template>
  <div class="ui-card-box">
    <div v-if="$slots.title">
      <slot name="title" />
    </div>
    <div v-if="$slots.default" class="ui-card-content-box">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss" >
.ui-card-box {
  background: #fff;
  border-radius: 5px;
  &+.ui-card-box{
    margin-top: 20px;
  }
  .ui-card-content-box {
    padding: 20px;
  }
}
</style>