
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {
  },
})
export default class extends Vue {

  @Prop()
  modelValue: any;
  open = true

  get list() {
    if (this.modelValue && this.modelValue.list) return this.modelValue.list;
    return [];
  }
  get popupList() {
    if (this.mesureData.list.length <= 3) return [];
    return this.mesureData.list;
  }
  get showButton() {
    return this.mesureData.list.length > 0;
  }
  get rightList() {
    let list = [...this.mesureData.fixedList, ...this.mesureData.list];
    let currentList = list;
    if (!this.open || this.mesureData.list.length > 3) {
      currentList = this.mesureData.fixedList;
    }
    return list.map((item, i) => {
      return {
        ...item,
        style:
          this.getItemStyle(Math.max(currentList.length - i, 0))

      };
    });
  }
  get wrapClass() {
    if (this.open) {
      return "floating-open-box";
    }
    return "";
  }
  get mesureData() {
    let fixedList = [];
    let list = [];
    this.list.map((item) => {
      if (item.fixed) {
        fixedList.push(item);
      } else {
        list.push(item);
      }
    });
    return {
      fixedList,
      list,
    };
  }

  getItemStyle(i) {
    if (i == 0) {
      return {};
    }
    if (!this.showButton) {
      i--;
    }
    return {
      transform: `translateY(-${58 * i}px)`,
      opacity: 1,
      visibility: "visible",
    };
  }
}

