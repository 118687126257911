import { App } from 'vue';
import { createApp } from 'vue-demi'
import DialogInstance from './index.vue';

let SingleInstance: { app: ReturnType<typeof createApp>, destory: Function, timeline?: number }
export function alert(value: string = ''): Promise<any[]> {
  if (SingleInstance) {
    SingleInstance.destory()
  }
  return new Promise(function (r) {
    const rootEl = document.createElement('div')
    document.body.appendChild(rootEl)
    let destory = () => {
      r([]);
      clearTimeout(SingleInstance.timeline)
      SingleInstance.app.unmount()
      SingleInstance = null;
      rootEl.remove()
    }
    const vueInstance = createApp(DialogInstance, {
      title: value,
      destory
    })

    SingleInstance = {
      app: vueInstance,
      destory: destory,
      timeline: setTimeout(() => {
        SingleInstance.destory()
      }, 3000)
    };
    vueInstance.mount(rootEl);
  })
}
function install(app: App) {
  app.config.globalProperties.$alert = alert;
  return alert
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $alert: typeof alert
  }
}

export default {
  install: install
}