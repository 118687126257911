import { App } from "vue";
import com from '@/com/index'
import api from './api'
import utils from './utils'
import store from '@/store'
import dialog from './dialog/index'
import drawer from './drawer/index'
import alert from './alert/index'
import router from '@/router'
import ElementPlus from 'element-plus'
import date from './date'

import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import vClickOutside from "click-outside-vue3"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

export async function plugins(app: App) {
  await com.install(app)
  app.use(vClickOutside)
  app.use(ElementPlus, {
    locale: zhCn,
  });
  app.use(date)
  app.use(store)
  app.use(api)
  app.use(utils)
  app.use(dialog)
  app.use(drawer)
  app.use(alert)
  app.use(router)
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  return app;
}