import { App } from "vue";
let comList;

export default {
  async install(app: App) {
    if (!comList) {
      const requireComponent = require.context(
        './ui/',
        true,
        /^\.\/(.*)\/index\.vue$/
      );
      let regexp = /^\.\/(.*)\/index\.vue$/
      comList = Promise.all([...requireComponent.keys().map(async (fileName) => {
        let matched = fileName.match(regexp);
        if (matched && matched[1].indexOf('/') == -1) {
          const componentConfig = await requireComponent(fileName);
          return [`ui-${matched[1]}`, componentConfig.default || componentConfig]
        }
      })]);
    }
    let resCom = await comList;
    resCom.map(function (item) {
      if (item) {
        let [name, value] = item
        app.component(name, value)
      }
    })
  }
}
