import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bcd17cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "goods-name-box" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_ui_page = _resolveComponent("ui-page")!

  return (_ctx.modelValue && _ctx.modelValue.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_link, {
          type: "primary",
          onClick: _ctx.chooseData
        }, {
          default: _withCtx(() => [
            _createTextVNode("选择商品")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_table, {
          data: _ctx.source.list,
          style: {"width":"500px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, { prop: "name" }, {
              header: _withCtx(() => [
                _createTextVNode("商品")
              ]),
              default: _withCtx(({ row }) => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("img", {
                    src: row.cover
                  }, null, 8, _hoisted_3),
                  _createTextVNode(" " + _toDisplayString(row.name), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "remake",
              label: "操作",
              width: "180",
              align: "right"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_el_link, {
                  type: "danger",
                  onClick: ($event: any) => (_ctx.remove(row.id)),
                  style: {"margin":"0 0 0 10px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("删除")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"]),
        _createVNode(_component_ui_page, {
          style: {"margin":"10px 0"},
          modelValue: _ctx.page.page,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page.page) = $event)),
          info: _ctx.pageInfo,
          layout: "prev, pager, next"
        }, null, 8, ["modelValue", "info"])
      ]))
    : (_openBlock(), _createBlock(_component_el_link, {
        key: 1,
        type: "primary",
        onClick: _ctx.chooseData
      }, {
        default: _withCtx(() => [
          _createTextVNode("选择商品")
        ]),
        _: 1
      }, 8, ["onClick"]))
}