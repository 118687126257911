
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import RuleItem from "./com/rule-item.vue";
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
import { LockDec } from '@/decorators';

function getDefaultRule() {
  return {
    value: '0',
    type: 0,
    area: [],
  };
}

@Component({
  components: {
    RuleItem,
  },

})
export default class extends Vue {

  @Watch('formData.goodsType')
  goodsTypeChange() {
    if (this.loading) return;
    this.formData.goodsValue = [];
  }

  @Action("address/getAddress")
  getAddress

  submitLoading = false
  loading = false
  formData = {
    name: "",
    timeType: 1,
    expressType: 0,
    freeType: 0,
    goodsType: 0,
    goodsValue: [],
    timeRange: [],
    ruleList: [getDefaultRule()],
  }

  async created() {
    this.getAddress()
    this.loading = true;
    await this.getData();
    this.loading = false;
  }
  createRule() {
    this.formData.ruleList.push(getDefaultRule());
  }

  async getData() {
    let id = this.$route.query.id;
    if (!id) return;
    let { error, data } = await this.$get("/manager/postage/detail", {
      id,
    });
    if (error) return;
    if (data.ruleList) {
      data.ruleList = JSON.parse(data.ruleList);
    }
    if (data.timeType != 0) {
      data.timeRange = [];
    } else {
      data.timeRange = [data.startTime, data.endTime];
    }
    data.goodsValue = data.goodsValue ? data.goodsValue.split(",") : [];
    this.formData = data;
  }

  @LockDec("submitLoading")
  async submit() {
    await this.$postNotify("/manager/postage/operat", {
      ...this.formData,
      startTime: this.formData.timeRange
        ? this.formData.timeRange[0]
        : null,
      endTime: this.formData.timeRange ? this.formData.timeRange[1] : null,
    });
    this.$router.back();
  }
}
