
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { rootForm } from './constant'
@Component({
  provide() {
    return { [rootForm]: this }
  }
})
export default class extends Vue {
  @Prop()
  labelWidth: any;
  @Prop({
    default() {
      return 'left'
    }
  })
  labelPosition: string;

  get formStyle() {
    return { '--form-label-width': this.labelWidth }
  }

  get wrapClass() {
    let res = {}
    switch (this.labelPosition) {
      case 'top':
        res['ui-form-position-' + this.labelPosition + '-box'] = true
        break;
    }

    return res
  }
  listItem = []

  push(item) {
    this.listItem.push(item);
  }

  async validate() {
    let list = [];
    await Promise.all(this.listItem.map(async (item) => {
      try {
        await item.validate()
      } catch (e) {
        list.push(e)
      }
    }));
    if (list.length > 0) {
      throw list
    }
  }

  unpush(item) {
    let index = this.listItem.indexOf(item);
    if (index >= 0) {
      this.listItem.splice(index, 1)
    }
  }
}
