
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
interface Props {
  id?: any
};

@Component({})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  id: any
  activeName = "1"
  loading = true
  source = ''
  created() {
    this.getData();
  }
  async getData() {
    let { error, data } = await this.$getDownload(
      "/manager/super/store/view",
      {
        id: this.id
      },
      {
        auto: false,
        method: 'GET'
      }
    );
    if (error) return
    this.source = data;
    this.loading = false;
  }
}
