
import { ValidateDec } from '@/decorators'
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  forms = {
    oldpwd: "",
    pwd: "",
    pwd2: "",
  }

  validateRulePassword(v) {
    if (!v) throw '请输入密码'
  }
  validateNewConfirmPassword(v) {
    if (!v) throw '请输入密码'
    if (this.forms.pwd != this.forms.pwd2) throw '两次密码不一致'
  }

  @ValidateDec("form")
  async submit() {
    const { oldpwd, pwd, pwd2 } = this.forms;
    await this.$postError('/manager/common/modify-password', {
      oldPwd: oldpwd,
      pwd: pwd,
    });
    this.$emit("resolve", {})
  }
  close() {
    this.$emit("reject", { msg: '取消' })
  }
}
