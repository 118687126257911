
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
@Component({})
export default class extends ListViewVue {
  listType = "router";

  getList() {
    return this.$get("/manager/goods/category-list", {
      ...this.mesureData,
    });
  }

  async deleteRow(id) {
    await this.$postError("/manager/goods/delete-category", {
      id,
    });
    this.refresh();
  }

  gotoCategory(id?) {
    if (id) {
      return this.$goto({
        path: "/goods/category/edit",
        query: {
          id,
        },
      });
    }
    this.$goto({
      path: "/goods/category/create",
    });
  }
}
