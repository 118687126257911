import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-760a9a64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout02-box" }
const _hoisted_2 = { class: "_title-text-box" }
const _hoisted_3 = { class: "_ext-text-box" }
const _hoisted_4 = { class: "_content-text-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.ext), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}