import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1d7e444"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-table-footer-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-custome-table", { 'customer-border-box': _ctx.border }])
  }, [
    _createVNode(_component_el_table, _mergeProps(_ctx.$attrs, _toHandlers(_ctx.$listeners), { border: _ctx.border }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 16, ["border"]),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ])
  ], 2))
}