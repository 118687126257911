import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import NotFound from '@/entrys/404/index.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import("@/entrys/login/index.vue")
  },
  {
    path: '/register',
    name: 'register',
    component: () => import("@/entrys/register/index.vue")
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import("@/entrys/forgot/index.vue")
  },
  {
    path: "/home",
    component: () => import("@/entrys/home/index.vue"),
    meta: {
      auth: true
    },
  },
  {
    path: "/manager",
    component: () => import("@/entrys/manager/index.vue"),
    meta: {
      auth: true
    },
  }
]

const router = createRouter({
  history: process.env.VUE_APP_NODE_ENV == 'production' ? createWebHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
  routes
})

let ignores = ['/login', '/register', '/forgot']

let isFirst = true;

let loopEvent = (list) => {
  return list.map((item) => {
    if (item.children) {
      let redirect;
      if (item.redirect) {
        redirect = { name: item.children[0].aliasName }
      }
      return {
        redirect,
        meta: { ...item.meta, isMenu: item.show },
        name: item.aliasName,
        component: () => import(`@/views/${item.filePath}`),
        path: item.path,
        children: loopEvent(item.children)
      }
    }
    return {
      meta: { ...item.meta, isMenu: item.show },
      name: item.aliasName,
      component: () => import(`@/views/${item.filePath}`),
      path: item.path,
    }
  })
};

router.beforeEach(async (to, from, next) => {
  let store = (await import('@/store')).default;
  const token = store?.state['user']?.token;
  if (ignores.includes(to.path)) {
    return next()
  }
  if (!token) {
    return next({
      path: '/login'
    });
  }

  if (to.matched.length === 0 && isFirst) {
    let { error, data } = await store.dispatch('store/getLastMenu');
    if (error) {
      return next("/home")
    }
    isFirst = false;
    let list = loopEvent(data.list);
    router.addRoute({
      name: "首页",
      path: "/",
      component: () => import("@/entrys/index/index.vue"),
      redirect: { name: list[0].name },
      children: list,
      meta: {
        auth: true
      },
    });

    router.addRoute({
      path: "/:pathMatch(.*)",
      component: NotFound
    });
    return next(to);
  }
  return next()
});
export default router;