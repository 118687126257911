
import { Component, Vue } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
@Component({
  components: {}
})
export default class extends ListViewVue {
  activeName = ""
  wechat = ""
  addLoading = false
  getList() {
    return this.$get("/manager/open-platform/tester-list", { ...this.mesureData });
  }
  async unbind(params) {
    let { error, data, message } = await this.$post(
      "/manager/open-platform/unbind-tester",
      {
        wechat: params.wechat,
      }
    );
    if (error) return this.$error(message);
    this.refresh();
  }

  async addMember() {
    this.addLoading = true;
    let { error, data, message } = await this.$post("/manager/open-platform/bind-tester", {
      wechat: this.wechat,
    });
    this.addLoading = false;
    if (error) return this.$error(message);
    this.wechat = "";
    this.refresh();
  }
}
