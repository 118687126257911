
import { LockDec, ValidateDec } from "@/decorators";
import ActiveityItem from "../com/activeity-item.vue";
import VipLevelInfo from './dialog/vip-info.vue'
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { State } from 'vuex-facing-decorator';

@Component({
  components: { ActiveityItem }
})
export default class extends Vue {
  @Prop()
  data: any;

  @State((state) => state.store.store)
  store;


  loading = false
  submitLoading = false
  list = []

  created() {
    this.getData();
  }

  getName(item) {
    if (item.name) {
      return item.name
    }
    switch (item.month) {
      case 1:
        return '1个月';
      case 3:
        return '3个月';
      case 12:
        return '12个月';
      case 36:
        return '3年';
      case 60:
        return '5年';
    }
  }
  async editRow(row) {
    row.priceList = await this.$dialog({
      title: '付费规则',
      props: {
        width: '400px'
      },
      content: () => {
        return <VipLevelInfo data={row.priceList}></VipLevelInfo>
      }
    });
  }
  backEvent() {
    this.$router.back()
  }
  removeItem(index) {
    this.list.splice(index, 1)
  }
  arraySpanMethod({ row, column, rowIndex, columnIndex }) {
    if (column.property == 'operat') {
      return {
        rowspan: this.list.length,
        colspan: 1
      };
    }
  }

  addVip() {
    this.list.push({
      rate: 1,
      userVipActivityId: '',
      freeDelivery: 0,
      discount: '',
      name: '',
      score: ''
    })
  }
  async getData() {
    this.loading = true;
    let { error, data } = await this.$get("/manager/level/paid-list", {});
    this.loading = false;
    if (error) return;
    this.list = data.list
  }
  @LockDec("submitLoading")
  @ValidateDec("form")
  async submit() {
    await this.$postNotify("/manager/level/save-paid",
      {
        list: this.list
      })
    this.getData()
  }
}
