
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';

@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;

  @State((state) => state.store.store)
  store;
};
