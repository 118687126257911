import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-623da616"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "edit-title-box" }
const _hoisted_2 = { class: "edit-title-header" }
const _hoisted_3 = { class: "edit-title-head-left" }
const _hoisted_4 = {
  key: 0,
  class: "edit-title-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.content)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.content), 1))
      : _createCommentVNode("", true)
  ]))
}