
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
})
export default class extends Vue {
  @Prop()
  formData: any;

  created() {
    this.formData.template = this.formData.template || [];
  }

  operatPage(data) {
    this.formData.template = data.list;
    this.$emit('submit')
  }
  saveButton() {
    (this.$refs.custom as any).saveButton();
  }
}
