
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;
  get count() {
    return 4;
  }
  get title() {
    return "这里是案例标题";
  }
}
