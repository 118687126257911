import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, _mergeProps(_ctx.$attrs, _toHandlers(_ctx.$listeners), {
    "model-value": _ctx.modelValue_,
    "onUpdate:modelValue": _ctx.inputValue
  }), {
    append: _withCtx(() => [
      _renderSlot(_ctx.$slots, "append")
    ]),
    _: 3
  }, 16, ["model-value", "onUpdate:modelValue"]))
}