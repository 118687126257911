import "@/polyfill"
import { createApp } from 'vue'
import App from './App.vue'
import { plugins } from './plugins/index'
import '@/assets/scss/global.scss'

async function init() {
  let app = createApp(App)
  await plugins(app);
  app.mount('#app');
}
init();