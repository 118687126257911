
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'


@Component({
  components: {},
})
export default class extends Vue {
  @Prop({
    default() {
      return false;
    }
  })
  disabled: boolean

  showChild = false;
  visible = false;

  showEvent() {
    this.visible = true;
  }
  hideEvent(e) {
    if (!this.visible) return;
    if (e && e.target) {
      let checkPrevents = (target) => {
        if (target.dataset.prevent) return true;
        if (target.parentElement) {
          return checkPrevents(target.parentElement);
        }
        return target?.nodeName != "HTML";
      };
      if (checkPrevents(e.target)) return;
    }
    this.visible = false;
  }
}
