import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ad0cb750"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-container" }
const _hoisted_2 = { class: "view-container-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_tabbar = _resolveComponent("ui-tabbar")!
  const _component_EditCategory = _resolveComponent("EditCategory")!
  const _component_EditGoods = _resolveComponent("EditGoods")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_tabbar, {
      modelValue: _ctx.modelValue.type,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.type) = $event)),
      list: _ctx.list
    }, null, 8, ["modelValue", "list"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.modelValue.type == 'category')
        ? (_openBlock(), _createBlock(_component_EditCategory, {
            key: 0,
            modelValue: _ctx.modelValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue) = $event))
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createBlock(_component_EditGoods, {
            key: 1,
            modelValue: _ctx.modelValue,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue) = $event))
          }, null, 8, ["modelValue"]))
    ])
  ]))
}