import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tabbar2-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_radio_group, {
      modelValue: _ctx.mesureModelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mesureModelValue) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
          return (_openBlock(), _createBlock(_component_el_radio, {
            key: i,
            label: item.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.name), 1)
            ]),
            _: 2
          }, 1032, ["label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}