
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {

  @Prop()
  modelValue: any;

  @Prop({
    default() { return true }
  })
  multiple: boolean;

  source = { list: [] };
  created() {
    this.getList()
  }
  get initValue() {
    if (!this.modelValue || this.modelValue === '') return undefined
    if (this.multiple) {
      if (this.modelValue instanceof Array) {
        return this.modelValue.map((item) => {
          return Number(item)
        })
      };
      return [Number(this.modelValue)];
    }
    return Number(this.modelValue);
  }

  set initValue(v) {
    this.$emit("update:modelValue", v || null);
  }

  async getList() {
    let { error, data } = await this.$get("/manager/salesman/get-category", {
    });
    if (error) return;
    this.source = data
  }
}
