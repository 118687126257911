
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;
  @Prop()
  item: any;

  @Watch('item.count')
  @Watch('item.count')
  countAllWatch() {
    this.getList();
  }

  get isLeft() {
    return this.modelValue.showType == "left";
  }
  get listClass() {
    let listMode = this.modelValue.listMode;
    if (this.modelValue.showType == "left") {
      listMode = "left";
    }
    return `list-mode-${listMode}`;
  }
  get imageStyle() {
    return {
      borderRadius: `${this.modelValue.imageRadius}`,
    };
  }
  get goodsGapStyle() {
    return {
      width: `calc(50% - ${this.modelValue.imageGap / 2}px)`,
      marginBottom: `${this.modelValue.imageGap}px`,
    };
  }
  get list() {
    if (this.modelValue.length) {
      return this.goods;
    }
    return Array.apply(null, { length: this.count }).map(() => {
      return this.getDefault();
    });
  }
  get count() {
    if (this.modelValue.showType == "left") {
      return 4;
    }
    switch (this.modelValue.listMode) {
      case "minix":
        return 5;
      case "big":
      case "list":
        return 3;
    }
    return 4;
  }

  created() {
    this.getList();
  }

  goods = [];

  async getList() {
    if (!this.item.id) {
      this.goods = [];
      return;
    }
    let { error, data } = await this.$get("/manager/goods/list", {
      categoryId: [this.item.id],
      pageSize: this.item.all == 1 ? -1 : this.item.count,
    });
    if (error) {
      this.goods = [];
      return;
    }
    this.goods = data.list;
  }
  getDefault() {
    if (this.modelValue.listMode == "big") {
      return {
        name: "这里显示商品名字，最多显示一行",
        cover: require("./images/default.png"),
        showPrice: 99,
      };
    }
    return {
      name: "这里显示商品名字，最多显示二行",
      cover: require("./images/default.png"),
      showPrice: 99,
    };
  }
}
