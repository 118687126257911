
import { LockDec, ValidateDec } from '@/decorators';
import { Component, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, State } from 'vuex-facing-decorator';

@Component({})
export default class extends Vue {
  uploadLoading = false;

  showData: Record<string, string> = {}
  @Watch("formData.auth_data.account_name")
  accountChange() {
    this.nameResult = null;
  }

  customerList = [{ name: '企业', value: 1 }, { name: '个体户', value: 12 }]

  invoiceList = [{ name: '不开发票', value: 1 }, { name: '电子发票', value: 2 }]

  get postData() {
    return this.formData
  }

  submitLoading = false;

  nameResult = null;

  async checkName() {
    let { error, data, message } = await this.$post(
      "/manager/open-platform/check-wechat-name",
      { name: this.formData.auth_data.account_name }
    );
    if (error) {
      this.nameResult = { type: 'error', message }
      return;
    }
    this.nameResult = { type: 'success', data }
  }

  @LockDec("submitLoading")
  @ValidateDec("form")
  async submit() {
    await this.$postNotify("/manager/wechat/auth/submit-audit", this.postData);
  }

  @LockDec("uploadLoading")
  async uploadFiles(fieldKey: string) {
    let files = await this.$chooseFile();
    let fd = new FormData()
    fd.append('file', files[0])
    let { error, data, message } = await this.$upload("/manager/wechat/auth/upload-media", fd);
    if (error) {
      return this.$error(message);
    }
    let formdata = this.formData;
    let arr = fieldKey.split('.');
    arr.map((key, index) => {
      if (index == arr.length - 1) {
        formdata[key] = data.mediaId
        console.log(data)
      } else {
        formdata = formdata[key]
      }
    })
    this.showData[data.mediaId] = data.path;
  }

  @State((state) => state.store.store)
  store;


  formData = {
    auth_data: {
      customer_type: 1,
      contact_info: {
        name: '',
        email: ''
      },
      invoice_info: {
        invoice_type: 2,
        electronic: {
          id: '',
          desc: ''
        }
      },
      qualification: '',
      account_name: '',
      account_name_type: 1
    },

  }

  created() {
  }

}
