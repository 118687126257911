import Edit from './edit.vue'
import Show from './show.vue'
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {
          type: 'goods',
          goodsType: 'goods',
          goodsIds: [],
          category: null,
          listMode: 'small',
          showBuyBtn: 1,
          showPrice: 1,
          showTitle: 1,
          btnStyle: 0
        },
        render: "Goods",
      }
    },
    data2() {
      return {
        data: {
          list: [],
          type: 'category',
          showType: 'top',
          showAll: 0,
          listMode: 'small',
          showBuyBtn: 1,
          showPrice: 1,
          showTitle: 1,
          btnStyle: 0,
          imageGap: 15,
          imageRadius:0 ,
          pageGap: 15,
        },
        render: "Goods",
      }
    },
    name: "商品",
    icon: "iconshangpin",
  }
}