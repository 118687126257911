
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;

  get showImage() {
    if (
      this.modelValue.showStyle == "image-text" ||
      this.modelValue.showStyle == "image"
    )
      return true;
    return false;
  }
  get showText() {
    if (
      this.modelValue.showStyle == "image-text" ||
      this.modelValue.showStyle == "text"
    )
      return true;
    return false;
  }
  get list() {
    if (this.modelValue.list.length == 0) {
      return Array.from({
        length: 4,
      }).map((item, index) => {
        return this.getDefaultData(`导航${index + 1}`);
      });
    }
    return this.modelValue.list;
  }
  get imageBoxStyle() {
    if (this.naturalHeight != 0) {
      let height = this.naturalHeight / 2;
      height = Math.max(height, 30);
      // height = Math.min(height, 400);
      return { height: height + "px" };
    }
  }
  get imageLength() {
    if (this.modelValue.mode == "scroll") return this.modelValue.count;
    return this.list.length;
  }
  get itemWidth() {
    return this.$refs.imageBox[0].offsetWidth
  }
  get itemStyle() {
    return {
      // width: this.itemWidth + "px",
    };
  }
  get wrapStyle() {
    return { ...this.modelValue.style, paddingLeft: `${this.modelValue.gap}px`, paddingRight: `${this.modelValue.gap}px`, gap: `${this.modelValue.imgGap}px` };
  }

  naturalHeight = 0
  naturalWidth = 0
  defaultCover = "https://file.cnzhizhi.com/common/images/image-text-default.png"


  getDefaultData(title) {
    return {
      cover: this.defaultCover,
      title,
    };
  }
  loadImage(e, index) {
    if (index == 0) {
      this.naturalHeight = e.target.naturalHeight;
      this.naturalWidth = e.target.naturalWidth;
    }
  }
}



