
import { dialog } from '@/plugins/dialog/index'
import InstanceBox from './index.vue';
export default function (options?) {
  options = Object.assign({
  }, options);
  return dialog({
    title: '其他小程序',
    props: {
      width: '500px'
    },
    content() {
      return <InstanceBox ></InstanceBox>
    }
  })
}