import Edit from './edit.vue'
import Show from './show.vue'

function createItem(data = {}) {
  return { cover: '', title: "", link: {}, ...data }
}
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {
          list: Array.from({
            length: 4,
          }).map((item, index) => {
            return createItem({ title: `导航${index + 1}` });
          }),
          mode: 'fixed',
          count: 4,
          showStyle: 'image-text',
          gap: 0,
          imgGap: 0,
          style: {
            backgroundColor: '#fff',
            color: '#000'
          }
        },
        render: "ImageText",
      }
    },
    createItem,
    name: "图文导航",
    icon: "iconpicture",
  }
}