
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
import CountyItem from "./county-item.vue";
@Component({
  components: {
    CountyItem
  }
})
export default class extends Vue {
  @Prop()
  citem: any;

  @Prop()
  checked: any;

  @Prop()
  disabled: any;

  @State(state => state.address.hash) cityHash

  get code() {
    return this.citem.code;
  }

  show = false
  get isDisabled() {
    if (this.disabled[this.code]) return true;
    if (this.disabled[this.cityHash[this.code].parentCode]) return true;
    if (this.disabled[0]) return true;
    return false;
  }
  get isChecked() {
    if (this.checked[this.code]) return true;
    if (this.checked[this.cityHash[this.code].parentCode]) return true;
    if (this.checked[0]) return true;
    return false;
  }
  get indeterminate() {
    if (this.isChecked) return false;
    let list = Object.keys(this.cityHash[this.code].children);
    for (let i = 0; i < list.length; i++) {
      let code = list[i];
      if (this.checked[code]) return true;
    }
    return false;
  }

  toggleCheckedCity(bool) {
    this.$emit("checkChange", {
      code: this.citem.code,
      checked: bool,
    });
  }
}
