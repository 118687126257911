
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {

  @Prop({
    default() {
      return 'normal'
    }
  })
  size: string

  @Prop({
    default() {
      return 'primary'
    }
  })
  type: string

}
