
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {

  postData: any = null

  submitLoading = false;
  loading = false;

  async getData() {
    if (!this.$route.query.id) return;
    let { data } = await this.$getError('/manager/salesman/view', { id: this.$route.query.id })
    this.postData = data;
  }

  async created() {
    this.loading = true
    await this.getData()
    this.loading = false
  }

}
