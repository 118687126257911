
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {

  @Prop()
  modelValue: any;
  list = [
    { name: "样式1", value: 0 },
    { name: "样式2", value: 1 },
    { name: "样式3", value: 2 },
  ]
}
