
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({

})
export default class extends Vue {

  @Prop()
  modelValue: any;

  @Watch('modelValue.category', {
    deep: true
  })
  @Watch('modelValue.goodsType')
  @Watch('modelValue.goodsIds')
  refreshWatch() {
    this.goods = [];
    this.getList();
  }


  goods = []

  get list() {
    if (this.modelValue.goodsType == "goods") {
      if (this.goods && this.goods.length) {
        return this.goods;
      }
    } else if (this.modelValue.goodsType == "category") {
      if (this.goods && this.goods.length) {
        return this.goods;
      }
    }
    return Array.apply(null, { length: this.count }).map((item, index) => {
      return this.getDefault(index);
    });
  }
  get count() {
    switch (this.modelValue.listMode) {
      case "minix":
        return 5;
      case "big":
      case "list":
        return 3;
    }
    return 4;
  }

  created() {
    this.getList();
  }

  async getList() {
    if (this.modelValue.goodsType == "goods") {
      this.getGoodsList();
    } else {
      this.getGoodsCategory();
    }
  }

  async getGoodsList() {
    if (this.modelValue.goodsIds && this.modelValue.goodsIds.length) {
      let { error, data } = await this.$get("/manager/goods/list", {
        ids: this.modelValue.goodsIds,
        pageSize: -1,
      });
      if (error) return;
      this.goods = data.list;
      return;
    }
    this.goods = [];
  }
  async getGoodsCategory() {
    if (this.modelValue.category) {
      let { error, data } = await this.$get("/manager/goods/list", {
        pageSize: this.modelValue.category.count,
        categoryId: [this.modelValue.category.id],
      });
      if (error) return;
      this.goods = data.list;
      return;
    }
    this.goods = [];
  }
  getDefault(index) {
    switch (this.modelValue.listMode) {
      case "big":
        return {
          name: "这里显示商品名字，最多显示一行",
          cover: require("./images/default.png"),
          showPrice: 99,
        };
      case "minix":
        if (index == 0) {
          return {
            name: "这里显示商品名字，最多显示一行",
            cover: require("./images/default.png"),
            showPrice: 99,
          };
        }
    }
    return {
      name: "这里显示商品名字，最多显示二行",
      cover: require("./images/default.png"),
      showPrice: 99,
    };
  }
}
