
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue';

@Component({
  components: {}
})
export default class extends ListViewVue {
  selected = ""
  getList() {
    return this.$get("/manager/activity/list", { ...this.mesureData });
  }
  toggle(row) {
    this.selected = row.id;
  }
  closeEvent() {
    this.$emit('reject', { msg: "未选择商品" });
  }
  choose() {
    if (this.selected == "") return;
    this.$emit('resolve', this.selected);
  }
}
