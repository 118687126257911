import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1993e4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switch-after-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-switch-box", {
    'swicht-check': _ctx.isChecked,
    'switch-disabled': _ctx.disabled,
    ['ui-switch-' + _ctx.size + '-box']: true,
  }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleEvent && _ctx.toggleEvent(...args)))
  }, [
    _renderSlot(_ctx.$slots, "before", {}, undefined, true),
    _createElementVNode("div", {
      class: _normalizeClass([{ selected: _ctx.isChecked }, "switch-box"])
    }, null, 2),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {
        checked: _ctx.isChecked,
        modelValue: _ctx.modelValue
      }, undefined, true)
    ])
  ], 2))
}