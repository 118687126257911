
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
@Component({
})
export default class extends Vue {
  @State((state) => state.store.store)
  store;

  @Action("store/getStore")
  getStore
  list = []

  created() {
    this.getList();
  }

  async changeVip(v) {
    if (v == 1) {
      await this.$confirm("是否开启招募会员", {
        title: '提示'
      })
    }
    await this.$postNotify("/manager/level/toggle-paid-vip", {
      vipSwitch: v,
    });
    this.getStore()
  }

  editInfo() {
    return this.$goto({
      path: "/vip/level/pay-operat",
    });
  }
  async getList() {
    let { error, data, message } = await this.$get("/manager/level/paid-list", {
      pageSize: -1,
    });
    if (error) {
      return this.$error(message)
    }
    this.list = data.list;
  }
}
