import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26e791b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "city-item-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_CountyItem = _resolveComponent("CountyItem")!
  const _component_ArrowDown = _resolveComponent("ArrowDown")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_checkbox, {
      onChange: _ctx.toggleCheckedCity,
      "model-value": _ctx.isChecked,
      indeterminate: _ctx.indeterminate,
      disabled: _ctx.isDisabled
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.citem.name), 1)
      ]),
      _: 1
    }, 8, ["onChange", "model-value", "indeterminate", "disabled"]),
    _createVNode(_component_el_popover, {
      placement: "right",
      width: "190",
      trigger: "click",
      "visible-arrow": false,
      onShow: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = true)),
      onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.show = false))
    }, {
      reference: _withCtx(() => [
        _createVNode(_component_el_icon, {
          class: _normalizeClass(["arrow-box", { 'open-arrow-box': _ctx.show }])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ArrowDown)
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityHash[_ctx.citem.code].children, (coitem, coi) => {
            return (_openBlock(), _createBlock(_component_CountyItem, {
              disabled: _ctx.disabled,
              checked: _ctx.checked,
              coitem: coitem,
              onCheckChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('checkChange', $event))),
              key: coi
            }, null, 8, ["disabled", "checked", "coitem"]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}