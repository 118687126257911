
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  emits: ['update:modelValue']
})
export default class extends Vue {
  @Prop({
    default() {
      return false;
    }
  })
  modelValue: any

  @Prop({
    default() {
      return false;
    }
  })
  disabled: boolean

  @Prop({
    default() {
      return true;
    }
  })
  trueValue: any


  @Prop({
    default() {
      return false;
    }
  })
  falseValue: any

  @Prop({
    default() {
      return 'normal';
    }
  })
  size: any;

  get isChecked() {
    return this.modelValue === this.trueValue;
  }
  toggleEvent() {
    if (this.isChecked) {
      this.$emit("update:modelValue", this.falseValue);
      return;
    }
    this.$emit("update:modelValue", this.trueValue);
  }
}
