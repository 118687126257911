
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import GoodsItem from "./goods-item.vue";
import ReturnItem from "./return-item.vue";

@Component({
  components: {
    GoodsItem,
    ReturnItem
  }
})
export default class extends Vue {
  @Prop()
  modelValue: any;

  get expressType() {
    switch (this.modelValue.expressType) {
      case 0:
        return "无需配送";
      case 1:
        return "快递";
      case 2:
        return "到店自提";
    }
    return "";
  }
  get orderStatus() {
    switch (this.modelValue.status) {
      case 0:
        return "等待客户付款";
      case 1:
        return "等待发货";
      case 2:
        return "商家已发货";
      case 3:
        return "交易完成";
      case 4:
        return "订单关闭";
    }
    return "";
  }
  get payType() {
    switch (this.modelValue.payType) {
      case 1:
        return "微信支付";
      case 2:
        return "货到付款";
    }
    return "其他方式";
  }

  async remake() {
    let val = await this.$inputContent({
      title: "卖家备注",
      value: this.modelValue.remake,
    });
    let { error, message } = await this.$post("/manager/order/remake", {
      id: this.modelValue.id,
      remake: val,
    });
    if (error) return this.$error(message);
    this.refresh();
  }
  refresh() {
    this.$emit("refresh");
  }
  async returnGoods() {
    await this.$returnGoods({
      id: this.modelValue.id,
    });
    this.refresh();
  }
  async modifyPrice() {
    await this.$modifyPrice({
      id: this.modelValue.id,
    });
    this.refresh();
  }
  async sendGoods() {
    await this.$sendGoods({
      id: this.modelValue.id,
    });
    this.refresh();
  }
}
