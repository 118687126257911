import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cdf4a6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "goods-images" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "goods-content" }
const _hoisted_4 = {
  key: 0,
  class: "goods-title"
}
const _hoisted_5 = { class: "goods-title-inner" }
const _hoisted_6 = {
  key: 1,
  class: "goods-opeart-box"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 2,
  class: "buy-button-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "item-container",
    style: _normalizeStyle(_ctx.modelValue.style)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["item-inner-container", 'list-mode-' + _ctx.modelValue.listMode])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "goods-item-box"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              src: item.cover
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.modelValue.showTitle)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.modelValue.showPrice || _ctx.modelValue.showBuyBtn)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", null, [
                    (_ctx.modelValue.showPrice)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, "￥" + _toDisplayString(item.showPrice), 1))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.modelValue.showBuyBtn)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (_ctx.modelValue.btnStyle == 0)
                          ? (_openBlock(), _createBlock(_component_ui_icon, {
                              key: 0,
                              modelValue: "iconjia",
                              class: "icon-right"
                            }))
                          : (_ctx.modelValue.btnStyle == 1)
                            ? (_openBlock(), _createBlock(_component_ui_icon, {
                                key: 1,
                                modelValue: "icongoumai",
                                class: "icon-right"
                              }))
                            : (_openBlock(), _createElementBlock("div", _hoisted_9, "购买"))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ], 2)
  ], 4))
}