import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7aeb726"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-page-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createVNode(_component_el_pagination, {
      "current-page": _ctx.currentValue,
      "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentValue) = $event)),
      background: "",
      layout: "prev, pager, next",
      "page-size": _ctx.pageSize,
      total: _ctx.countTotal
    }, null, 8, ["current-page", "page-size", "total"])
  ]))
}