import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_form_item = _resolveComponent("ui-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ui_form = _resolveComponent("ui-form")!

  return (_openBlock(), _createBlock(_component_ui_form, {
    ref: "formTable",
    "label-width": "100px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ui_form_item, {
        required: true,
        data: _ctx.ruleForm.appid,
        label: "APPID"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.appid,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ruleForm.appid) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["data"]),
      _createVNode(_component_ui_form_item, { label: "页面路径" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.page,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ruleForm.page) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ui_form_item, { label: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.submitForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("确定")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('reject', { msg: '取消选择' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}