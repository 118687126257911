
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
@Component({})
export default class extends Vue {


  @Action("store/getStore")
  getStore;


  ruleForm = {
    mchId: "",
  }

  async submitForm() {
    let { error } = await this.$post(
      "/manager/store/edit-wechat-pay",
      this.ruleForm
    );
    if (error) return;
    this.$success("设置成功");
    this.getStore()
    this.$emit("resolve",)
  }

}
