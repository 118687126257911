
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop({
    default() {
      return ''
    }
  })
  modelValue: string;

  @Prop({
    default() {
      return ''
    }
  })
  style: string;

  @Watch('modelValue', {
    immediate: true,
  })
  modelValueWatch() {
    this.localValue = this.modelValue;
  }

  _focus = false;
  focusEvente(e) {
    this._focus = true;
    this.$emit("focus", e);
  }
  blutEvent(e) {
    this._focus = false;
    this.$emit("focus", e);
  }
  localValue = "";
  inputEvent(e) {
    this.$emit("update:modelValue", e.target.value);
  }
}
