import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f863ba8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type == 'url')
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _ctx.modelValue
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["ui-ezshop", _ctx.classStyle]),
        innerHTML: _ctx.showContent
      }, null, 10, _hoisted_2))
}