
import draggable from "vuedraggable";
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
  components: {
    draggable
  }
})
export default class extends Vue {
  @Prop()
  modelValue: any;
  removeImage(index) {
    this.modelValue.list.splice(index, 1);
  }
  async addImage() {
    let data = await this.$chooseImage();
    this.modelValue.list.push(
      ...data.map(function (item) {
        return { image: item.url, title: "", link: {} };
      })
    );
  }
}
