import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3aa2af21"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin":"0","max-height":"80vh","overflow":"auto"} }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "ruleForm",
    "label-width": "100px",
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "父类" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.parent ? _ctx.parent.name : "无"), 1)
        ]),
        _: 1
      }),
      (_ctx.parent)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "父类ID"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.parent.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.parent.id) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_form_item, { label: "名称" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ruleForm.name) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "别名" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.aliasName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.ruleForm.aliasName) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "前端路径" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.path,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ruleForm.path) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "文件路径" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.filePath,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ruleForm.filePath) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "是否显示" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            "true-label": 1,
            "false-label": 0,
            modelValue: _ctx.ruleForm.show,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.ruleForm.show) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode("菜单")
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "重定向" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            "true-label": 1,
            "false-label": 0,
            modelValue: _ctx.ruleForm.redirect,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.ruleForm.redirect) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode("是否需要")
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "评论" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.comment,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.ruleForm.comment) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "排序" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.sort,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.ruleForm.sort) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "图标" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.icon,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.ruleForm.icon) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "权限" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_popover, {
            placement: "top",
            width: "300"
          }, {
            reference: _withCtx(() => [
              _createElementVNode("div", null, [
                (_ctx.ruleForm.roleItemInfo)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_el_link, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.ruleForm.roleItemInfo.name), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("span", {
                        style: {"margin-left":"10px"},
                        onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.ruleForm.roleItemInfo = null), ["stop"]))
                      }, "取消")
                    ], 64))
                  : (_openBlock(), _createBlock(_component_el_link, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode("请选择")
                      ]),
                      _: 1
                    }))
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_el_tree, {
                  data: _ctx.list,
                  "node-key": "id",
                  "default-expand-all": "",
                  class: "cus-tree-box"
                }, {
                  default: _withCtx(({ node, data }) => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["limit-9999row-item-box", {
                selected: _ctx.ruleForm.roleItemInfo
                  ? _ctx.ruleForm.roleItemInfo.id == data.id
                  : null,
              }]),
                      onClick: _withModifiers(($event: any) => (_ctx.selectItem(data)), ["stop"])
                    }, [
                      _createElementVNode("div", null, _toDisplayString(data.name), 1)
                    ], 10, _hoisted_2)
                  ]),
                  _: 1
                }, 8, ["data"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.confirmData
          }, {
            default: _withCtx(() => [
              _createTextVNode("确定")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, { onClick: _ctx.closeDialog }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}