
import { Component, Vue } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
@Component({})
export default class extends ListViewVue {
  listType = "router";
  getList() {
    return this.$get('/manager/page/list', {
      isSystem: 1,
      ...this.mesureData
    })
  }
  gotoEdit(item) {
    this.$goto({
      name: 'system-edit',
      query: {
        id: item.id
      }
    });
  }
}
