
import { LockDec, ValidateDec } from '@/decorators';
import { Watch, Component, Vue, toNative, mixins } from 'vue-facing-decorator'
@Component({

})
export default class extends Vue {
  loading = false
  submitLoading = false
  list: any[] = []

  created() {
    this.getData();
  }

  backEvent() {
    this.$router.back()
  }
  removeItem() {
    this.list.splice(-1)
  }

  async getData() {
    this.loading = true;
    let { error, data } = await this.$get("/manager/level/task-list", {
    });
    this.loading = false;
    if (error) return;
    this.list = data.list
  }

  @LockDec("submitLoading")
  @ValidateDec("form")
  async submit() {
    await this.$postNotify("/manager/level/operat-task",
      {
        list: this.list
      })
  }
}
