
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({

})
export default class extends Vue {

  @Watch('value_')
  value_Watch() {
    this.showValue = this.value_;
  }

  @Watch('info')
  infoWatch() {
    this.info_ = { ...this.info };
  }

  @Prop()
  type: any
  @Prop()
  goods: any
  @Prop()
  info: any


  get maxPrice() {
    if (this.info_.skuList.length == 0) {
      return this.goods.price;
    }
  }
  get minPrice() {
    if (this.info_.skuList.length == 0) {
      return this.goods.price;
    }
  }
  get afterMinPrice() {
    if (this.info_.skuList.length == 0) {
      return this.goods.price;
    }
  }
  get afterMaxPrice() {
    if (this.info_.skuList.length == 0) {
      return this.goods.price;
    }
  }
  get value_() {
    if (!this.info_.skuList) return ""
    if (this.info_.skuList.length == 0) {
      if (this.type != this.info_.type) return "";
      return this.info_.value;
    }
    for (let i = 0; i < this.info_.skuList.length; i++) {
      let item = this.info_.skuList[i];
      if (item.type != this.type) return "";
      let prev = this.info_.skuList[i - 1];
      if (prev && prev.value != item.value) {
        return "";
      }
    }
    return this.info_.skuList[0].value;
  }
  showValue = ""
  info_: any = { skuList: [] }
  created() {
    this.info_ = { ...this.info }
    this.showValue = this.value_;
  }
  change(val) {
    this.$emit("change", val);
  }
}

