import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0edd54fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "radoi-card-box" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "desc-color" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["radoi-card-item", { selected: item.value == _ctx.modelValue }]),
        key: index,
        onClick: ($event: any) => (_ctx.changeValue(item.value))
      }, [
        _createElementVNode("div", null, _toDisplayString(item.name), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(item.desc), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}