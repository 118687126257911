import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa9ebed0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "province-item-box" }
const _hoisted_2 = {
  key: 0,
  class: "city-list-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_ArrowDown = _resolveComponent("ArrowDown")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_CityItem = _resolveComponent("CityItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["city-list-wrap", { 'droplist-box': _ctx.item.code == _ctx.selected }])
    }, [
      _createElementVNode("div", null, [
        _createVNode(_component_el_checkbox, {
          modelValue: _ctx.isChecked,
          indeterminate: _ctx.indeterminate,
          disabled: _ctx.isDisabled,
          onChange: _ctx.toggleCheckProvince
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.item.name), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "indeterminate", "disabled", "onChange"]),
        _createVNode(_component_el_icon, {
          class: "arrow-box city-arrow-box",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', _ctx.item.code)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ArrowDown)
          ]),
          _: 1
        })
      ]),
      (_ctx.selected == _ctx.item.code)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (citem, i) => {
              return (_openBlock(), _createBlock(_component_CityItem, {
                citem: citem,
                checked: _ctx.checked,
                onCheckChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('checkChange', $event))),
                key: i,
                disabled: _ctx.disabled
              }, null, 8, ["citem", "checked", "disabled"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}