
import { Watch, Component, Vue, Prop, toNative, mixins } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {

  @Prop({})
  modelValue: any

  @Prop({})
  validate: any

  @Watch('modelValue')
  modelValueWather(val) {
    this.modelValue_ = val;
  }

  modelValue_ = ''
  created(): void {
    this.modelValue_ = this.modelValue
  }
  inputValue(val) {
    if (val != "") {
      switch (this.validate) {
        case "coin":
          if (
            !/^(([1-9][0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/.test(
              val
            )
          ) {
            return;
          }
          break;
      }
    }
    this.modelValue_ = val;
    this.$emit("update:modelValue", val);
  }

}

