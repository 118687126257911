
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
@Component({
  components: {},

})
export default class extends ListViewVue {
  @Prop()
  modelValue: any
  @Watch('modelValue')
  modelValueWatch() {
    this.refresh();
  }

  remove(id) {
    let index = this.modelValue.indexOf(id);
    this.modelValue.splice(index, 1);
    this.$emit("update:modelValue", [...this.modelValue]);
  }

  async chooseData() {
    let data = await this.$chooseGoods({
      list: this.modelValue.map((id) => {
        return { id };
      }),
    });
    let ids = data.map((item) => {
      return item.id;
    });
    this.$emit("update:modelValue", ids);
  }

  async getList() {
    if (this.modelValue.length == 0) {
      return {
        data: {
          list: [],
        }
      }
    }
    return this.$get("/manager/goods/list", {
      ...this.mesureData,
      ids: this.modelValue,
      pageSize: 5,
    });
  }
}
