
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
  components: {}
})
export default class extends Vue {

  @Prop()
  modelValue: any;

  @Prop()
  style: any;

  @Prop({
    default() {
      return 'image'
    }
  })
  type: string;

  closeEvent() {
    this.$emit("update:modelValue")
  }

  get imgSrc() {
    return this.modelValue
  }

  get fileName() {
    return this.modelValue
  }
  get processStyle() {
    return {
      width: this.loaded.progress + '%'
    }
  }

  uploading = false;

  loaded = {
    progress: 0,
  }

  uuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = "-";
    var uuid = s.join("");
    return uuid;
  }

  async sliceUpdate(file: File) {
    let step = 1024 * 1024 * 1;
    let start = 0;
    let res
    let group = this.uuid();
    while (true) {
      if (start > file.size) {
        break;
      }
      let fd = new FormData();
      let bold = file.slice(start, start + step);
      let uploadSliceFile = new File([bold], file.name);
      fd.append("group", group)
      fd.append("file", uploadSliceFile);
      if (start == 0) {
        fd.append("begin", '1');
      }
      if (start + uploadSliceFile.size > file.size) {
        fd.append("end", '1');
      }
      let { error, data, message, origin } = await this.$upload('/common/piece-upload', fd, (res) => {
        let loaded = res.loaded + start,
          total = file.size;
        let percentage = Math.ceil((loaded / total) * 100);
        this.loaded.progress = Math.min(percentage, 99.99);
      });
      if (error) {
        return { error, data, message, origin }
      }
      res = data;
      start += step;
    }
    this.loaded.progress = 100;
    return { data: res }
  }
  async uploadFile(file: File) {
    this.uploading = true;
    let { error, data } = await this.sliceUpdate(file)
    this.uploading = false;
    if (error) return;
    switch (this.type) {
      case 'image':
        this.$emit("update:modelValue", data.url);
        return
      default:
        this.$emit("update:modelValue", data);
        return;
    }
  }

  async requests(res) {
    this.uploadFile(res.file);
  }
}
