
import draggable from "vuedraggable";
import { Component, Vue, Prop } from 'vue-facing-decorator'
@Component({
  components: {
    draggable,
  },
})
export default class extends Vue {
  @Prop()
  modelValue: any;

  @Prop()
  cover: any;

  addSku() {
    this.modelValue.specList.push({
      name: "",
      image: "",
    });
  }
}
