import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab170bea"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "item-container",
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.modelValue.showTitle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "item-title",
          style: _normalizeStyle(_ctx.titleStyle)
        }, _toDisplayString(_ctx.title), 5))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      innerHTML: _ctx.content,
      style: _normalizeStyle(_ctx.contentStyle),
      class: "editor-box"
    }, null, 12, _hoisted_1)
  ], 4))
}