import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc04aa7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "manager-layout-box" }
const _hoisted_2 = { class: "left-menu-box" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "right-body-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Limit = _resolveComponent("Limit")!
  const _component_Role = _resolveComponent("Role")!
  const _component_Store = _resolveComponent("Store")!
  const _component_Category = _resolveComponent("Category")!
  const _component_VersionList = _resolveComponent("VersionList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass([{ 'selected-box': _ctx.value == item.value }, "menu-item-box"]),
          onClick: ($event: any) => (_ctx.value = item.value),
          key: i
        }, _toDisplayString(item.name), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.value == 'menu')
        ? (_openBlock(), _createBlock(_component_Menu, { key: 0 }))
        : (_ctx.value == 'limit')
          ? (_openBlock(), _createBlock(_component_Limit, { key: 1 }))
          : (_ctx.value == 'role')
            ? (_openBlock(), _createBlock(_component_Role, { key: 2 }))
            : (_ctx.value == 'store')
              ? (_openBlock(), _createBlock(_component_Store, { key: 3 }))
              : (_ctx.value == 'category')
                ? (_openBlock(), _createBlock(_component_Category, { key: 4 }))
                : (_ctx.value == 'version-list')
                  ? (_openBlock(), _createBlock(_component_VersionList, { key: 5 }))
                  : _createCommentVNode("", true)
    ])
  ]))
}