
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import floating from "./floating/index.vue";
import { getDefaultData } from "./index";
@Component({
  components: {
    floating,
  },
})
export default class extends Vue {
  @Prop()
  modelValue: any


  created() {
    if (!this.modelValue) {
      this.$emit("update:modelValue", getDefaultData());
    }
  }
}
