
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
  
})
export default class extends Vue {

  @Prop()
  modelValue: any

  processVideo(url) {
    return `${url}?x-oss-process=video/snapshot,t_0,m_fast,w_240`;
  }
}
