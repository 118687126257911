
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {}
})
export default class extends Vue {
  @Prop()
  resolve: any;
  @Prop()
  props: any;
  @Prop()
  content: any;

  get currentComponent() {
    if (typeof this.content == "function") {
      return this.content();
    }
    return this.content;
  }
  showDialog = false;

  eventClose = false;
  showContent = false;

  async mounted() {
    this.showDialog = true;
    await this.$nextTick()
    this.showContent = true
  }
  closed() {
    if (this.eventClose) return;
    let fn = this.resolve as Function;
    fn([{ msg: '点击模块关闭' }]);
  }
  async resolveEvent(...arg) {
    this.eventClose = true;
    let fn = this.resolve as Function;
    this.showDialog = false;
    await this.$sleep(300)
    this.showContent = false;
    fn(...arg);
  }
}
