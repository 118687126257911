import Text from './text/index';
import Image from './image/index';
import ImageText from './image-text/index';
// import Custom from './custom/index';
import Category from './category/index';
import Case from './case/index';
import Gap from './gap/index';
import Goods from './goods/index';
import Search from './search/index';
import RichText from './rich-text/index';
import Notice from './notice/index';
import Shopinfo from './shopinfo/index';
import MenuList from './menu-list/index';
import MenuFixed from './menu-fixed/index';

// 用户相关组件
import UserCenter from './user-center/index';
import UserMenu from './user-menu/index';
import UserOrderMenu from './user-order-menu/index';

export const menu = [
  {
    title: "基础组件",
    list: [
      Text.data,
      Image.data,
      ImageText.data,
      // Custom.data,
      Gap.data,
      Goods.data,
      Search.data,
      RichText.data,
      Notice.data,
      Shopinfo.data,
    ],
  },
]

export default {
  Text,
  Image,
  ImageText,
  // Custom,
  Category,
  Gap,
  Goods,
  Search,
  RichText,
  Notice,
  Shopinfo,
  MenuList,
  MenuFixed,
  UserCenter,
  UserMenu,
  UserOrderMenu,
  Case
}