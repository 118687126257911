
import { Component, Inject, Prop, Vue, Watch } from 'vue-facing-decorator'
import { rootForm } from '../form/constant';
import { formItemContextKey } from 'element-plus'
@Component({
  provide() {
    let key = formItemContextKey as symbol;
    return { [key]: this }
  }
})
export default class extends Vue {
  @Prop()
  data: any;

  @Prop()
  rule: any;

  @Prop()
  required: any;

  @Prop()
  label: string;

  @Prop()
  error: string;

  @Prop({
    default() {
      return true;
    }
  })
  showError: boolean;

  @Prop({
    default() {
      return ['blur', 'custom'];;
    }
  })
  trigger: string | string[];

  get showLabel() {
    return (!!this.label) || this.label === '';
  }

  @Inject({
    from: rootForm
  })
  rootForm;

  _error = null;

  mounted() {
    this.rootForm.push(this);
  }

  private _valiRequired() {
    if (this.data instanceof Array) {
      if (this.data.length == 0) {
        throw this.error || '请选择内容'
      }
      return;
    }
    if (!this.data) {
      throw this.error || '请输入内容'
    }
  }

  private async _validate() {
    if (this.data === undefined && !this.rule) return;
    if (!this.rule && !this.required) return;
    let type = typeof (this.rule);
    if (type == 'string') {
      switch (this.rule) {
        case 'require':
          this._valiRequired()
          return;
      }
    } else if (type == 'function') {
      return this.rule(this.data)
    } else if (this.rule instanceof RegExp) {
      if (!this.rule.test(this.data)) {
        throw this.error || '内容有误'
      }
      return true;
    }
    if (this.required) {
      this._valiRequired()
    }
  }
  async validate(eventKey) {
    if (eventKey) {
      if (this.trigger instanceof Array) {
        if (this.trigger.indexOf(eventKey) == -1) return;
      } else {
        if (this.trigger != eventKey) return;
      }
    }
    try {
      await this._validate()
    } catch (e) {
      this._error = { message: e };
      throw e;
    }
    this._error = null;
  }

  clear() {
    this._error = null;
  }

  beforeUnmount() {
    this?.rootForm?.unpush(this)
  }
}
