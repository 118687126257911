
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
import Update from "./com/update.vue";

@Component({})
export default class extends ListViewVue {
  tablist = [
    {
      name: "所有分类",
      value: "",
    }
  ]
  activeName = ""

  async getList() {
    return this.$get("/manager/super/store/category-list", { ...this.mesureData });
  }
  async operat(row) {
    await this.$dialog({
      title: "修改记录",
      content() {
        return <Update row={row} />
      }
    });
    this.refresh()
  }
}
