
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import OrderItem from "./order-item.vue";
@Component({
  components: {
    OrderItem
  }
})
export default class extends Vue {
  @Prop()
  data: any[]
}
