
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
})
export default class extends Vue {
  @Prop()
  modelValue: any;

  inputEvent(time) {
    if (
      this.modelValue &&
      this.modelValue instanceof Date &&
      time &&
      time instanceof Date
    ) {
      time.setHours(
        this.modelValue.getHours(),
        this.modelValue.getMinutes(),
        this.modelValue.getSeconds()
      );
    }

    this.$emit("update:modelValue", time);
  }
  created() { }
}
