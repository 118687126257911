
import { dialog } from '@/plugins/dialog/index'
import InstanceBox from './index.vue';

export default function (options) {
  options = Object.assign({
    multiple: true
  }, options)
  return dialog({
    title: '请选择会员组',
    content() {
      return <InstanceBox options={options}></InstanceBox>
    }
  })
}