
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';

import Nickname from "./dialog/nickname.vue";
import Signature from "./dialog/signature.vue";
import Qrcode from "./dialog/qrcode.vue";
import Avatar from "./dialog/avatar.vue";
import Category from "./dialog/category.vue";
@Component({})
export default class extends Vue {
  @State((state) => state.store.store)
  store;

  sizeForm: any = {}
  dataInfo: any = {}
  categoryInfo: any = {}
  allCategory: any = {}

  created() {
    this.getData();
    this.getCategory();
    this.getCategoryAll();
  }
  async addCategory() {
    let list = this.allCategory.categories_list.categories || [];
    await this.$dialog({
      title: "选择分类",
      props: { width: "500px", },
      content() {
        return <Category list={list}></Category>;
      },
    });
    this.getCategory();
  }
  async deleteRow(row) {
    let { error, data } = await this.$post(
      "/manager/open-platform/delete-wechat-cactegory",
      {
        firstId: row.first,
        secondId: row.second,
      }
    );
    if (error) return;
    this.getCategory();
  }
  async setImage() {
    await this.$dialog({
      title: "设置头像",
      props: { width: "500px", },
      content() {
        return <Avatar></Avatar>;
      },
    });
    this.getData();
  }

  async viewCode() {
    await this.$dialog({
      title: "小程序码",
      props: { width: "500px", },
      content() {
        return <Qrcode></Qrcode>;
      },
    });
  }

  async changeSignature() {
    await this.$dialog({
      title: "修改简介",
      props: { width: "500px", },
      content() {
        return <Signature></Signature>;
      },
    });
    this.getData();
  }
  async changeNickName() {
    await this.$dialog({
      title: "修改昵称",
      props: { width: "500px", },
      content() {
        return <Nickname></Nickname>;
      },
    });
    this.getData();
  }
  async getCategoryAll() {
    let { error, data } = await this.$get(
      "/manager/open-platform/get-wechat-cactegory-all"
    );
    if (error) return;
    this.allCategory = data;
  }
  async getCategory() {
    let { error, data } = await this.$get(
      "/manager/open-platform/get-wechat-cactegory"
    );
    if (error) return;
    this.categoryInfo = data;
  }
  async getData() {
    let { error, data } = await this.$get(
      "/manager/open-platform/get-wechat-info"
    );
    if (error) return;
    this.dataInfo = data;
  }
}
