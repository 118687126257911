import Edit from './edit.vue'
import Show from './show.vue'
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {
          showTitle: 1,
          title: "",
          content: "",
          titleStyle: {
            color: "#323233",
            fontSize: '16px',
            fontWeight: 'bold'
          },
          contentStyle: {
            color: "#969799",
            fontSize: '12px',
            fontWeight: 'normal'
          },
          style: {
            textAlign: "left",
            backgroundColor: "#FFFFFF",
          },
        },
        render: "Text",
      }
    },
    name: "文本组件",
    icon: "iconfont-size",
  }
}