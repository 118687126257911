
import tinymce from 'tinymce'
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
})
export default class extends Vue {
  @Prop()
  modelValue: string

  editor: any[]
  async mounted() {
    let editors = await tinymce.init({
      base_url: '/tinymce',
      target: this.$refs.editor as HTMLElement, //容器，可使用css选择器
      language: 'zh-Hans', //调用放在langs文件夹内的语言包
      promotion: false,
      suffix: '.min',
      menubar: 'edit insert format table ',
      branding: false,
      // menu: {
      //   edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
      //   view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments' },
      //   insert: { title: 'Insert', items: 'image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime' },
      //   format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
      //   table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
      // },
      file_picker_types: 'file image media',
      contextmenu: ['link', 'image', 'table'],
      plugins: ['link', 'image', 'table'], //选择需加载的插件
      // plugins: 'image',
      // menubar: 'insert',
      toolbar: 'image',
      image_caption: true,
      image_title: true,
      /* enable automatic uploads of images represented by blob or data URIs*/
      automatic_uploads: true,
      file_picker_callback: async (cb, value, meta) => {
        let res = await this.$chooseImage({ multiple: false });
        cb(res.url, {})
      }
      //选中时出现的快捷工具，与插件有依赖关系
      // quickbars_selection_toolbar: 'bold italic forecolor | link blockquote',
    });
    editors.map((item) => {
      item.on("input NodeChange", (e) => {
        this.$emit("update:modelValue", item.getContent())
      })
      item.setContent(this.modelValue || '')
    })
    this.editor = editors
  }
  beforeUnmount() {
    this.editor.map((item) => {
      item.destroy()
    })
  }
}
