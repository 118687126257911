
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
  components: {}
})
export default class extends Vue {

  @Prop()
  content: any;
  @Prop()
  props: any;
  @Prop()
  resolve: any;
  @Prop()
  reject: any;
  @Prop()
  title: string;

  get currentComponent() {
    if (typeof this.content == "function") {
      return this.content();
    }
    return this.content;
  }
  showDialog = false;
  mounted() {
    this.showDialog = true;
  }
  rejectEvent(data) {
    let fn = this.reject as Function;
    this.showDialog = false;
    fn(data)
  }
  async resolveEvent(...arg) {
    let fn = this.resolve as Function;
    this.showDialog = false;
    fn(...arg);
  }
}
