import { Throttle } from '@/decorators';
import axios from 'axios';
import { App } from 'vue';
const PREV = process.env.VUE_APP_API_BASE;
import { utils } from '@/plugins/utils/index'

class ApiClass {
  @Throttle(300)
  async logout() {
    const store = await import('@/store/index')
    store.default.dispatch('user/logout', {
      needRquest: false,
      tooltip: false,
    });
    utils.$error('登录过期')
  }
}

let apiClass = new ApiClass()
const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  }
});

const REQUEST = async function (url, method = 'GET', data = {}, headers = {}, progress = (e) => { }): Promise<{ data?: any, message?: string, origin?: any, error?: any }> {
  let store = (await import('@/store')).default;
  let params = {};
  if (method == 'GET') {
    params = data;
  }
  return new Promise((r) => {
    instance.request({
      method,
      url,
      data,
      params: params,
      onUploadProgress: progress,
      headers: {
        ...headers,
        'Authorization': store.state['user']?.token
      }
    }).then(function (res) {
      if (res.data.code == 200) {
        return r({ data: res.data.data, message: res.data?.data?.message || res?.data?.message })
      }
      if (res.data.code == 100000) {
        apiClass.logout()
      }
      return r({ error: res.data, message: res.data.message, origin: res })
    })
      .catch(function (res) {
        if (navigator.onLine) {
          return r({ message: '网络请求失败', error: res, origin: res })
        }
        return r({ message: '请检查网络状态', error: res, origin: res })
      });
  })
}

const request = {
  async $getExport(url: string, payload = {}, prev = PREV) {
    let res = REQUEST(`${prev}${url}`, 'GET', { ...payload, is_export: 1 });
    let { error, data, message, origin } = await res;
    if (error) {
      utils.$error(message)
      return { error, data, message, origin };
    }
    let iframe = document.createElement("iframe")
    iframe.src = data.url;
    document.body.appendChild(iframe)
  },
  async $get(url: string, data = {}, prev = PREV) {
    return REQUEST(`${prev}${url}`, 'GET', data);
  },
  async $getNotify(url: string, data = {}, prev = PREV) {
    let v = request.$get(url, data, prev);
    let { error, message } = await v;
    if (error) {
      utils.$error(message)
      throw v
    }
    utils.$success(message)
    return v
  },
  async $getError(url: string, data = {}, prev = PREV) {
    let v = request.$get(url, data, prev);
    let { error, message } = await v;
    if (error) {
      utils.$error(message);
      throw v;
    }
    return v
  },
  async $post(url: string, data = {}, prev = PREV) {
    return REQUEST(`${prev}${url}`, 'POST', data);
  },
  async $postNotify(url: string, payload = {}, prev = PREV) {
    let v = request.$post(url, payload, prev);
    let { error, message, data } = await v;
    if (error) {
      utils.$error(message)
      throw v
    }
    utils.$success(message)
    return data
  },
  async $postError(url: string, payload = {}, prev = PREV) {
    let v = request.$post(url, payload, prev);
    let { error, message, data } = await v;
    if (error) {
      utils.$error(message);
      throw v;
    }
    return data
  },

  async $upload(url: string, data = {}, progress = (e) => { }, prev = PREV) {
    return REQUEST(`${prev}${url}`, 'POST', data, {
      'Content-Type': 'application/multipart/form-data',
    }, progress);
  },

  async $getDownload(url, data?, options?, prev = PREV): Promise<{ message?: string, data?: any, error?: any, }> {
    options = { method: 'GET', auto: true, headers: {}, ...options }
    return new Promise(async function (r) {
      let store = (await import('@/store')).default;
      let headers = options.headers;
      if (store.state['user'].token) {
        headers = {
          'Authorization': store.state['user'].token,
          ...headers
        }
      }
      instance.request({
        method: options.method,
        url: `${prev}${url}`,
        params: data,
        responseType: 'blob',
        ...options,
        headers
      }).then(async (res) => {
        if (res.data.type === 'application/json') {
          const fileReader = new FileReader();
          fileReader.readAsText(res.data);
          fileReader.onload = () => {
            const text = fileReader.result;
            try {
              r({ error: true, message: JSON.parse(`${text}`) })
            } catch (e) {
              return r({ error: e, message: '未知错误' })
            }
          }
          return;
        }
        if (options.auto) {
          let url = URL.createObjectURL(res.data);
          utils.$download(url);
          URL.revokeObjectURL(url);
          return r({ data: res.data })
        }
        return r({ data: URL.createObjectURL(res.data) });
      }).catch((e) => {
        r({ error: e, message: '下载失败' })
      });
    });
  }
}

declare module '@vue/runtime-core' {
  type InstallApi = typeof request;
  export interface ComponentCustomProperties extends InstallApi {
  }
}

export default {
  install(app: App) {
    for (let key in request) {
      app.config.globalProperties[key] = request[key];
    }
  }
}
export { request } 