import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34e9205d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload-dialog-style-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChooseIcon = _resolveComponent("ChooseIcon")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_ChooseFile = _resolveComponent("ChooseFile")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.info.type == 3)
      ? (_openBlock(), _createBlock(_component_el_tabs, {
          key: 0,
          modelValue: _ctx.activeName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "系统图标",
              name: "first"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ChooseIcon, {
                  info: _ctx.info,
                  onResolve: _ctx.resolve,
                  onReject: _ctx.reject
                }, null, 8, ["info", "onResolve", "onReject"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, {
              label: "我的图片",
              name: "second"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ChooseFile, {
                  info: _ctx.info,
                  onResolve: _ctx.resolve,
                  onReject: _ctx.reject
                }, null, 8, ["info", "onResolve", "onReject"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : (_openBlock(), _createBlock(_component_ChooseFile, {
          key: 1,
          info: _ctx.info,
          onResolve: _ctx.resolve,
          onReject: _ctx.reject
        }, null, 8, ["info", "onResolve", "onReject"]))
  ]))
}