
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
interface Props {
  options: any
}

@Component({
  components: {
  }
})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  options: any

  get rows() {
    return this.options.rows || 4;
  }
  get maxlength() {
    return this.options.maxlength || 255;
  }
  get placeholder() {
    return this.options.placeholder || "请输入内容";
  }
  get title() {
    return this.options.title;
  }

  loaindg = false
  contentValue = ""

  created() {
    this.contentValue = this.options.value || "";
  }
  async submitForm() {
    this.$emit('resolve', this.contentValue);
  }
}
