import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00431ae4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabbar-box" }
const _hoisted_2 = { class: "tabbar-inner-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShowCategoryItem = _resolveComponent("ShowCategoryItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["item-container", _ctx.wrapClass]),
    style: _normalizeStyle(_ctx.modelValue.style)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabbar, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass([{ selected: _ctx.selected == index }, "tabbar-item-box"]),
            key: index
          }, _toDisplayString(item.name), 3))
        }), 128))
      ])
    ]),
    (_ctx.modelValue.showType == 'left')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "list-category-wrap",
          style: _normalizeStyle(_ctx.listWarpStyle)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabbar, (item) => {
            return (_openBlock(), _createBlock(_component_ShowCategoryItem, {
              modelValue: _ctx.modelValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
              item: item,
              key: item.id || item.name
            }, null, 8, ["modelValue", "item"]))
          }), 128))
        ], 4))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "list-category-wrap",
          style: _normalizeStyle(_ctx.listWarpStyle)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.tabbar[_ctx.selected]], (item) => {
            return (_openBlock(), _createBlock(_component_ShowCategoryItem, {
              modelValue: _ctx.modelValue,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue) = $event)),
              key: item.id || item.name,
              item: item
            }, null, 8, ["modelValue", "item"]))
          }), 128))
        ], 4))
  ], 6))
}