
import defaultData from "./index";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any

  defaultData: any = {}
  foramtRadius(val) {
    if (val == "normal") return "正常";
    return "圆形";
  }
  created() {
    this.defaultData = defaultData.data.data()
  }
}

