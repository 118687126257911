
import { LockDec, ValidateDec } from "@/decorators";
import DialogCoupont from "./com/dialog-coupont.vue";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {}
})
export default class extends Vue {
  loading = false
  submitLoading = false
  formData = {
    name: "",
    price: "",
    description: "",
    presentList: [
      {
        name: "",
        description: "",
        type: 1,
        amount: "",
        num: 1,
        couponInfo: null,
        couponId: null,
      },
    ],
  }

  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  }

  addRow() {
    this.formData.presentList.push({
      name: "",
      description: "",
      type: 2,
      amount: "",
      num: 1,
      couponInfo: null,
      couponId: null,
    });
  }

  async chooseData(index) {
    let data = await this.$dialog({
      title: "请选择优惠券",
      content() {
        return <DialogCoupont></DialogCoupont>;
      },
    });
    this.formData.presentList[index].couponId = data.id;
    this.formData.presentList[index].couponInfo = data;
    this.formData.presentList = [...this.formData.presentList];
  }
  async getData() {
    let id = this.$route.query.id;
    if (!id) return;
    let { error, data } = await this.$get("/manager/recharge/info", { id });
    if (error) return;
    this.formData = data;
    this.$forceUpdate();
  }

  @ValidateDec("form")
  @LockDec("submitLoading")
  async submit() {
    this.formData.presentList.map((item) => {
      if (item.type == 2) {
        if (!item.couponId) {
          this.$error('请选择优惠券')
          throw { msg: '' }
        }
      }
    });
    await this.$postNotify("/manager/recharge/operat", {
      ...this.formData,
    });
    this.$router.back();
  }
}
