import { Throttle } from "@/decorators";
import { Watch, Component, Vue } from 'vue-facing-decorator'

const defaultPageSize = 10;
const defaultPage = 1;

@Component({})
export class ListViewVue extends Vue {
  /**
   *  锁
   */
  private setLocked = false;

  @Watch('$route', {
    deep: true,
    immediate: true
  })
  _routerWatch() {
    if (this.firstLoading) {
      this.page_ = { ...this.page }
    }
    if (this.listType == 'scope') {
      return;
    }
    let res = { ...this.$route.query };
    let { pageSize, page, ...condition } = res;
    let pageInfo = { pageSize: parseInt(`${pageSize || defaultPageSize}`), page: parseInt(`${page || defaultPage}`) };
    if (this.firstLoading) {
      condition = { ...this.getCondition(), ...condition };
    }
    this.setLocked = true;
    this.page = { ...pageInfo };
    this.condition = { ...condition };
    this.$nextTick(() => {
      this.setLocked = false;
    });
    this.page_ = { ...pageInfo };
    this.condition_ = { ...condition };
  }

  @Watch('condition', {
    deep: true,
  })
  _conditionWatcher() {
    if (this.firstLoading) {
      if (this.listType == 'scope') {
        this.condition = { ...this.getCondition() };
        this.condition_ = { ...this.getCondition() };
      }
      return;
    }
  }


  @Watch('page', {
    deep: true,
  })
  _pageWatch() {
    if (this.firstLoading) return;
    if (this.setLocked) return;
    if (this.listType == 'scope') {
      this.page_ = { ...this.page }
      return;
    }
    this.gotoPath()
  }

  @Watch('_mesureData', {
    immediate: true,
  })
  async __mesureDataWatcher() {
    if (this.firstLoading) {
      await this.$sleep(1)
    }
    this._getList()
  }


  getCondition() {
    return {}
  }

  private gotoPath() {
    let { fullPath } = this.$router.resolve({ path: this.$route.path, query: { ...this.condition, ...this.page } });
    if (this.$route.fullPath == fullPath) {
      this.refresh()
      return
    }
    this.$router.push(fullPath)
  }
  /** 
   * 来源后台返回 用于显示
   */
  pageInfo = { countTotal: 0, pageSize: defaultPageSize };
  /**
   * 是否第一次加载
   */
  firstLoading = true;
  /**
   * "scope" | 'router'
   */
  listType: string = 'scope'

  /**显示用 */
  condition: Record<string, any> = {}

  /**
   * 显示分页
   */
  page: {
    page: number,
    pageSize: number,
    [key: string]: any
  } = {
      page: defaultPage,
      pageSize: defaultPageSize
    }

  /**
  * 实际条件
  */
  private condition_: Record<string, any> = {}

  /**
   * 实际分页
   */
  private page_: {
    page?: number,
    pageSize?: number,
    [key: string]: any
  } = {}

  source: {
    loading: boolean,
    list: any[],
    [key: string]: any
  } = { list: [], loading: false }

  find(data?) {
    this.page.page = 1;
    if (data) {
      this.condition = { ...data }
    }
    if (this.listType == 'scope') {
      this.condition_ = { ...this.condition }
      return;
    }
    this.gotoPath()
  }

  /**
  *  同一个时间片只允许调用一次
  * @returns 
  */
  @Throttle(0)
  refresh() {
    this._getList()
  }

  get _mesureData() {
    return { ...this.condition_, ...this.page_, }
  }

  get mesureData() {
    return { ...this._mesureData }
  }

  async getList(): Promise<{
    error?: any, data?: any, message?: string, origin?: any
  }> {
    console.error('请实现getList方法')
    return { error: true, data: { list: [], page: { countTotal: 0, pageSize: defaultPageSize } } }
  }

  async _getList() {
    this.source.loading = true;
    await this.$sleep(0);
    if (this.$.isUnmounted) {
      return [{ msg: '已卸载' }]
    }
    let res = this.getList()
    let { error, data } = await res;
    this.source.loading = false;
    this.firstLoading = false;
    if (error) return res;
    this.source = { ...data, loading: false }
    this.pageInfo = data.page
    return res;
  }

}