import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8cf51c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-tabbar-box" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
      return (_openBlock(), _createElementBlock("li", {
        key: i,
        class: _normalizeClass({ selected: item.value == _ctx.modelValue }),
        onClick: ($event: any) => (_ctx.$emit('update:modelValue', item.value))
      }, [
        _createElementVNode("div", null, _toDisplayString(item.name), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}