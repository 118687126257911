
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
})
export default class extends Vue {
  @Prop()
  modelValue: any


  get initValue() {
    return this.modelValue;
  }

  set initValue(v) {
    this.$emit("update:modelValue", v);
  }

  source = { list: [] };
  created() {
    this.getList()
  }

  async getList() {
    let { error, data } = await this.$get("/manager/goods/category-list", {
      pageSize: 100,
    });
    if (error) return;
    this.source = data;
  }
}
