
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import PriceInput from "./price-input.vue";
interface Props {
  info: any
  goods: any
}
@Component({
  components: { PriceInput },
})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  info: any

  @Prop()
  goods: any

  get skuHash() {
    let res = {};
    if (!this.goods || !this.goods.skuList) return res;
    for (let i = 0; i < this.goods.skuList.length; i++) {
      let item = this.goods.skuList[i];
      let name: any[] = [];
      let skuInfo: any[] = item.skuInfo;
      for (let i = 0; i < skuInfo.length; i++) {
        name.push(skuInfo[i].value);
      }
      res[item.id] = { ...item, name: name.join(" ") };
    }
    return res;
  }

  list = [];

  created() {
    this.list = this.info.skuList.map((item) => {
      return { ...item };
    })
  }

  confirmData() {
    this.$emit('resolve', this.list);
  }
  closeDialog() {
    this.$emit('reject', { msg: "已取消" });
  }
  getPrice(price: string, row) {
    if (!row.valuue) return price
    let res = (() => {
      switch (`${row.type}`) {
        case "1":
          return price.toDecimal().mul(row.value).div(10).toString();
        case "2":
          return price.toDecimal().minus(row.value).toString();
        case "3":
          return row.value;
      }
      return price;
    })();
    return parseFloat(res).toFixed(2);
  }
}
