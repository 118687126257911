
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import defaultData from "./index";
import EditGoods from "./edit-goods.vue";
import EditCategory from "./edit-category.vue";
@Component({
  components: {
    EditGoods,
    EditCategory
  },
})
export default class extends Vue {

  @Prop()
  modelValue: any;

  @Watch('modelValue.type')
  modelValueTypeWatch() {
    if (this.modelValue.type == "category") {
      return this.$emit("update:modelValue", defaultData.data.data2().data);
    }
    this.$emit("update:modelValue", defaultData.data.data().data);
  }

  list = [
    { name: "商品", value: "goods" },
    { name: "商品分组", value: "category" },
  ]
}
