import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "share-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_ui_layout1 = _resolveComponent("ui-layout1")!
  const _component_ui_upload_image_list = _resolveComponent("ui-upload-image-list")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_layout1, {
      style: {"margin-top":"5px"},
      class: "view-item-box"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_checkbox, {
            modelValue: _ctx.data.isShare,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.isShare) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode("能否分享")
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }),
    (_ctx.data.isShare)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_ui_layout1, {
            title: "分享图",
            class: "view-item-box"
          }, {
            content: _withCtx(() => [
              _createVNode(_component_ui_upload_image_list, {
                modelValue: _ctx.data.shareImage,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.shareImage) = $event)),
                tips: "更换图片",
                class: "setting-upload-avatar-box"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ui_layout1, {
            title: "分享标题",
            class: "view-item-box"
          }, {
            content: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.data.shareTitle,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.shareTitle) = $event)),
                placeholder: "请输入页面名称",
                size: "small"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true)
  ]))
}