import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"margin-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, { label: "商品分组" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_select, {
        filterable: "",
        placeholder: "请选择",
        modelValue: _ctx.initValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.initValue) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.source.list, (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: item.id,
              label: item.name,
              value: item.id
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_link, {
          type: "primary",
          onClick: _ctx.getList,
          underline: false
        }, {
          default: _withCtx(() => [
            _createTextVNode("刷新")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_divider, { direction: "vertical" }),
        _createVNode(_component_el_link, {
          type: "primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$goto('/goods/category/create', '_blank'))),
          underline: false
        }, {
          default: _withCtx(() => [
            _createTextVNode("新建分组")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}