
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {
  }
})
export default class extends Vue {

  @Prop()
  loading: boolean


  @Prop()
  submitLoading: boolean

  @Prop()
  formData: any

  async submit() {
    this.$emit("submit");
  }
} 
