import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d36148e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.style),
    class: _normalizeClass(["ui-textarea-box", { 'textarea-focus': _ctx._focus }])
  }, [
    _createElementVNode("textarea", _mergeProps({
      value: _ctx.localValue,
      autocomplete: "off",
      autocorrect: "off",
      autocapitalize: "off",
      spellcheck: "false",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputEvent && _ctx.inputEvent(...args)))
    }, _ctx.$attrs, {
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focusEvente && _ctx.focusEvente(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blutEvent && _ctx.blutEvent(...args)))
    }), null, 16, _hoisted_1)
  ], 6))
}