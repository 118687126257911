
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
  components: {},
})
export default class extends Vue {
  @Prop()
  modelValue: number

  @Prop()
  info: { countTotal: number, pageSize: number };

  get pageSize() {
    return Number(this.info?.pageSize || 10)
  }

  get countTotal() {
    return Number(this.info?.countTotal || 0)
  }
  get currentValue() {
    return Number(this.modelValue || 1);
  }
  set currentValue(v) {
    this.$emit("update:modelValue", v);
  }

  updateCurrent() { }
}
