
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any

  get empty() {
    if (this.modelValue.content == "") return true;
    return false;
  }
}

