import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e226e04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-name-box" }
const _hoisted_2 = {
  key: 0,
  class: "row-name-tag-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ui_title = _resolveComponent("ui-title")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_ui_card = _resolveComponent("ui-card")!
  const _component_ui_container = _resolveComponent("ui-container")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_ui_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_ui_card, null, {
        title: _withCtx(() => [
          _createVNode(_component_ui_title, { title: "角色管理" }, {
            right: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gotoDetail()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" 添加角色 ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createBlock(_component_ui_table, {
            data: _ctx.source.list
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "name",
                label: "名字",
                width: "180"
              }, {
                default: _withCtx(({ row }) => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", null, _toDisplayString(row.name), 1),
                    (!row.storeId)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "官"))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                prop: "description",
                label: "角色描述"
              }),
              _createVNode(_component_el_table_column, {
                label: "员工数量",
                width: "180"
              }, {
                default: _withCtx(({ row }) => [
                  _createTextVNode("0 ")
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "操作",
                width: "160",
                align: "right"
              }, {
                default: _withCtx(({ row }) => [
                  (row.storeId)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: ($event: any) => (_ctx.gotoDetail(row.id))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("编辑")
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_el_popconfirm, {
                          title: "是否删除该记录",
                          onConfirm: ($event: any) => (_ctx.deleteRow(row.id)),
                          style: {"margin-left":"10px"}
                        }, {
                          reference: _withCtx(() => [
                            _createVNode(_component_ui_button, { type: "danger" }, {
                              default: _withCtx(() => [
                                _createTextVNode("删除")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onConfirm"])
                      ], 64))
                    : (_openBlock(), _createBlock(_component_el_button, {
                        key: 1,
                        type: "primary",
                        onClick: ($event: any) => (_ctx.gotoDetail(row.id))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("查看")
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])), [
            [_directive_loading, _ctx.source.loading]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}