import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cf3ba72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabbar-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_Normal = _resolveComponent("Normal")!
  const _component_PayVip = _resolveComponent("PayVip")!
  const _component_ui_card = _resolveComponent("ui-card")!
  const _component_ui_container = _resolveComponent("ui-container")!

  return (_openBlock(), _createBlock(_component_ui_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_ui_card, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_tabs, {
              modelValue: _ctx.activeName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
              type: "card",
              class: "tabbar-box"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tablist, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_tab_pane, {
                    label: item.name,
                    name: item.value,
                    key: index
                  }, null, 8, ["label", "name"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          (_ctx.activeName == 'normal')
            ? (_openBlock(), _createBlock(_component_Normal, { key: 0 }))
            : (_openBlock(), _createBlock(_component_PayVip, { key: 1 }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}