
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
import DialogGoods from './com/dialog-goods/index.vue'
@Component({
  components: {},
})
export default class extends ListViewVue {
  listType = "router";

  tableChange(type) {
    let data = { ...this.condition };
    data.type = type;
    this.find(data);
  }

  async editInfo(row) {
    await this.$dialog({
      title: '商品分销设置',
      props: {
        width: '800px'
      },
      content: () => {
        return <DialogGoods modelValue={row} />
      }
    })
    this.refresh()
  }

  operatGoods(id?) {
    if (id) {
      return this.$goto({
        path: "/goods/goods/edit",
        query: {
          id,
        },
      });
    }
    return this.$goto({
      path: "/goods/goods/create",
    });
  }
  async getList() {
    return this.$get("/manager/salesman/goods-list", {
      ...this.mesureData,
    });
  }
  resetFilter() {
    this.find({ ...this.getCondition(), type: this.condition.type });
  }
}

