
import { ValidateDec } from '@/decorators';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({

})
export default class extends Vue {
  ruleForm = {
    name: "",
    sort: "",
  }
  rules = {
    name: [
      { required: true, message: "请输入分组名称", trigger: "blur" },
      {
        min: 2,
        max: 10,
        message: "长度在 2 到 10 个字符",
        trigger: "blur",
      },
    ],
  }

  @ValidateDec("formTable")
  async submitForm() {
    let { error, data, message } = await this.$post(
      "/manager/document/operat-category",
      this.ruleForm
    );
    if (error) return this.$error(message);
    this.$emit('resolve', data);
  }
}
