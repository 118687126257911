
import { dialog } from '@/plugins/dialog/index'
import InstanceBox from './index.vue';

export default function (options?: { multiple?: boolean, [key: string]: any }) {
  options = Object.assign({
    multiple: true
  }, options)
  return dialog({
    title: '请选择商品',
    content() {
      return <InstanceBox options={options}></InstanceBox>
    }
  })
}