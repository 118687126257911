import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63e6ca82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-item-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_ui_card = _resolveComponent("ui-card")!
  const _component_ui_container = _resolveComponent("ui-container")!

  return (_openBlock(), _createBlock(_component_ui_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_ui_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_tabs, {
            modelValue: _ctx.activeName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
            type: "card",
            class: "tabbar-box"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tablist, (item, index) => {
                return (_openBlock(), _createBlock(_component_el_tab_pane, {
                  label: item.name,
                  name: item.value,
                  key: index
                }, null, 8, ["label", "name"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", null, [
            _createVNode(_component_ui_button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.add()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("添加")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_el_tree, {
              data: _ctx.list,
              "node-key": "id",
              "default-expand-all": "",
              class: "cus-tree-box"
            }, {
              default: _withCtx(({ node, data }) => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", null, _toDisplayString(data.name) + " (" + _toDisplayString(data.limit) + ") ", 1),
                  _createElementVNode("div", {
                    class: "right-grid-box",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                  }, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_link, {
                        onClick: ($event: any) => (_ctx.add(data))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("添加")
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_link, {
                        onClick: ($event: any) => (_ctx.edit(data))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("编辑")
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_popconfirm, {
                        onConfirm: ($event: any) => (_ctx.deleteRow(data)),
                        title: "这是一段内容确定删除吗？"
                      }, {
                        reference: _withCtx(() => [
                          _createVNode(_component_el_link, null, {
                            default: _withCtx(() => [
                              _createTextVNode("删除")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onConfirm"])
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["data"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}