import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7aab0f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-choose-icon-box" }
const _hoisted_2 = {
  key: 0,
  class: "image-box"
}
const _hoisted_3 = {
  key: 0,
  class: "image-box-tips"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ui_close_btn = _resolveComponent("ui-close-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "choose-item-box",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.chooseImage && _ctx.chooseImage(...args)))
    }, [
      (_ctx.modelValue)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ui_icon, { modelValue: _ctx.modelValue }, null, 8, ["modelValue"]),
            (_ctx.tips)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.tips), 1))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createBlock(_component_el_icon, {
            key: 1,
            size: "30"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Plus)
            ]),
            _: 1
          })),
      (_ctx.modelValue && _ctx.showClose)
        ? (_openBlock(), _createBlock(_component_ui_close_btn, {
            key: 2,
            onClick: _withModifiers(_ctx.removeData, ["stop"])
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}