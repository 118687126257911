import Edit from './edit.vue'
import Show from './show.vue'

export function createData(data?) {
  return {
    icon: '',
    name: '',
    link: {},
    fixed: 0,
    ...data
  }
}

export function getDefaultData() {
  let res = {
    show: {
      home: 1,
      goods_category: 1,
      goods_detail: 1
    },
    icon1: 'https://file.cnzhizhi.com/common/icons/icon-add.png',
    icon2: 'https://file.cnzhizhi.com/common/icons/icon-multiple.png',
    list: [
    ]
  }
  res.list = res.list.map((item) => createData(item));
  return res;
}
export default {
  Show,
  Edit,
  createData,
  data: {
    data: getDefaultData,
    name: "悬浮导航",
  },
  style: {
    position: "absolute",
    bottom: '100px',
    right: 0,
    left: 0
  },
}