import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "item-container",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("div", {
      class: "search-show-box",
      style: _normalizeStyle(_ctx.searchStyle)
    }, [
      _createVNode(_component_ui_icon, {
        modelValue: "iconsousuo",
        class: "icon-style-box"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.modelValue.title), 1)
    ], 4)
  ], 4))
}