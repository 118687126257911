
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { createData } from "./index";
import draggable from "vuedraggable";
@Component({
  components: {
    draggable,
  },
})
export default class extends Vue {
  @Prop()
  modelValue: any;
  get source() {
    if (this.modelValue) return this.modelValue;
    return {};
  }
  valueType = "first"
  list = [
    { name: "内容设置", value: "first" },
    { name: "应用页面", value: "last" },
  ]

  changeCheck(item) {
    if (item.fixed == 0) {
      let k = 0;
      for (let i = 0; i < this.source.list.length; i++) {
        let item = this.source.list[i];
        if (item.fixed == 1) {
          if (++k >= 2) {
            return this.$error("固定菜单最多添加两个");
          }
        }
      }
    }
    item.fixed = item.fixed == 0 ? 1 : 0;
  }
  addMenu() {
    this.source.list.push(createData());
  }
  removeIndex(index) {
    this.source.list.splice(index, 1);
  }
}
