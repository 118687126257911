import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../image/gou.png'


const _withScopeId = n => (_pushScopeId("data-v-2f20e9da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-search-bar-box" }
const _hoisted_2 = ["accept"]
const _hoisted_3 = { class: "choose-image-list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "image-box-selected" }
const _hoisted_6 = { class: "image-box-selected-text" }
const _hoisted_7 = {
  key: 1,
  src: _imports_0
}
const _hoisted_8 = { style: {"color":"#999"} }
const _hoisted_9 = { class: "operat-button-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_video_item = _resolveComponent("video-item")!
  const _component_image_item = _resolveComponent("image-item")!
  const _component_ui_page = _resolveComponent("ui-page")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createBlock(_component_el_button, { onClick: _ctx.hugupUploadFile }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.uploadInfo.text), 1)
        ]),
        _: 1
      }, 8, ["onClick"])), [
        [_directive_loading, _ctx.uploadLoading]
      ]),
      _createVNode(_component_el_input, {
        modelValue: _ctx.condition.keyword,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.condition.keyword) = $event)),
        placeholder: "请输入关键字",
        onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.find()), ["enter","native"]))
      }, null, 8, ["modelValue"])
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "file",
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.uploadFiles && _ctx.uploadFiles(...args))),
      ref: "uploadFile",
      multiple: "",
      accept: _ctx.uploadInfo.accept
    }, null, 40, _hoisted_2), [
      [_vShow, false]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives((_openBlock(), _createElementBlock("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.source.list, (item, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: i,
            onClick: ($event: any) => (_ctx.changeChoose(item)),
            class: _normalizeClass({ selected: _ctx.selectHash[item.id] })
          }, [
            (item.type == 2)
              ? (_openBlock(), _createBlock(_component_video_item, {
                  key: 0,
                  modelValue: item
                }, null, 8, ["modelValue"]))
              : (_openBlock(), _createBlock(_component_image_item, {
                  key: 1,
                  modelValue: item
                }, null, 8, ["modelValue"])),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.multiple)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.getIndex(_ctx.modelValue, item)), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock("img", _hoisted_7))
              ])
            ])
          ], 10, _hoisted_4))
        }), 128))
      ])), [
        [_directive_loading, _ctx.source.loading]
      ])
    ]),
    _createVNode(_component_ui_page, {
      style: {"margin-top":"16px"},
      info: _ctx.pageInfo,
      modelValue: _ctx.page.page,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.page.page) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, "已选择 " + _toDisplayString(_ctx.modelValue.length) + " 个", 1)
      ]),
      _: 1
    }, 8, ["info", "modelValue"]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_el_button, {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('cancel'))),
        size: "small"
      }, {
        default: _withCtx(() => [
          _createTextVNode("取消")
        ]),
        _: 1
      }),
      _createVNode(_component_el_button, {
        type: "primary",
        disabled: _ctx.modelValue.length == 0,
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('confirm'))),
        size: "small"
      }, {
        default: _withCtx(() => [
          _createTextVNode("确定")
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}