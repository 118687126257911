import Edit from './edit.vue'
import Show from './show.vue'
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {
          style: {
            backgroundColor: ''
          },
          gap: 15,
          content: ''
        },
        render: "RichText",
      }
    },
    name: "富文本",
    icon: "iconfuwenben",
  }
}