
import { Watch, Component, Vue, toNative, mixins } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
import GroupSelect from "./com/group-select.vue";
@Component({
  components: { GroupSelect },
})
export default class extends mixins(ListViewVue) {
  listType = "router";

  tableChange(type) {
    let data = { ...this.condition };
    data.type = type;
    this.find(data);
  }

  operatGoods(id?) {
    if (id) {
      return this.$goto({
        path: "/goods/goods/edit",
        query: {
          id,
        },
      });
    }
    return this.$goto({
      path: "/goods/goods/create",
    });
  }
  async getList() {
    return this.$get("/manager/goods/list", {
      ...this.mesureData,
      categoryId: this.mesureData.categoryId instanceof Array ? this.mesureData.categoryId : [this.mesureData.categoryId]
    });
  }
  resetFilter() {
    this.find({ ...this.getCondition(), type: this.condition.type });
  }
  async deleteRow(id) {
    let { error, message } = await this.$post("/manager/goods/delete", { id });
    if (error) return this.$error(message);
    this.$success("删除成功");
    this.refresh();
  }
  cloneGoods(id) {
    this.$goto({
      path: "/goods/goods/create",
      query: {
        type: "clone",
        id,
      },
    });
  }
}

