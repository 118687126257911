import Edit from './edit.vue'
import Show from './show.vue'
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: { height: 15 },
        render: "Gap",
      }
    },
    name: "辅助分割",
    icon: "iconfengexian",
  }
}