import Edit from './edit.vue'
import Show from './show.vue'
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {
          background: "https://file.cnzhizhi.com/common/images/member.png"
        },
        render: "UserCenter",
      }
    },
    name: "用户中心",
    icon: "",
  }
}