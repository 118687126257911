import Edit from './edit.vue'
import Show from './show.vue'
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {
          content: "",
          listMode: 'small',
          order: {
            order1: "0",
            order2: "0"
          },
          showBuyBtn: 1,
          showPrice: 1,
          showTitle: 1,
          btnStyle: 0
        },
        render: "Category",
      }
    },
    name: "商品组",
    icon: "",
  }
}