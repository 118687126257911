<template>
  <div class="ui-container">
    <slot />
  </div>
</template>

<style scoped lang="scss" >
.ui-container {
  padding: 20px ;
  background: rgb(248, 248, 248);
}
</style>