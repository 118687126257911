import Edit from './edit.vue'
import Show from './show.vue'
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {},
        render: "UserMenu",
      }
    },
    name: "个人中心菜单",
    icon: "",
  }
}