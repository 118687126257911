import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "category-name-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.modelValue)
          ? (_openBlock(), _createBlock(_component_el_tag, {
              key: 0,
              type: "warning"
            }, {
              default: _withCtx(() => [
                _createTextVNode("请选择商品来源")
              ]),
              _: 1
            }))
          : (!_ctx.source)
            ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 1,
                type: "danger"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("分类已删除")
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_el_tag, { key: 2 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.source.name), 1)
                ]),
                _: 1
              }))
      ]))
    : _createCommentVNode("", true)
}