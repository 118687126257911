
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import ListView from "./com/list-view.vue";
import OperatCategory from "./dialog/operat-category.vue";

@Component({
  components: {
    ListView
  }
})
export default class extends Vue {
  @Prop()
  info: any;

  get realType() {
    if (this.info.type == 2) return 2;
    return 1;
  }
  get multiple() {
    return this.info.multiple;
  }
  get initList() {
    if (!this.keyword) return this.categoryList;
    return this.categoryList.filter((item) => {
      return item.name.indexOf(this.keyword) != -1;
    });
  }

  keyword = ""
  categoryId = ""
  list = []
  categoryList = []

  async deleteCategory(id) {
    await this.$confirm("提示", {
      message: "是否删除分类",
    });
    let { error, message } = await this.$post("/manager/document/delete-category", {
      id,
    });
    if (error) return this.$error(message);
    this.categoryId = "";
    this.getList();
  }
  async addGroup() {
    await this.$dialog({
      title: '添加分组',
      content() {
        return <OperatCategory />
      }
    }, 'category')
    this.getList();
  }
  closeEvent() {
    this.$emit("reject", { msg: "未选择文件" });
  }
  confirm() {
    if (this.list.length == 0) {
      return;
    }
    if (this.multiple) {
      return this.$emit("resolve", this.list);
    }
    this.$emit("resolve", this.list[0]);
  }
  async getList() {
    let { error, data } = await this.$get("/manager/document/category-list", {
      pageSize: -1,
    });
    if (error) return
    this.categoryList = data.list
  }

  created() {
    this.getList()
  }
}

