export function Throttle(time = 3000) {
  return function (target, key, descriptor) {
    const oldFunction = descriptor.value;
    let isLock = false;
    descriptor.value = function () {
      if (isLock) { return; }
      isLock = true;
      oldFunction.apply(this, arguments);
      setTimeout(() => {
        isLock = false;
      }, time);
    }
    return descriptor;
  }
}

export function Debounce(wait = 1000) {
  return function (target, key, descriptor) {
    const oldFunction = descriptor.value;
    let timer = null;
    descriptor.value = function () {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        Promise.resolve(oldFunction.apply(this, arguments)).then(function () {
          timer = null;
        });
      }, wait);
    }
    return descriptor;
  }
}

/**
 * 锁修饰符
 */
export function LockDec(propsKey: string) {
  return function (target, key, descriptor) {
    const oldFunction = descriptor.value;
    descriptor.value = function () {
      if (this[propsKey]) {
        throw '加载中'
      }
      this[propsKey] = true;
      let res = oldFunction.apply(this, arguments)
      Promise.resolve(res)
        .then(() => {
          this[propsKey] = false;
        })
        .catch(() => {
          this[propsKey] = false;
        })
      return res;
    }
    return descriptor
  }
}



/**
 * 验证修饰符
 */
export function ValidateDec(propsKey: string, option = { showTips: false }) {
  option = { showTips: false, ...option, }
  return function (target, key, descriptor) {
    const oldFunction = descriptor.value;
    descriptor.value = function () {
      let resultPipe = (res) => {
        return Promise.resolve(res)
          .then(() => {
            return oldFunction.apply(this, arguments).catch(() => { })
          }).catch((e) => {
            if (option.showTips) {
              import("@/plugins/utils/index").then(({ utils }) => {
                utils.$error(e[0])
              })
            }
            throw e;
          })
      }
      if (this.$refs && this.$refs[propsKey] && this.$refs[propsKey].validate) {
        return resultPipe(this.$refs[propsKey].validate())
      } else if (this[propsKey]?.validate) {
        return resultPipe(this[propsKey]?.validate());
      } else if (typeof (this[propsKey]) == 'function') {
        return resultPipe(this[propsKey]())
      }
      return oldFunction.apply(this, arguments)
    }
    return descriptor
  }
}