import Edit from './edit.vue'
import Show from './show.vue'
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {
          title: "搜索商品",
          wrapStyle:{
            backgroundColor: '#F9F9F9'
          },
          borderRadius: 'normal',
          style: {
            height: 28,
            backgroundColor: '#fff',
            color: '#969799',
          }
        },
        render: "Search",
      }
    },
    name: "搜索",
    icon: "iconsearch",
  }
}