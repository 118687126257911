import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2aa7a188"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rule-item-wrap" }
const _hoisted_2 = { class: "rule-item-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_close_btn = _resolveComponent("ui-close-btn")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_ui_show_area = _resolveComponent("ui-show-area")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.list.length > 1)
      ? (_openBlock(), _createBlock(_component_ui_close_btn, {
          key: 0,
          style: {"visibility":"visible"},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('destory')))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_el_form_item, {
      required: true,
      label: "包邮区域"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createVNode(_component_el_link, {
              type: "primary",
              onClick: _ctx.chooseArea,
              underline: false
            }, {
              default: _withCtx(() => [
                _createTextVNode("编辑包邮区域")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_ui_show_area, {
              modelValue: _ctx.modelValue.area
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, {
      required: true,
      label: "包邮规则"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(" 满"),
          (_ctx.modelValue.type == 0)
            ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                style: {"width":"100px","margin":"0 10px"},
                modelValue: _ctx.modelValue.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.value) = $event))
              }, null, 8, ["modelValue"]))
            : (_openBlock(), _createBlock(_component_el_input, {
                key: 1,
                style: {"width":"100px","margin":"0 10px"},
                modelValue: _ctx.modelValue.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.value) = $event))
              }, null, 8, ["modelValue"])),
          _createVNode(_component_el_select, {
            modelValue: _ctx.modelValue.type,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.type) = $event)),
            style: {"width":"80px","margin":"0 10px 0 0"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                value: "0",
                label: "元"
              }),
              _createVNode(_component_el_option, {
                value: "1",
                label: "件"
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createTextVNode("免运费 ")
        ])
      ]),
      _: 1
    })
  ]))
}