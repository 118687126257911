
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;

  list = [
    { name: "样式1", value: 0 },
    { name: "样式2", value: 1 },
    { name: "样式3", value: 2 },
  ]
  get count() {
    switch (this.modelValue.listMode) {
      case "minix":
        return 5;
      case "big":
      case "list":
        return 3;
    }
    return 4;
  }
  get title() {
    if (this.modelValue.listMode == "big") return "这里显示商品名字，最多显示一行";
    return "这里显示商品名字，最多显示二行";
  }
}
