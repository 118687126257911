
import InstanceBox from './index.vue';
import { dialog } from '@/plugins/dialog/index'

export default function (data) {
  data = { title: '请选择', disabledList: [], selectList: [], ...data };
  return dialog({
    title: data.title,
    props: {
      width: '700px',
    },
    content() {
      return <InstanceBox selectList={data.selectList} disabledList={data.disabledList}></InstanceBox>
    }
  })
}