import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_upload_image_list = _resolveComponent("ui-upload-image-list")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "店铺LOGO" }, {
      default: _withCtx(() => [
        _createVNode(_component_ui_upload_image_list, {
          modelValue: _ctx.sizeForm.logo,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sizeForm.logo) = $event)),
          tips: "更换LOGO",
          class: "setting-upload-avatar-box"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "店铺名字" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.sizeForm.name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sizeForm.name) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "简介" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          type: "textarea",
          modelValue: _ctx.sizeForm.description,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sizeForm.description) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "表单字段" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          onClick: _ctx.setForm,
          type: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("设置")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, {
      size: "small",
      style: {"margin":"0"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          onClick: _ctx.submit,
          type: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("应用修改")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ]))
}