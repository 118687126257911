import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70a14c42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-container" }
const _hoisted_2 = { class: "view-group-box" }
const _hoisted_3 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_4 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_5 = {
  key: 0,
  style: {"padding-left":"25px"}
}
const _hoisted_6 = { style: {"display":"flex","align-items":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_ui_layout1 = _resolveComponent("ui-layout1")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_ui_layout2 = _resolveComponent("ui-layout2")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_radio = _resolveComponent("el-radio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_layout1, {
        title: "第一优先级",
        class: "view-item-box"
      }, {
        content: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.modelValue.order.order1,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.order.order1) = $event)),
            placeholder: "请选择第一优先级",
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "序号越大越靠前",
                value: "0"
              }),
              _createVNode(_component_el_option, {
                label: "最热的排在前面",
                value: "1"
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ui_layout1, {
        title: "第二优先级",
        class: "view-item-box"
      }, {
        content: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.modelValue.order.order2,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.order.order2) = $event)),
            placeholder: "请选择第二优先级",
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "自动排序",
                value: "0"
              }),
              _createVNode(_component_el_option, {
                label: "创建时间越晚越靠前",
                value: "1"
              }),
              _createVNode(_component_el_option, {
                label: "创建时间越早越靠前",
                value: "2"
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ui_layout2, { title: "列表样式" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            modelValue: _ctx.modelValue.listMode,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.listMode) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio_button, { label: "small" }, {
                default: _withCtx(() => [
                  _createTextVNode("小图")
                ]),
                _: 1
              }),
              _createVNode(_component_el_radio_button, { label: "big" }, {
                default: _withCtx(() => [
                  _createTextVNode("大图")
                ]),
                _: 1
              }),
              _createVNode(_component_el_radio_button, { label: "minix" }, {
                default: _withCtx(() => [
                  _createTextVNode("一大两小")
                ]),
                _: 1
              }),
              _createVNode(_component_el_radio_button, { label: "list" }, {
                default: _withCtx(() => [
                  _createTextVNode("列表")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_divider),
      _createVNode(_component_ui_layout1, { class: "view-item-box" }, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_checkbox, {
              modelValue: _ctx.modelValue.showTitle,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.showTitle) = $event)),
              "true-label": 1,
              "false-label": 0
            }, {
              default: _withCtx(() => [
                _createTextVNode("显示标题")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ui_layout1, { class: "view-item-box" }, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_checkbox, {
              modelValue: _ctx.modelValue.showBuyBtn,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelValue.showBuyBtn) = $event)),
              "true-label": 1,
              "false-label": 0
            }, {
              default: _withCtx(() => [
                _createTextVNode("显示购买按钮")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        content: _withCtx(() => [
          (_ctx.modelValue.showBuyBtn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
                  return (_openBlock(), _createBlock(_component_el_radio, {
                    modelValue: _ctx.modelValue.btnStyle,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modelValue.btnStyle) = $event)),
                    label: item.value,
                    key: i
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "label"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ui_layout1, { class: "view-item-box" }, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_checkbox, {
              modelValue: _ctx.modelValue.showPrice,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modelValue.showPrice) = $event)),
              "true-label": 1,
              "false-label": 0
            }, {
              default: _withCtx(() => [
                _createTextVNode("显示价格")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}