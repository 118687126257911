
import { LockDec, ValidateDec } from '@/decorators';
import { Component, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, State } from 'vuex-facing-decorator';

@Component({})
export default class extends Vue {
  validateTime() {
    if (!this.formData.icp_subject.principal_info.certificate_validity_date_start) {
      throw '请选择开始时间'
    }
    if (!this.formData.icp_subject.principal_info.certificate_validity_date_end) {
      throw '请选择结束时间'
    }
    return true;
  }

  principalIsSame = true;
  uploadLoading = false;
  get disabledSubmit() {
    if (!this.remoteInfo) return false;
    if (this.remoteInfo.type == 2) return false;
    if (this.remoteInfo.auditType == 2) return false;
    return false
  }
  showData: Record<string, string> = {}

  @Watch('formData.icp_subject.base_info.type')
  typeChanges() {
    this.formData.icp_subject.organize_info.certificate_type = this.certificateList[0].type
  }

  async withdrawAudit() {
    await this.$postNotify('/manager/icp/withdraw-filing')
  }

  ruleComment() {
    if (this.formData.icp_applets.base_info.comment.length < 20) throw '备注不能少于20字'
    if (this.formData.icp_applets.base_info.comment.length > 200) throw '备注不能多余200字'
    return true
  }

  get postData() {
    if (this.principalIsSame) {
      let data = JSON.parse(JSON.stringify(this.formData));
      data.icp_applets.principal_info = data.icp_subject.principal_info
      return data
    }
    return this.formData
  }

  valiteRealAddress(address) {
    if (address.indexOf('省') > 0 || address.indexOf('市') > 0) {
      throw '地址请勿填写省市区'
    }
    return true
  }
  submitLoading = false;

  @LockDec("submitLoading")
  @ValidateDec("form")
  async submit() {
    await this.$postNotify("/manager/icp/apply-icp-filing", this.postData);
    this.getData()
    this.getDocData();
  }

  @LockDec("uploadLoading")
  async uploadFiles(fieldKey: string, type: string | number) {
    let files = await this.$chooseFile();
    let fd = new FormData()
    fd.append('file', files[0])
    fd.append("icpOrderField", fieldKey)
    fd.append("certificateType", String(type))
    fd.append("type", 'image')
    let { error, data, message } = await this.$upload("/manager/icp/upload-icp-media", fd);
    if (error) {
      return this.$error(message);
    }
    let formdata = this.formData;
    let arr = fieldKey.split('.');
    arr.map((key, index) => {
      if (index == arr.length - 1) {
        formdata[key] = data.mediaId
      } else {
        formdata = formdata[key]
      }
    })
    this.showData[data.mediaId] = data.path;
  }

  @LockDec("uploadLoading")
  async uploadPromiseBook(fieldKey: string) {
    let files = await this.$chooseFile();
    let fd = new FormData()
    fd.append('file', files[0])
    fd.append("icpOrderField", fieldKey)
    fd.append("type", 'image')
    let { error, data, message } = await this.$upload("/manager/icp/upload-icp-media", fd);
    if (error) {
      return this.$error(message);
    }
    let formdata = this.formData
    let arr = fieldKey.split('.');
    arr.map((key, index) => {
      if (index == arr.length - 1) {
        formdata[key][0] = data.mediaId
      } else {
        formdata = formdata[key]
      }
    })
    this.showData[data.mediaId] = data.path;
  }


  get serviceContentType() {
    let childrens: Record<number, any> = {}
    let getChildres = (list, parentId) => {
      if (!list || list.length == 0) return null;
      let res = [];
      list.map((item) => {
        if (item.parent_type == parentId) {
          res.push({ ...item, value: item.type, label: item.name, children: getChildres(list, item.type) })
        }
        if (!item.children || item.children.length == 0) {
          childrens[item.type] = item
        }
      })
      return res
    }
    return { list: getChildres(this.icpService, 0), childrens }
  }

  @Watch('serviceContentValue')
  serviceContentValueHanlder() {
    if (this.serviceContentValue.length > 5) {
      this.$alert('最多可选5个行业')
      this.serviceContentValue = this.serviceContentValue.slice(0, 5)
    }
    let res = []
    this.serviceContentValue.map((item) => {
      res.push(item[1])
    })
    this.formData.icp_applets.base_info.service_content_types = res
  }

  serviceContentValue = []

  get nrlxDetails() {
    return this.formData.icp_applets.base_info.nrlx_details.map((item) => item.type)
  }

  set nrlxDetails(v) {
    let res = []
    let hashRecord: Record<string, any> = {}
    this.formData.icp_applets.base_info.nrlx_details.map((item) => {
      hashRecord[item.type] = item;
    })
    v.map((item) => {
      if (hashRecord[item]) {
        res.push(hashRecord[item])
      } else {
        res.push({ type: item })
      }
    })
    let index = v.indexOf(24);
    if (index == 0) {
      res.shift()
    } else if (index != -1) {
      res = [res[index]]
    }
    this.formData.icp_applets.base_info.nrlx_details = res
  }

  @State((state) => state.store.store)
  store;

  @State((state) => state.store.icpDistrict)
  icpDistrict: any[]

  @State((state) => state.store.icpCertificate)
  icpCertificate: any[]

  @State((state) => state.store.icpSubject)
  icpSubject: any[]

  @State((state) => state.store.icpNrlx)
  icpNrlx: any[]

  @State((state) => state.store.icpService)
  icpService: any[]


  /**可选证件列表 */
  get certificateList() {
    if (!this.formData.icp_subject.base_info.type) return []
    return this.icpCertificate.filter((item) => {
      return item.subject_type == this.formData.icp_subject.base_info.type
    })
  }

  /**个人可选证件列表 */
  get personalList() {
    return this.icpCertificate.filter((item) => {
      return item.subject_type == 5
    })
  }



  @Action("store/getStore")
  getStore

  @Action("store/getIcpCondition")
  getIcpCondition


  get areaOptions() {
    let getChildres = (list) => {
      if (!list || list.length == 0) return null;
      return list.map((item) => {
        return { ...item, value: item.code, label: item.name, children: getChildres(item.sub_list) }
      })
    }
    return getChildres(this.icpDistrict)
  }

  get areaCode() {
    if (this.formData.icp_subject.base_info.province) {
      return [this.formData.icp_subject.base_info.province, this.formData.icp_subject.base_info.city, this.formData.icp_subject.base_info.district]
    }
    return []
  }
  set areaCode(v) {
    this.formData.icp_subject.base_info.province = v[0]
    this.formData.icp_subject.base_info.city = v[1]
    this.formData.icp_subject.base_info.district = v[2]
  }

  formData = {
    icp_subject: {
      base_info: {
        type: 4,
        name: '',
        province: '',
        city: '',
        district: '',
        address: '',
        comment: ''
      },
      organize_info: {
        certificate_type: '',
        certificate_number: '',
        certificate_address: '',
        certificate_photo: '' // 这个还没写 
      },
      principal_info: {
        name: "",
        mobile: "", // 个人联系方式
        email: "",
        emergency_contact: "", // 紧急联系方式
        certificate_type: 2, // 身份证
        certificate_number: "",
        certificate_validity_date_start: "",
        certificate_validity_date_end: "",
        certificate_photo_front: "",
        certificate_photo_back: ""
      }
    },
    icp_applets: {
      base_info: {
        service_content_types: [],
        nrlx_details: [
          {
            type: 24 // 以上都不涉及
          }
        ],
        comment: ""
      },
      principal_info: {
        name: "",
        mobile: "",
        email: "",
        emergency_contact: "",
        certificate_type: 2, // 身份证
        certificate_number: "",
        certificate_validity_date_start: "",
        certificate_validity_date_end: "",
        certificate_photo_front: "",
        certificate_photo_back: ""
      }
    },
    icp_materials: {
      commitment_letter: []
    }
  }

  remoteInfo = null;

  sizeForm: any = {}

  async created() {
    this.getDocData()
    await this.getIcpCondition();
    this.getData()
  }

  async getData() {
    let { error, data } = await this.$get('/manager/icp/detail')
    if (error) return;
    this.remoteInfo = data;
    this.formData = Object.assign(this.formData, data.content);
    let list = []
    this.formData.icp_applets.base_info.service_content_types.map((item) => {
      if (this.serviceContentType.childrens[item]) {
        list.push([this.serviceContentType.childrens[item].parent_type, this.serviceContentType.childrens[item].type])
      }
    })
    this.serviceContentValue.push(...list)
  }

  async getDocData() {
    let { error, data } = await this.$get("/manager/icp/get-doc-list", {
    })
    if (error) return;
    data.list.map((item) => {
      this.showData[item.mediaId] = item.path;
    })
  }


}
