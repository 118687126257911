
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
interface Props {
  parent?: any
  row?: any
}
@Component({
  components: {},
})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  parent: any

  @Prop()
  row: any
  ruleForm = {
    name: "",
    limit: "",
  }

  created() {
    if (this.row) {
      this.ruleForm.name = this.row.name;
      this.ruleForm.limit = this.row.limit;
    }
  }
  async confirmData() {
    await this.$post("/manager/super/common/operat-role-item", {
      ...this.ruleForm,
      id: this.row ? this.row.id : undefined,
      parentId: this.parent ? this.parent.id : undefined,
    });
    this.$emit('resolve', []);
  }
  closeDialog() {
    this.$emit('reject', { msg: "已取消" });
  }
}
