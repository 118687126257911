import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_dialog_title = _resolveComponent("ui-dialog-title")!
  const _component_ui_dialog_container = _resolveComponent("ui-dialog-container")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, _mergeProps({
    "model-value": _ctx.showDialog,
    onClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rejectEvent({ msg: '关闭' }))),
    "show-close": false,
    class: "common-dialog-box",
    "close-on-press-escape": false
  }, _ctx.props), {
    default: _withCtx(() => [
      (_ctx.title)
        ? (_openBlock(), _createBlock(_component_ui_dialog_container, {
            key: 0,
            title: _ctx.title
          }, {
            header: _withCtx(() => [
              _createVNode(_component_ui_dialog_title, {
                onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.rejectEvent({ msg: '关闭' }))),
                title: _ctx.title
              }, null, 8, ["title"])
            ]),
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
                onResolve: _ctx.resolveEvent,
                onReject: _ctx.rejectEvent
              }, null, 40, ["onResolve", "onReject"]))
            ]),
            _: 1
          }, 8, ["title"]))
        : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
            key: 1,
            onResolve: _ctx.resolveEvent,
            onReject: _ctx.rejectEvent
          }, null, 40, ["onResolve", "onReject"]))
    ]),
    _: 1
  }, 16, ["model-value"]))
}