
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({
    default() {
      return ''
    }
  })
  size: string;
}
