import { createStore } from 'vuex'
import UserModule from './modules/user'
import StoreModule from './modules/store'
import AddressModule from './modules/address'

export default createStore({
  modules: {
    user: UserModule,
    store: StoreModule,
    address: AddressModule
  }
})