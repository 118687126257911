import Edit from './edit.vue'
import Show from './show.vue'
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {
          content: '',
          icon: '',
          style: {
            color: 'rgb(100, 101, 102)',
            backgroundColor: 'rgb(255, 248, 233)'
          }
        },
        render: "Notice",
      }
    },
    name: "公告",
    icon: "iconRectangleCopy",
  }
}