
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop()
  title: any;

  @Prop()
  destory: any;

  showTitle2 = "";
  get show() {
    if (this.showTitle2) return this.showTitle2;
    return this.title;
  }
  setItem(v) {
    this.showTitle2 = v;
    return;
  }
}
