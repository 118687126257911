import { App } from 'vue';
import { createApp } from 'vue-demi'
import DialogInstance from './index.vue';
import { DrawerProps } from 'element-plus'

let dialogType: {
  content: () => any,
  props?: Partial<DrawerProps>
} = {
  content: () => undefined,
}

type DrawerType = Partial<typeof dialogType>;
let SingleInstance: Record<string, ReturnType<typeof createApp>> = {}
function drawer(value: DrawerType = dialogType, key = 'default'): Promise<any[]> {
  if (SingleInstance[key]) return Promise.resolve([{ msg: '已唤起' }]);
  return new Promise(async function (r) {
    const rootEl = document.createElement('div')
    document.body.appendChild(rootEl)
    value = Object.assign({ content: '' }, value, {
      resolve(arg) {
        r(arg)
        SingleInstance[key].unmount()
        delete SingleInstance[key];
        rootEl.remove()
      }
    });
    const plugins = await import('@/plugins');
    const vueInstance = createApp(DialogInstance, value)
    SingleInstance[key] = vueInstance;
    await plugins.plugins(vueInstance)
    vueInstance.mount(rootEl);
  })
}

function install(app: App) {
  app.config.globalProperties.$drawer = drawer;
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $drawer: typeof drawer
  }
}

export default {
  install: install
}