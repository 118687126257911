import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75dace26"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "right-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "item-container",
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.modelValue.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.modelValue.icon
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ], 4))
}