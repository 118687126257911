import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66e7eac6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { style: {"padding":"10px 15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_layout1 = _resolveComponent("ui-layout1")!
  const _component_ui_edit_share = _resolveComponent("ui-edit-share")!
  const _component_ui_custom = _resolveComponent("ui-custom")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_ui_custom, {
          key: 0,
          showLeft: _ctx.showLeft,
          pageName: _ctx.source.title,
          modelValue: _ctx.list,
          fixedValue: _ctx.fixedList,
          selected: 0,
          onSubmit: _ctx.operatPage
        }, _createSlots({ _: 2 }, [
          (_ctx.type != 'menu')
            ? {
                name: "page",
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_ui_layout1, {
                      title: "页面名称",
                      class: "view-item-box"
                    }, {
                      content: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.source.title,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.source.title) = $event)),
                          placeholder: "请输入页面名称"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ui_edit_share, { data: _ctx.source }, null, 8, ["data"])
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["showLeft", "pageName", "modelValue", "fixedValue", "onSubmit"]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}