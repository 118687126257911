
import defaultData from "./index";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any

  get positionExt() {
    if (this.modelValue.style.textAlign == "left") return "左对齐";
    return "居中对齐";
  }
  defaultData: any = {}

  created(): void {
    this.defaultData = defaultData.data.data()
  }


  foramtSize(val) {
    if (val == "12px") return "小(12号)";
    if (val == "14px") return "中(14号)";
    return "大(16号)";
  }
  foramtWeight(val) {
    if (val == "bold") return "加粗体";
    return "常规体";
  }
}


