
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
})
export default class extends Vue {
  @Prop()
  modelValue: any;

  @Prop()
  tips: any;

  @Prop({
    default() {
      return true;
    }
  })
  showClose: boolean

  removeData() {
    this.$emit("update:modelValue", "");
  }
  removeIndex(index) {
    this.modelValue.splice(index, 1);
  }
  async chooseImage() {
    let data = await this.$chooseIcon({
      multiple: false,
    });
    this.$emit("update:modelValue", data.url);
  }
}
