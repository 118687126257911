
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
interface Props {
  options: any
}
@Component({
})
export default class extends TSX<Props>()(ListViewVue) implements Props {

  @Prop()
  options: any;

  get checkIndeter() {
    if (!this.source.list) return false;
    let checked = 0,
      unchecked = 0;
    for (let i = 0; i < this.source.list.length; i++) {
      let item = this.source.list[i];
      if (!this.checkList[item.id]) {
        checked++;
      } else {
        unchecked++;
      }
    }
    return checked > 0 && unchecked > 0;
  }

  page = { pageSize: 5, page: 1 }
  get checkAll() {
    if (!this.source.list || this.source.list.length == 0) return false;
    for (let i = 0; i < this.source.list.length; i++) {
      let item = this.source.list[i];
      if (!this.checkList[item.id]) {
        return false;
      }
    }
    return true;
  }

  get checkList() {
    let res = {};
    this.list.map((item) => {
      res[item.id] = true;
    });
    return res;
  }

  list = []

  created() {
    this.list = this.options.list || [];
  }

  createGoods() {
    this.$goto(
      {
        path: "/goods/goods/create",
      },
      "_blank"
    );
  }
  toggleAll(bool) {
    if (bool) {
      this.source.list.map((item) => {
        if (!this.checkList[item.id]) {
          this.list.push(item);
        }
      });
      return;
    }
    this.source.list.map((row) => {
      if (this.checkList[row.id]) {
        let index = this.list.findIndex((item) => {
          return item.id == row.id;
        });
        this.list.splice(index, 1);
      }
    });
  }
  getList() {
    return this.$get("/manager/goods/list", {
      ...this.mesureData,
    });
  }
  toggle(row, value) {
    if (this.options.multiple) {
      if (value) {
        return this.list.push(row);
      }
      let index = this.list.findIndex((item) => {
        return item.id == row.id;
      });
      this.list.splice(index, 1);
      return;
    }
    this.list = [row];
  }
  closeEvent() {
    this.$emit('reject', { msg: "未选择商品" });
  }
  choose() {
    if (this.list.length == 0) {
      return;
    }
    this.$emit('resolve', this.list);
  }
}
