
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
import Tester from './com/tester/index.vue'
@Component({})
export default class extends ListViewVue {
  tablist = [
    {
      name: "所有店铺",
      value: "",
    }
  ]
  activeName = ""

  async getList() {
    return this.$get("/manager/super/store/list", { ...this.mesureData });
  }
  async viewCode(row) {
    await this.$dialog({
      title: "查看体验码",
      content() {
        return <Tester id={row.id} />
      }
    });
  }
  async submitAudit(row) {
    await this.$postError("/manager/super/store/submit-audit", {
      id: row.id,
    });
    this.$success("提审成功");
  }
  async withdrawAudit(row) {
    await this.$postError("/manager/super/store/withdraw-audit", {
      id: row.id,
    });

    this.$success("撤回成功");
  }
  async release(row) {
    await this.$postNotify("/manager/super/store/release", {
      id: row.id,
    });
  }
}
