
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
@Component({})
export default class extends ListViewVue {
  listType = "router";

  tablist = [
    {
      name: "全部用户",
      value: "",
    }
  ]

  activeName = ""

  getGender(v) {
    switch (v) {
      case 1:
        return '男'
      case 2:
        return '女'
    }
    return '未知'
  }
  getCondition() {
    return {
      gender: "",
    }
  }

  gotoSearch(id?) {
    this.$goto(`/order/index?userId=${id}&type=0`);
  }

  resetFilter() {
    this.find(this.getCondition())
  }

  async setLevel(row) {
    let levelData = await this.$chooseVipGroup({
      multiple: false,
    });
    let { error, message } = await this.$post("/manager/level/set-user-level", {
      levelId: levelData.id,
      id: row.id,
    });
    if (error) return this.$error(message);
    this.$success("操作成功");
    this.refresh();
  }

  async getList() {
    return this.$get("/manager/customer/list", { ...this.mesureData });
  }
}
