
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import Update from "./com/update.vue";

@Component({})
export default class extends Vue {
  tablist = [
    {
      name: "菜单列表",
      value: "",
    }
  ]
  activeName = ""
  list = []
  created() {
    this.getList()
  }
  async getList() {
    let { error, data } = await this.$get("/manager/super/common/menu-item-list");
    if (error) return;
    this.list = data;
  }
  async add(row?) {
    let [err, data] = await this.$dialog({
      title: "添加分类",
      content() {
        return <Update parent={row} />
      }
    });
    if (err) return;
    this.getList();
  }
  async edit(row?) {
    let [err, data] = await this.$dialog({
      title: "编辑分类",
      content() {
        return <Update row={row} parent={row.parent} />
      }
    });
    if (err) return;
    this.getList();
  }
  async deleteRow(row) {
    let { error } = await this.$post("/manager/super/common/menu-item-delete", {
      id: row.id,
    });
    if (error) return;
    this.getList();
  }
}
