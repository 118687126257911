import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_ui_page = _resolveComponent("ui-page")!
  const _component_ui_card = _resolveComponent("ui-card")!
  const _component_ui_container = _resolveComponent("ui-container")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_ui_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_ui_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_tabs, {
            modelValue: _ctx.activeName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
            type: "card",
            class: "tabbar-box"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tablist, (item, index) => {
                return (_openBlock(), _createBlock(_component_el_tab_pane, {
                  label: item.name,
                  name: item.value,
                  key: index
                }, null, 8, ["label", "name"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", null, [
            (_ctx.activeName == 'draft_list')
              ? _withDirectives((_openBlock(), _createBlock(_component_ui_table, {
                  key: 0,
                  data: _ctx.source.draft_list
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      label: "小程序名称",
                      width: "100",
                      prop: "source_miniprogram"
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "版本",
                      width: "100",
                      prop: "user_version"
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "开发者",
                      width: "100",
                      prop: "developer"
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "描述",
                      prop: "user_desc"
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "创建时间",
                      width: "170"
                    }, {
                      default: _withCtx(({ row }) => [
                        _createTextVNode(_toDisplayString(_ctx.$format(row.create_time * 1000)), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "操作",
                      align: "right",
                      width: "200"
                    }, {
                      default: _withCtx(({ row }) => [
                        _createVNode(_component_ui_button, {
                          onClick: ($event: any) => (_ctx.addTemplate(row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("添加到模板库")
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["data"])), [
                  [_directive_loading, _ctx.source.loading]
                ])
              : _withDirectives((_openBlock(), _createBlock(_component_ui_table, {
                  data: _ctx.source.template_list,
                  key: 2
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      label: "模板id",
                      prop: "template_id"
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "小程序名称",
                      width: "100",
                      prop: "source_miniprogram"
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "版本",
                      width: "100",
                      prop: "user_version"
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "开发者",
                      width: "100",
                      prop: "developer"
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "描述",
                      prop: "user_desc"
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "创建时间",
                      width: "170",
                      prop: "ctm"
                    }, {
                      default: _withCtx(({ row }) => [
                        _createTextVNode(_toDisplayString(_ctx.$format(row.create_time * 1000)), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "操作",
                      align: "right",
                      width: "150"
                    }, {
                      default: _withCtx(({ row }) => [
                        _createVNode(_component_el_popconfirm, {
                          title: "是否删除该记录",
                          onConfirm: ($event: any) => (_ctx.deleteTemplate(row)),
                          style: {"margin-left":"10px"}
                        }, {
                          reference: _withCtx(() => [
                            _createVNode(_component_ui_button, { type: "danger" }, {
                              default: _withCtx(() => [
                                _createTextVNode("删除")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onConfirm"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["data"])), [
                  [_directive_loading, _ctx.source.loading]
                ]),
            _createVNode(_component_ui_page, {
              modelValue: _ctx.page.page,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page.page) = $event)),
              info: _ctx.pageInfo
            }, null, 8, ["modelValue", "info"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}