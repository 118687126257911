import dayjs from 'dayjs'
import { App } from 'vue';

declare global {
  type exDate = typeof dateList
  interface Date extends exDate {
  }
}

const dateList = {
  format(fmt = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs(this).format(fmt)
  }
}

for (let key in dateList) {
  Date.prototype[key] = dateList[key];
}


const output = {
  $format(date: number | string | Date, fmt = 'YYYY-MM-DD HH:mm') {
    return dayjs(date).format(fmt)
  },
  $toDate(date) {
    return dayjs(date).toDate()
  }
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $format: typeof output.$format,
    $toDate: typeof output.$toDate
  }
}
export default {
  install(app: App) {
    app.config.globalProperties.$format = output.$format
    app.config.globalProperties.$toDate = output.$toDate
  }
}