
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  @Prop({
    default() {
      return '内容提示'
    }
  })
  modelValue: any
}
