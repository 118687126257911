
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import SelectCategory from './com/select-category/index.vue'
import SelectLevel from './com/select-level/index.vue'
import { LockDec, ValidateDec } from '@/decorators'
@Component({
  components: {
    SelectCategory,
    SelectLevel
  }
})
export default class extends Vue {
  get disabledPhone() {
    return !!this.$route.query.id
  }
  postData = { phone: '', name: '', prevLevelId: null, categoryId: null }

  submitLoading = false;
  loading = false;

  async getData() {
    if (!this.$route.query.id) return;
    let { data } = await this.$getError('/manager/salesman/view', { id: this.$route.query.id })
    this.postData = data;
  }

  created() {
    this.getData()
  }

  @LockDec('submitLoading')
  @ValidateDec("formBox")
  async submit() {
    await this.$postError(this.$route.query.id ? '/manager/salesman/edit' : '/manager/salesman/add', { ...this.postData, id: this.$route.query.id });
    this.$success("操作成功");
    this.$router.back();
  }
}
