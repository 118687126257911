import Vue from 'vue';
import { request } from "@/plugins/api/index"


let lastPromise;
let lastMenu;
let expressInfo: Promise<{ error?: any, message: string, data?: any }> = Promise.resolve({ error: true, message: 'notInit' })

export default {
  namespaced: true,
  state: {
    store: {},
    menu: [],
    /** icp备案地址 */
    icpDistrict: [],
    /**
     * 小程序服务内容
     */
    icpService: [],
    /**
     * 可选证件
     * */
    icpCertificate: [],
    /**
     * 公司性质
     * */
    icpSubject: [],
    /**
     * 可选前置审批
     */
    icpNrlx: [],
    express: {
      list: []
    }
  },
  actions: {
    async getIcpCondition({ commit }) {
      let list = await Promise.all([
        request.$get('/manager/icp/query-icp-service-content-types'),
        request.$get('/manager/icp/query-icp-certificate-types'),
        request.$get('/manager/icp/query-icp-district-code'),
        request.$get('/manager/icp/query-icp-nrlx-types'),
        request.$get('/manager/icp/query-icp-subject-types')
      ]);
      list.map(({ error, data }, index) => {
        if (error) return;
        switch (index) {
          case 0:
            commit("setData", { icpService: data.list });
            break;
          case 1:
            commit("setData", { icpCertificate: data.list });
            break;
          case 2:
            commit("setData", { icpDistrict: data.list });
            break;
          case 3:
            commit("setData", { icpNrlx: data.list });
            break;
          case 4:
            commit("setData", { icpSubject: data.list });
            break;
        }
      });
    },
    getLastStore(state) {
      if (lastPromise) return lastPromise;
      return state.dispatch('getStore');
    },
    getLastMenu(state) {
      if (lastMenu) return lastMenu;
      return state.dispatch('getMenu');
    },
    getStore({ commit }, post) {
      lastPromise = (async () => {
        let { error, data, message, origin } = await request.$get('/manager/store/info');
        if (error) return { error, data, message, origin };
        commit('setStore', data);
        return { error, data, message, origin }
      })();
      return lastPromise;
    },
    async getMenu({ commit }, post) {
      lastMenu = (async () => {
        let { error, data, message, origin } = await request.$get('/manager/store/limit');
        if (error) return { error, data, message, origin };
        commit('setMenu', data);
        return { error, data, message, origin }
      })();
      let { error, data, message, origin } = await lastMenu;
      if (error) {
        lastMenu = null;
        return { error, data, message, origin }
      }
      return lastMenu;
    },
    async getExpress({ commit }) {
      let { error } = await expressInfo;
      if (!error) return expressInfo
      expressInfo = (async () => {
        let { error, data, message, origin } = await request.$get('/manager/order/express-list');
        if (error) return { error, data, message, origin };;
        commit('setExpress', data)
        return { error, data, message, origin }
      })();
      return expressInfo;
    },
  },
  mutations: {
    setData(state, data) {
      for (let key in data) {
        state[key] = data[key]
      }
    },
    setStore(state, store) {
      state.store = store;
    },
    setMenu(state, menu) {
      state.menu = menu;
    },
    setExpress(state, expressList) {
      state.express = {
        list: expressList
      }
    },
  },
  getters: {}
}