
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'

@Component({})
export default class extends ListViewVue {
  tablist = [
    {
      name: "草稿箱",
      value: "draft_list",
    },
    {
      name: "草稿箱",
      value: "template_list",
    }
  ]

  activeName = "draft_list"
  @Watch('activeName')
  activeNameWatch() {
    this.find()
  }

  getList() {
    switch (this.activeName) {
      case "draft_list":
        return this.$get("/manager/super/store/draft-list", {});
    }
    return this.$get("/manager/super/store/template-list", {});
  }

  async addTemplate(row) {
    await this.$postNotify('/manager/super/store/add-template', {
      id: row.draft_id,
    });
  }
  async deleteTemplate(row) {
    await this.$postNotify('/manager/super/store/delete-template-list', {
      id: row.template_id,
    });
    this.refresh();
  }

}
