import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cf87b86"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "image-box-tips"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ui_close_btn = _resolveComponent("ui-close-btn")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["upload-images-list-box", { 'upload-image-multiple': _ctx.limit != 1 }])
  }, [
    (_ctx.limit == 1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "upload-image-button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.chooseImage && _ctx.chooseImage(...args)))
        }, [
          (_ctx.signInfo.hasData)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "image-box",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', _ctx.signInfo.url)))
              }, [
                _createElementVNode("img", {
                  src: _ctx.urlPipe(_ctx.signInfo.url)
                }, null, 8, _hoisted_1),
                (_ctx.tips)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.tips), 1))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createBlock(_component_el_icon, {
                key: 1,
                size: "28"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Plus)
                ]),
                _: 1
              })),
          (_ctx.signInfo.hasData && _ctx.showClose)
            ? (_openBlock(), _createBlock(_component_ui_close_btn, {
                key: 2,
                onClick: _withModifiers(_ctx.removeData, ["stop"])
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_draggable, {
            modelValue: _ctx.modelValue,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
            class: "upload-drag-wrap"
          }, {
            item: _withCtx(({ element, index }) => [
              (_openBlock(), _createElementBlock("div", {
                class: "upload-image-button",
                key: index,
                onClick: ($event: any) => (_ctx.$emit('click', element))
              }, [
                _createElementVNode("img", {
                  src: _ctx.urlPipe(element)
                }, null, 8, _hoisted_4),
                (_ctx.showClose)
                  ? (_openBlock(), _createBlock(_component_ui_close_btn, {
                      key: 0,
                      onClick: _withModifiers(($event: any) => (_ctx.removeIndex(index)), ["stop"])
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_3))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", {
            class: "upload-image-button",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.chooseImage && _ctx.chooseImage(...args)))
          }, [
            _createVNode(_component_el_icon, { size: "28" }, {
              default: _withCtx(() => [
                _createVNode(_component_Plus)
              ]),
              _: 1
            })
          ])
        ], 64))
  ], 2))
}