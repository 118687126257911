
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

import UpgradeSetting from './com/upgrade-setting.vue'
import { LockDec, ValidateDec } from '@/decorators';
@Component({
  components: {
    UpgradeSetting
  }
})
export default class extends Vue {
  postData = {
    list: [],
  }

  removeLevel(i) {
    this.postData.list.splice(i, 1)
  }

  addLevel() {
    let prevItem = this.postData.list[this.postData.list.length - 1];
    if (prevItem && prevItem.isSelfBuy == 0 && prevItem.isSalesman == 0 && this.postData.list.length > 1) {
      return this.$error('请完当前等级条件')
    }
    this.postData.list.push({
      name: '',
      rate: '',
      isSelfBuy: 0,
      isSalesman: 0,
    })
  }


  submitLoading = false
  loading = false

  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  }

  async getData() {
    let { data } = await this.$getError('/manager/salesman/get-level');
    this.postData = data;
  }

  @LockDec("submitLoading")
  @ValidateDec("form")
  async submit() {
    await this.$postNotify('/manager/salesman/set-level', this.postData)
  }
}
