import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5aa04ecb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "image-box"
}
const _hoisted_2 = {
  key: 1,
  class: "text-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "item-container",
    style: _normalizeStyle(_ctx.modelValue.style)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.list, (item, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (item.icon)
          ? (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "menu-item-box",
              style: _normalizeStyle(_ctx.iconColor1)
            }, [
              (item.icon)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_ui_icon, {
                      modelValue: item.icon
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              (item.text)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(item.text), 1))
                : _createCommentVNode("", true)
            ], 4))
          : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ], 4))
}