
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {

  @Prop()
  modelValue: any

  @Prop({
    default() {
      return true;
    }
  })
  filterable: boolean


  @Prop({
    default() {
      return true;
    }
  })
  multiple: boolean


  source = { list: [] }
  set initValue(v) {
    this.$emit("update:modelValue", v);
  }
  created() { this.getList() }
  get initValue() {
    if (this.modelValue instanceof Array) return this.modelValue;
    if (this.modelValue == null || this.modelValue == '') return;
    return Number(this.modelValue)
  }
  async getList() {
    let { error, data } = await this.$get("/manager/case/category-list", {
      pageSize: -1,
    });

    if (error) return;
    this.source = data;
  }
}
