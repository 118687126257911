
import EditGoodsList from "./edit-goods-list.vue";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
  components: {
    EditGoodsList,
  }
})
export default class extends Vue {

  @Prop()
  modelValue: any;

  list = [
    { name: "样式1", value: 0 },
    { name: "样式2", value: 1 },
    { name: "样式3", value: 2 },
  ]

  async addGoodsCategory() {
    let data = await this.$chooseCategory({
      multiple: false,
    });
    this.modelValue.category = {
      count: 10,
      ...this.modelValue.category,
      id: data[0].id,
      name: data[0].name,
    };
  }
  async addGoods() {
    let data = await this.$chooseGoods();
    this.modelValue.goodsIds.push(...data.map((item) => item.id));
    this.modelValue.goodsIds = [...this.modelValue.goodsIds]
  }
}


