
import { LockDec, ValidateDec } from '@/decorators';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {

  @Watch('formData.goodsType')
  formDataGoodsType() {
    if (this.loading) return;
    this.formData.goodsValue = [];
  }

  submitLoading = false
  loading = false
  formData = {
    id: undefined,
    name: "",
    type: 0,
    price: "",
    randMinPrice: "",
    randMaxPrice: "",
    discount: 0,
    discountLimit: 0,
    discountMaxPrice: 0,
    remake: "",
    count: 0,
    goodsType: 0,
    goodsValue: [],
    conditionType: 0,
    conditionValue: "",
    timeType: 0,
    timeValue: "",
    startTime: null,
    endTime: null,
    getCountType: 0,
    getCountValue: "",
    getRuleType: 0,
    getRuleValue: [],
    overlap: 1,
    content: "",
  }
  get userTimeRange() {
    if (!this.formData.startTime && !this.formData.startTime)
      return [];
    return [this.formData.startTime, this.formData.endTime];
  }

  set userTimeRange(res) {
    if (!res) {
      this.formData.startTime = null;
      this.formData.endTime = null;
      return;
    }
    let [startTime, endTime] = res
    this.formData.startTime = startTime;
    this.formData.endTime = endTime;
  }

  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  }

  async getData() {
    let id = this.$route.query.id;
    if (!id) return;
    let { error, data } = await this.$get("/manager/coupon/detail", { id });
    if (error) return;
    data.goodsValue = data.goodsValue.split(",");
    data.getRuleValue = data.getRuleValue
      ? data.getRuleValue.split(",")
      : [];
    this.formData = data;
  }

  @ValidateDec("form", { showTips: true })
  @LockDec("submitLoading")
  async submit() {
    await this.$postNotify("/manager/coupon/operat", {
      ...this.formData,
    });
    this.$router.back();
  }
}
