import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-stock-text-box", _ctx.classColor]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
  }, [
    _createTextVNode(_toDisplayString(_ctx.modelValue) + " ", 1),
    _renderSlot(_ctx.$slots, "default", { direction: _ctx.direction }, undefined, true)
  ], 2))
}