import Decimal from 'decimal.js';

declare global {
  interface String {
    toDecimal: () => Decimal
  }
}

String.prototype.toDecimal = function () {
  return new Decimal(parseFloat(this));
}
export { }; 