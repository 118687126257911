
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import ActiveityItem from "../com/activeity-item.vue";
import { LockDec, ValidateDec } from '@/decorators';
@Component({
  components: { ActiveityItem },
})
export default class extends Vue {

  loading = false
  submitLoading = false
  list: any[] = []

  created() {
    this.getData();
  }

  backEvent() {
    this.$router.back()
  }
  removeItem() {
    this.list.splice(-1)
  }
  arraySpanMethod({ row, column, rowIndex, columnIndex }) {
    if (column.property == 'operat') {
      return {
        rowspan: this.list.length,
        colspan: 1
      };
    }
  }
  addVip() {
    this.list.push({
      rate: 1,
      userVipActivityId: '',
      freeDelivery: 0,
      discount: '',
      name: '',
      score: ''
    })
  }
  async getData() {
    this.loading = true;
    let { error, data } = await this.$get("/manager/level/list", {
    });
    this.loading = false;
    if (error) return;
    this.list = data.list
  }

  @LockDec('submitLoading')
  @ValidateDec('form')
  async submit() {
    await this.$postNotify("/manager/level/save",
      {
        list: this.list
      })
  }
}
