import Edit from './edit.vue'
import Show from './show.vue'
export default {
  Show,
  Edit,
  data: {
    data() {
      return {
        data: {
          content: "",
          order: {
            order1: "0",
          },
        },
        render: "Case",
      }
    },
    name: "案例列表",
    icon: "",
  }
}