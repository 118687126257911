
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import draggable from "vuedraggable";

@Component({
  components: {
    draggable,
  }
})
export default class extends Vue {



  @Prop()
  modelValue: any;

  @Watch('modelValue')
  modelValueWatch() {
    this.getData();
  }

  get dragValue() {
    return this.modelValue
  }

  set dragValue(val) {
    this.$emit("update:modelValue", val);
  }

  source = {}
  created() {
    this.getData();
  }
  lastPromise = null;
  async getData() {
    await this.lastPromise;
    this.lastPromise = this.getRealData();
  }
  async getRealData() {
    let needLoad = {};
    this.modelValue.map((id) => {
      if (!this.source[id]) {
        needLoad[id] = true;
      }
    });
    let questArray = Object.keys(needLoad);
    if (questArray.length == 0) return;
    let { error, data } = await this.$get("/manager/goods/list", {
      ids: questArray,
      pageSize: -1,
    });
    if (error) return;
    data.list.map((item) => {
      this.source[item.id] = item;
    });
    this.$forceUpdate();
  }
  removeItem(index) {
    this.modelValue.splice(index, 1);
  }
}
