
import { ValidateDec } from '@/decorators'
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  @Prop()
  prevValue: any
  @Prop()
  modelValue: any
  @Prop()
  hideEvent: any

  localValue = {
    isSelfBuy: 0,
    isSalesman: 0,
    selfBuyAmount: "",
    salesmanyAmount: ""
  }

  validateSelfBuy() {
    if (!this.localValue.isSelfBuy) return;
    if (!this.localValue.selfBuyAmount) {
      throw '请输入自购金额';
    }
    let amount = Number(this.localValue.selfBuyAmount);
    if (isNaN(amount)) {
      throw '请输入正确的数字';
    }
    if (this.prevValue?.isSelfBuy) {
      if (amount <= this.prevValue.selfBuyAmount) {
        throw `需大于上一自购金额(${this.prevValue.selfBuyAmount})`;
      }
    }
  }

  validateSalesmany() {
    if (!this.localValue.isSalesman) return;
    if (!this.localValue.salesmanyAmount) {
      throw '请输入销售金额';
    }
    let amount = Number(this.localValue.salesmanyAmount);
    if (isNaN(amount)) {
      throw '请输入正确的数字';
    }
    if (this.prevValue?.isSalesman) {
      if (amount <= this.prevValue.salesmanyAmount) {
        throw `需大于上一销售金额(${this.prevValue.salesmanyAmount})`;
      }
    }
  }
  created() {
    let assignData: Record<string, any> = {};
    if (this.prevValue.isSelfBuy) {
      assignData.isSelfBuy = this.prevValue.isSelfBuy
    }
    if (this.prevValue.isSalesman) {
      assignData.isSalesman = this.prevValue.isSalesman
    }
    this.localValue = { ...this.modelValue, ...assignData };
  }

  @ValidateDec('form')
  async confirm() {
    if (!(this.localValue.isSelfBuy || this.localValue.isSalesman)) {
      return this.$error('请设置升级条件')
    }
    this.hideEvent();
    this.$emit('confirm', this.localValue)
  }
  cancel() {
    this.hideEvent();
  }
}
