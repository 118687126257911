import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00f69c39"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-select-link-box" }
const _hoisted_2 = {
  key: 1,
  class: "has-text-box"
}
const _hoisted_3 = { class: "has-block-box" }
const _hoisted_4 = { style: {"height":"0","width":"0","overflow":"hidden"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowDown = _resolveComponent("ArrowDown")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_cascader = _resolveComponent("el-cascader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.show)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "empty-text-box",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args)))
        }, [
          _createTextVNode(" 请选择跳转页面"),
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_ArrowDown)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.show.name), 1),
          _createElementVNode("div", {
            class: "empty-text-box",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args)))
          }, [
            _createTextVNode(" 修改"),
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_ArrowDown)
              ]),
              _: 1
            })
          ])
        ])),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(), _createBlock(_component_el_cascader, {
        props: { label: 'name' },
        key: _ctx.key,
        options: _ctx.list,
        style: {"width":"0","height":"0"},
        modelValue: _ctx.value_,
        "show-all-levels": false,
        onChange: _ctx.changeEvent,
        ref: "cascader"
      }, null, 8, ["options", "modelValue", "onChange"]))
    ])
  ]))
}