
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter, Mutation, namespace, State } from 'vuex-facing-decorator';
import { LockDec, ValidateDec } from '@/decorators/index'
@Component({})
export default class extends Vue {
  loading = false;
  post = {
    phone: "",
    loginpwd: "",
  }
  @Action("user/login")
  userLogin;

  @ValidateDec('form', { showTips: true })
  @LockDec('loading')
  async submitEvent() {
    let { error, message } = await this.userLogin(this.post)
    if (error) return this.$error(message);
    this.$replace("/home");
  }

  goto(path) {
    this.$goto({ path });
  }
}
