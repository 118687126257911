import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f744dca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "operat-content-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_ui_card = _resolveComponent("ui-card")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_ui_page = _resolveComponent("ui-page")!
  const _component_ui_container = _resolveComponent("ui-container")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_ui_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_ui_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_form, {
            class: "search-box",
            "label-width": "100px"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_el_form_item, { label: "商品名称" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      type: "text",
                      modelValue: _ctx.condition.name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.condition.name) = $event)),
                      placeholder: "请输入商品名称"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "佣金模式" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "商品状态" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_el_form_item, {
                label: "",
                style: {"margin-bottom":"0"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_el_button, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.find())),
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("筛选")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_link, {
                      type: "primary",
                      style: {"margin":"0 0 0 10px"},
                      onClick: _ctx.resetFilter,
                      underline: false
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("重置筛选条件")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ui_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_tabs, {
            type: "card",
            class: "tabbar-box"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, {
                label: "分销商品",
                name: "0"
              })
            ]),
            _: 1
          }),
          _withDirectives((_openBlock(), _createBlock(_component_ui_table, {
            data: _ctx.source.list
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                label: "商品",
                prop: "name"
              }, {
                default: _withCtx(({ row }) => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("img", {
                      src: row.cover,
                      style: {"width":"40px","height":"40px","object-fit":"cover","margin-right":"10px"}
                    }, null, 8, _hoisted_4),
                    _createElementVNode("span", null, _toDisplayString(row.name), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "金额",
                prop: "showPrice"
              }),
              _createVNode(_component_el_table_column, {
                label: "库存",
                prop: "stock"
              }),
              _createVNode(_component_el_table_column, {
                label: "佣金模式",
                prop: "mode"
              }),
              _createVNode(_component_el_table_column, { label: "商品佣金" }, {
                default: _withCtx(({ row }) => [
                  (row.goodsInfo && row.goodsInfo.type == 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, "-"))
                    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(row.levelList, (item) => {
                        return (_openBlock(), _createElementBlock("div", null, _toDisplayString(item.name) + "：" + _toDisplayString(item.value) + _toDisplayString(item.unit), 1))
                      }), 256))
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, { label: "商品状态" }),
              _createVNode(_component_el_table_column, { label: "更新时间" }, {
                default: _withCtx(({ row }) => [
                  _createTextVNode(_toDisplayString(_ctx.$format(row.createTime)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "操作",
                width: "120"
              }, {
                default: _withCtx(({ row }) => [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_el_link, {
                      onClick: ($event: any) => (_ctx.editInfo(row)),
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("编辑")
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])), [
            [_directive_loading, _ctx.source.loading]
          ]),
          _createVNode(_component_ui_page, {
            modelValue: _ctx.page.page,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.page.page) = $event)),
            info: _ctx.pageInfo
          }, null, 8, ["modelValue", "info"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}