
import { ValidateDec } from '@/decorators';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  forms = {
    name: "",
  }

  @ValidateDec("form")
  async submit() {
    let { error, message } = await this.$post('/manager/store/create', {
      name: this.forms.name,
    });
    if (error) return this.$error(message);
    this.$emit("resolve", {})
  }
  close() {
    this.$emit("reject", { msg: '取消' })
  }
}
