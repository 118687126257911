
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
interface Props {
  list: any[]
}
@Component({})
export default class extends TSX<Props>()(Vue) implements Props {

  @Prop()
  list: any[];

  @Watch('exterList')
  exterListWatch() {
    this.certicates = this.exterList.map((item) => {
      return { key: item.name, value: "" };
    });
  }

  @Watch('value', {
    deep: true
  })
  valueWatch() {
    this.radio = 0;
  }

  radio = 0
  value = []
  certicates = []


  get qualify() {
    return this.showData.biz_qualify || this.showData.qualify
  }

  get exterList() {
    if (
      this.showData &&
      this.qualify &&
      this.qualify.exter_list[this.radio]
    ) return this.qualify.exter_list[this.radio].inner_list;
 
    return []
  }
  get showData() {
    if (this.value.length == 0) return null;
    return this.hashList[this.value[this.value.length - 1]];
  }
  get hashList() {
    let res = {};
    this.list.map((item) => {
      res[item.id] = item;
    });
    return res;
  }
  get showList() {
    let res = [];
    this.list.map((item) => {
      if (item.father == 0) {
        let children1 = [];
        item.children.map((childId) => {
          let citem = this.hashList[childId];
          if (!citem) return;

          children1.push({
            ...this.hashList[childId],
            children: null,
          });
        });

        res.push({
          ...item,
          children: children1,
        });
      }
    });
    return res;
  }

  async changeFile(e, index) {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let fd = new FormData();
      fd.append("file", file);
      let { error, data } = await this.$upload(
        "/manager/open-platform/upload-media",
        fd
      );
      if (error) {
        e.target.value = "";
        return;
      }
      this.certicates[index].value = data.media_id;
    }
    e.target.value = "";
  }
  async submitForm() {
    for (let i = 0; i < this.certicates.length; i++) {
      let item = this.certicates[i];
      if (item.value == "") {
        return this.$error(`请上传${item.key}`);
      }
    }
    let { error, data } = await this.$post(
      "/manager/open-platform/add-wechat-cactegory",
      {
        categories: [
          {
            first: this.value[0],
            second: this.value[1],
            certicates: this.certicates,
          },
        ],
      }
    );
    if (error) return;
    this.$emit('resolve');
  }
}
