
import { LockDec } from '@/decorators';
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
interface Props { };

@Component({})
export default class extends TSX<Props>()(Vue) implements Props {
  list = [];

  submitLoading = false;
  editItem(i) { }

  removeItem(i) {
    this.list.splice(i, 1)
  }

  swapArray(arr, index1, index2) {
    if (index1 < 0 || index1 >= arr.length || index2 < 0 || index2 >= arr.length) {
      return;
    }
    let temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
  }

  upIndex(i) {
    this.swapArray(this.list, i, i - 1)
  }

  downLindex(i) {
    this.swapArray(this.list, i, i + 1)
  }

  created() {
    this.getList()
  }

  changeRequire(row, val) {
    row.isRequire = val;
    if (row.isRequire == 1) {
      row.isEnabled = 1;
    }
  }

  changeEnabled(row, val) {
    row.isEnabled = val;
    if (row.isEnabled == 0) {
      row.isRequire = 0;
    }
  }

  getFormatType(type) {
    switch (type) {
      case 1:
        return '普通文本';
      case 2:
        return '多行文本';
      case 3:
        return '电话带区号';
      case 4:
        return '数字文本';
      case 5:
        return '身份证';
      case 6:
        return '单选';
      case 7:
        return '多选';
    }
    return '未知类型';
  }

  async getList() {
    let { error, data } = await this.$get("/manager/store/order-form-list")
    if (error) return;
    this.list = data.list;
  }

  @LockDec("submitLoading")
  async submitForm() {
    let { error, data } = await this.$postNotify("/manager/store/set-order-form", {
      list: this.list
    });
    if (error) return;
    this.list = data;
    this.$emit("resolve")
  }
  cancel() {
    this.$emit("reject")
  }
}
