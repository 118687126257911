
import InstanceBox from './index.vue';
import { dialog } from '@/plugins/dialog/index'

export default function (options) {
  options = Object.assign({}, options)
  return dialog({
    title: '订单退款',
    props: {
      width: '700px'
    },
    content() {
      return <InstanceBox options={options}></InstanceBox>
    }
  })
}