
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
interface Props {
  row?: any
}
@Component({
  components: {},
})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  row: any

  ruleForm = {
    name: "",
    description: "",
    isSuper: 0,
    roleMiddleList: [],
  }
  get mesureList() {
    let res = { children: [] };
    let loopParentCheck = (pushItem) => {
      if (!pushItem.parentInfo) return;
      pushItem.parentInfo.half = true;
      loopParentCheck(pushItem.parentInfo);
    };
    let getDeep = (id, data, parentInfo?) => {
      for (let i = 0; i < this.list.length; i++) {
        let item = this.list[i];
        if (item.parent_id == id) {
          let pushItem = { ...item, parentInfo };
          let children = getDeep(item.id, [], pushItem);
          if (children.length > 0) {
            pushItem.children = children;
          }
          if (this.defaultKeys[pushItem.id]) {
            loopParentCheck(pushItem);
          }
          data.push(pushItem);
        }
      }
      return data;
    };
    getDeep(null, res.children);
    return res.children;
  }

  get defaultKeys() {
    let res = {};
    this.ruleForm.roleMiddleList.map((item, index) => {
      res[item.roleItemId] = `${index}`;
    });
    return res;
  }

  list = []
  created() {
    if (this.row) {
      this.ruleForm = JSON.parse(
        JSON.stringify({ ...this.ruleForm, ...this.row })
      );
    }
    this.getList();
  }
  async getList() {
    let { error, data } = await this.$get("/manager/super/common/role-item-list");
    if (error) return;
    this.list = data;
  }

  removeItem(data) {
    let removeParents = (info) => {
      if (!info) return;
      if (this.defaultKeys[info.id]) {
        this.ruleForm.roleMiddleList.splice(this.defaultKeys[info.id], 1);
      }
      removeParents(info.parentInfo);
    };
    let removeChildren = (children) => {
      if (!children) return;
      children.map((item) => {
        if (item.children) {
          removeChildren(item.children);
        }
        if (this.defaultKeys[item.id]) {
          this.ruleForm.roleMiddleList.splice(this.defaultKeys[item.id], 1);
        }
      });
    };
    removeParents(data.parentInfo);
    removeChildren(data.children);
    if (this.defaultKeys[data.id]) {
      this.ruleForm.roleMiddleList.splice(this.defaultKeys[data.id], 1);
    }
  }

  addItem(data) {
    let addChildren = (infoItem) => {
      if (infoItem.children) {
        infoItem.children.map((item) => {
          addChildren(item);
        });
      }
      if (!this.defaultKeys[infoItem.id]) {
        this.ruleForm.roleMiddleList.push({
          roleItemId: infoItem.id,
        });
      }
    };
    let addParent = (infoItem) => {
      if (!infoItem.parentInfo) return;
      let checkAll = infoItem.parentInfo.children.every((item) => {
        return !!this.defaultKeys[item.id];
      });
      if (checkAll) {
        this.ruleForm.roleMiddleList.push({
          roleItemId: infoItem.parentInfo.id,
        });
        addParent(infoItem.parentInfo);
      }
    };
    addChildren(data);
    addParent(data);
  }

  changeEvent(data, bool) {
    if (bool) {
      return this.addItem(data);
    }
    this.removeItem(data);
  }

  async confirmData() {
    await this.$post("/manager/super/common/operat-role", {
      ...this.ruleForm,
    });
    this.$emit('resolve', []);
  }

  closeDialog() {
    this.$emit('reject', { msg: "已取消" });
  }
}
