
import { ValidateDec } from '@/decorators';
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator';
interface Props {
  source: any[]
}

@Component({})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  source: any
  get codeName() {
    for (let i = 0; i < this.list.length; i++) {
      let item = this.list[i];
      if (item.value == this.source.codeType) {
        return item.name;
      }
    }
  }
  force = false
  list = [
    {
      name: "统一社会信用代码",
      value: 1,
    },
    {
      name: "组织机构代码",
      value: 2,
    },
    {
      name: "营业执照注册号",
      value: 3,
    },
  ]
  ruleForm = {
    name: "",
    code: "",
    codeType: 1,
    legalPersonaWechat: "",
    legalPersonaName: "",
  }

  @ValidateDec('formCheck', { showTips: true })
  async submitForm() {
    let { error, message } = await this.$post(
      "/manager/open-platform/register-mini",
      this.ruleForm
    );
    if (error) return this.$error(message);
    this.$success("提交申请成功");
    this.$emit('resolve');
  }
}
